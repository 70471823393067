/*
    This mixin is purely for the entry App.vue to automate
    common processes and decouple it from the views and styling.
*/

import {
  EventBus,
  EventBusChannels,
  config,
  raf,
  ApiFactory,
  Locale,
  SEO,
} from "_core";
import { MainSettings, ApiRoutes } from "_types";
import { getApi } from "./";
import { debounce } from "_utils";
import { FontLoader } from "../init/fonts";
import { NavigationGuardNext, Route } from "vue-router";
import { Vue, Component, Mixins } from "vue-property-decorator";

@Component({
  name: "CreateAppMixin",
  metaInfo() {
    return SEO.getAppMetaData(this.settings && this.settings.seo);
  },
})
export class createAppMixin extends Mixins(getApi) {
  public isResizing = false;
  public isBlurred = false;
  public settings = null;
  public application = null;
  public fontLoader: FontLoader = null;

  private currentWindowSize = { width: 0, height: 0 };
  private resizeHandler = null;

  $debug: boolean;

  beforeMount(): void {
    Locale.setupStore(this.$store);
    this.query = ApiFactory.getQuery(ApiRoutes.Settings, this.$route);
  }

  mounted(): void {
    // Single console log ignored by UglifyJS
    window.console.log(...config.consoleMessage);
    if (config.options.enableResize) this.enableResize();
    // window.addEventListener("resize", this.enableResize);
    if (config.options.enableBlurFocus) this.enableBlurFocus();
    if (config.options.enableRaf) this.enableRaf();
    window.addEventListener("click", this.handleGlobalClick);
  }

  beforeDestroy(): void {
    window.removeEventListener("click", this.handleGlobalClick);
    if (config.options.enableResize) this.disableResize();
    // window.removeEventListener("resize", this.enableResize);
  }

  created() {
    this.onCreated();
    this.$router.beforeEach((to: Route, from: Route, next): void => {
      Locale.updateRoute(to);
      EventBus.$emit(EventBusChannels.ChangingPage, to);
      EventBus.$emit(EventBusChannels.ModalClose);
      this.onPageChange(to, from, next);
    });
  }

  // Empty function to prevent error - can be activated from app.vue
  public onCreated(): void { }
  public onPageChange(
    to: Route,
    from: Route,
    next: NavigationGuardNext<Vue>
  ): void { }
  public onLoad(settings: MainSettings): void {
    this.settings = settings;
    if (config.options.enableFontLoader) {
      this.fontLoader = new FontLoader();
      this.fontLoader.setupFonts(
        settings.globalSettings ? settings.globalSettings.font_settings : null
      );
    }
  }

  // TODO: Refactor this nicely
  private handleGlobalClick(event) {
    // This will inject WYSIWYG links with vue router links
    let { target } = event;
    while (target && target.tagName !== "A") target = target.parentNode;
    // handle only links that do not reference external resources

    if (
      target &&
      target.parentNode?.matches(".wysiwygTextWrapper p") &&
      target.href
    ) {
      // some sanity checks taken from vue-router:
      // https://github.com/vuejs/vue-router/blob/dev/src/components/link.js#L106
      const { altKey, ctrlKey, metaKey, shiftKey, button, defaultPrevented } =
        event;
      // don't handle with control keys
      if (metaKey || altKey || ctrlKey || shiftKey) return;
      // don't handle when preventDefault called
      if (defaultPrevented) return;
      // don't handle right clicks
      if (button !== undefined && button !== 0) return;
      // don't handle if `target="_blank"`
      if (target && target.getAttribute) {
        const linkTarget = target.getAttribute("target");
        if (/\b_blank\b/i.test(linkTarget)) return;
      }

      const url = new URL(target.href);
      const to = url.pathname;

      // Allow normal <a> behavior if external link
      if (window.location.hostname !== url.hostname) return

      const regex = new RegExp('^.*\.(jpg|JPG|gif|GIF|doc|DOC|pdf|PDF|png|PNG|avi|AVI|webm|WEBM|mov|MOV)$');

      if (window.location.pathname !== to && event.preventDefault && !regex.test(target.href)) {
        event.preventDefault();
        this.$router.push(to);
      }
    }
  }

  private enableResize(): void {
    if (this.resizeHandler) return;  // If handler already exists, don't create a new one

    this.resizeHandler = debounce(() => {
      const { innerWidth, innerHeight } = window;
      const isMob = innerWidth < config.mobBreakpoint;

      if (innerWidth !== this.currentWindowSize.width || innerHeight !== this.currentWindowSize.height) {
        config.dom = {
          ...config.dom,
          width: innerWidth,
          height: innerHeight,
          isMob,
        };

        EventBus.$emit(EventBusChannels.Resize, config.dom);

        this.currentWindowSize = {
          width: innerWidth,
          height: innerHeight,
        };
      }
    }, 100);

    window.addEventListener('resize', this.resizeHandler);
  }
  private disableResize(): void {
    if (this.resizeHandler) {
      window.removeEventListener('resize', this.resizeHandler);
      this.resizeHandler = null;
    }
  }
  private enableRaf(): void {
    const rafFunc = (): void => {
      if (window) EventBus.$emit(EventBusChannels.Scroll, window.scrollY);
      raf(rafFunc);
    };
    rafFunc();
  }
  private enableBlurFocus(): void {
    window.onblur = debounce((): void => {
      this.isBlurred = true;
      EventBus.$emit(EventBusChannels.Blur);
    }, 200);
    window.onfocus = debounce((): void => {
      this.isBlurred = false;
      EventBus.$emit(EventBusChannels.Focus);
    }, 200);
  }
}
