
import { Mixins, Component, Prop, Ref } from "vue-property-decorator";
import { SmartImage, SmartImageProps, SmartText } from "_components";
import { onScroll, onView, onResize } from "_mixins";
import { DynamicContentImageGridProps } from "../../types";
import SingleTextBlock from "../Text/TextBlock.vue";
import { generateID, createModifierClass } from "_utils";

@Component({
  name: "DCImageWithText",
  components: { SmartImage, SingleTextBlock, SmartText },
})
export default class DCImageGrid extends Mixins(onScroll, onView, onResize) {
  @Prop({ required: true }) data: DynamicContentImageGridProps;
  @Ref() wrapperRef: HTMLElement;
  @Ref() textRef: HTMLElement;
  @Ref() imageRef: HTMLElement;

  rootClass = "c-dc-image-grid";
  imageLoadCounter = 0;

  imageCache = 0;

  get mainTitle() {
    return this.data.title && this.data.title;
  }

  get name() {
    return this.images.length
      ? this.data.blocks.map((block) => block.name)
      : [];
  }

  get prefix() {
    return this.images.length
      ? this.data.blocks.map((block) => block.prefix)
      : [];
  }

  get url() {
    return this.images.length
      ? this.data.blocks.map((block) => block.link)
      : [];
  }

  get uniqueID() {
    return generateID();
  }

  get showTrueColorsOnHover() {
    return !this.data?.options?.true_colors_on_hover;
  }

  get generateClass() {
    return [
      this.rootClass,
      ...createModifierClass(
        this.rootClass,
        this.showTrueColorsOnHover,
        "colored"
      ),
    ];
  }

  get images(): SmartImageProps[] {
    return this.data.blocks.length
      ? this.data.blocks.map((block) => ({
          image: block.image,
          lazyload: true,
          fit: "contain",
        }))
      : [];
  }

  imageLoaded() {}
}
