import { config, EventBus, EventBusChannels } from "_core";
import { flattenArray } from "_utils";
import FontFaceObserver from "fontfaceobserver";

export type FontClass = {
  name: string;
  tag: string;
  group: string;
  fontFamily: string;
};

export class SmartTextCoreClass {
  public mappedClasses: FontClass[];
  private observers: { [key: string]: FontFaceObserver }[] = [];

  constructor() {
    this.init();
  }

  public getClass(name: string): FontClass {
    return this.mappedClasses.find((singleClass) => singleClass.name === name);
  }

  private mapClasses(): void {
    this.mappedClasses = flattenArray(
      Object.entries(config.fontSettings.fontClasses).map((font) =>
        // @ts-ignore
        font[1].map((chosenFont: FontClass) => ({
          name: chosenFont.name,
          tag: chosenFont.tag,
          group: font[0],
          ...(config.fontSettings.fontsLoaded && {
            fontFamily: config.fontSettings.fontsToUse[`${font[0]}_font`],
          }),
        }))
      )
    );
  }

  private init() {
    this.mapClasses();
    if (!config.fontSettings.fontsLoaded) {
      EventBus.$once(EventBusChannels.FontsInit, () => {
        this.mappedClasses = this.mappedClasses.map((singleClass) => ({
          ...singleClass,
          fontFamily:
            config.fontSettings.fontsToUse[`${singleClass.group}_font`],
        }));
        Object.entries(config.fontSettings.fontsToUse).forEach((font) => {
          if (font[1] && !this.observers[font[1]])
            this.observers[font[1]] = new FontFaceObserver(font[1]);
        });
        EventBus.$emit(EventBusChannels.FontsAttached);
      });
    }
  }

  public hasFontLoaded(font: string) {
    return new Promise((resolve) => {
      this.observers[font].load().then(() => resolve(true));
    });
  }
}

const Core = new SmartTextCoreClass();

export { Core };
