
import { DynamicContentVideoProps } from "../../types";
import { Vue, Component, Prop } from "vue-property-decorator";
import { VideoPlayer } from "_components";

@Component({
  name: "DynamicContentImage",
  components: { VideoPlayer },
})
export default class DynamicContentVideo extends Vue {
  @Prop({ required: true }) data: DynamicContentVideoProps;
  rootClass = "c-dc-video";

  get videoId(): string {
    return this.data.video;
  }
}
