
import { Vue, Component, Prop } from "vue-property-decorator";
import { DynamicContentSimpleTimelineProps } from "../../types";
import { generateID, createModifierClass } from "_utils";
import { SmartSVG, SmartImage, SmartText, SVGLibraryCustom, SmartImageProps } from "_components";
import { DCTimelineModules, DCTimelineDateLayout } from './types';
import TextSelector from "../Text/index.vue";

@Component({
  name: "DynamicContentImage",
  components: { SmartImage, SmartSVG, SmartText, SVGLibraryCustom, TextSelector },
  filters: {
    formatDate(value: string) {
      if (!value) return ''

      const dateParts = value.split("/");
      const date = new Date(parseInt(dateParts[2], 10), parseInt(dateParts[1], 10) - 1, parseInt(dateParts[0], 10));

      const week = date.toLocaleDateString('en-US', {weekday: 'long'});
      const s = ["th", "st", "nd", "rd"];
      const dayUnformatted = date.toLocaleDateString('en-US', {day: 'numeric'})
      const v = parseInt(dayUnformatted)%100;
      const day = dayUnformatted + (s[(v-20)%10] || s[v] || s[0])
      const month = date.toLocaleDateString('en-US', {month: 'long'});
      const year = date.toLocaleDateString('en-US', {year: 'numeric'});

      return `${week} ${day} ${month} ${year}`
    }
  }
})

export default class DynamicContentImage extends Vue {
  @Prop({ required: true }) data: DynamicContentSimpleTimelineProps;

  rootClass = "c-dc-simple-timeline";
  tabVisibility = {};
  SVGLibraryCustom = SVGLibraryCustom;
  DCTimelineModules = DCTimelineModules;

  get uniqueID() {
    return generateID();
  }

  getItemClass(index, layout) {
    return [
      `${this.rootClass}__item`,
      ...createModifierClass(
        `${this.rootClass}__item`,
        this.tabVisibility[index],
        "open"
      ),
      ...createModifierClass(
        `${this.rootClass}__item`,
        layout === DCTimelineDateLayout.WITH_IMAGE,
        "withImage"
      ),
    ];
  }

  getImageSettings(images): SmartImageProps[] {
    return images.length
      ? images.map((image) => ({
          image,
          lazyload: true,
          fit: "contain",
        }))
      : [];
  }

  toggleVisibility(index: number): void {
    if(!this.tabVisibility[index]) Vue.set(this.tabVisibility, index, true);
    else Vue.set(this.tabVisibility, index, false)
  }
}
