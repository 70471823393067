
import { DevTools, Footer, CookieDialog } from "_components";
import Header from "./components/Header/index.vue";
import { Route } from "vue-router/types/router";
import { Component, Mixins, Ref } from "vue-property-decorator";
import { createApp } from "_mixins";

@Component({
  components: {
    DevTools,
    Header,
    Footer,
    CookieDialog,
  },
})
export default class Boilerplate extends Mixins(createApp) {
  @Ref() pageRef;
  $projectName: string;

  onPageChange(to: Route, from: Route, next: () => void): void {
    // Trigger page animation from here
    next();
  }

  handleEnter() {
    const target = this.pageRef && this.pageRef;
    if (target && target.calcAfterAnim) target.calcAfterAnim();
  }

  get footerSettings() {
    return this.settings ? this.settings.footer : null;
  }
}
