import { WPImage } from "_types";

export enum DCTimelineModules {
  TEXT_CONTENT = 'text_content',
  TIMESLOT = 'timeslot'
}

export type DCTimeslot = {
  acf_fc_layout: DCTimelineModules;
  time: string;
  title: string;
  texts?: DCTimelineText[];
}

export type DCTimelineText = {
  acf_fc_layout: DCTimelineModules;
  text: string;
}

export type DCTimelineDateContent = {
    images?: WPImage[];
    left_column?: (DCTimelineText|DCTimeslot)[];
    right_column: (DCTimelineText|DCTimeslot)[];
}

export enum DCTimelineDateLayout {
    WITH_IMAGE = 'with_image',
    NO_IMAGE = 'no_image'
}

export type DCTimelineDate = {
    date: string;
    layout: DCTimelineDateLayout;
    content: DCTimelineDateContent;
}