
import { FooterSettings } from "_types";
import { Mixins, Component, Prop } from "vue-property-decorator";
import { SmartText, WYSIWYG } from "_components";
import { generateID, sanitise, validateEmail } from "_utils";
import { onResize } from "_mixins";
import { axios } from "_core";
import { Contact, LinkButton, TextInput } from "../components";

type StarBaseEmailType = {
  $id: number;
  date_time: string;
  err_code: number;
  message: string;
  output: string;
};

@Component({
  name: "Footer",
  components: { SmartText, TextInput, WYSIWYG, LinkButton, Contact },
})
export default class Footer extends Mixins(onResize) {
  @Prop({ required: true }) settings: FooterSettings;
  rootClass = "c-footer";

  isNotValidEmail = false;
  emailAlreadyExists = false;
  success = false;

  get enable() {
    return this.settings.enable;
  }

  get uniqueID() {
    return generateID();
  }

  get enableNewsletter() {
    return this.enable && this.settings.newsletter.enable_newsletter;
  }

  get newsletterCopy() {
    return this.enable && this.settings.newsletter.newsletter_text;
  }

  get emailPlaceholderKey() {
    return this.enable && this.settings.newsletter.input_placeholder;
  }

  get enableContact() {
    return (
      this.enable &&
      this.settings.contact &&
      this.settings.contact.enable_contact
    );
  }

  get links() {
    return this.enable && this.settings.links;
  }

  get invalidEmailAddressKey() {
    return this.enable && this.settings.newsletter.invalid_email_address_message
      ? this.settings.newsletter.invalid_email_address_message
      : "Invalid email address";
  }

  get emailAlreadyExistsKey() {
    return this.enable && this.settings.newsletter.email_already_exists_message
      ? this.settings.newsletter.email_already_exists_message
      : "Email already exists";
  }

  get successKey() {
    return this.enable && this.settings.newsletter.success_message
      ? this.settings.newsletter.success_message
      : "Thanks for subscribing!";
  }

  get errorMessage() {
    if (this.isNotValidEmail) return this.invalidEmailAddressKey;
    else if (this.emailAlreadyExists) return this.emailAlreadyExistsKey;
  }

  async handleEmail(email: string) {
    const sanitized = sanitise(email);
    const api = "https://api2.ecomailapp.cz/lists/5/subscribe";

    if (validateEmail(sanitized)) {
      this.isNotValidEmail = false;
      this.emailAlreadyExists = false;
      this.success = false;

      await axios
        .post(
          api,
          JSON.stringify({
            subscriber_data: {
              email: sanitized,
            },
            trigger_autoresponders: true,
            resubscribe: true,
          }),
          {
            headers: {
              "content-type": "text/json",
              key: this.settings.newsletter.api_key,
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            if (response.data.already_subscribed) {
              this.emailAlreadyExists = true;
            } else {
              this.success = true;
            }
          } else {
            this.isNotValidEmail = true;
          }
        });
    } else {
      this.isNotValidEmail = true;
    }
  }
}
