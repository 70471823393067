export enum DCTVideoWithTextOrientation {
  VIDEO_ON_LEFT = 'videoOnLeft',
  VIDEO_ON_RIGHT = 'videoOnRight',
}

export enum DCTVideoWithTextType {
  HALF_WIDTH = 'halfWidth',
  LARGE_TEXT = 'largeText',
}

export type DCVideoWithTextOptions = {
  orientation: DCTVideoWithTextOrientation;
  type: DCTVideoWithTextType
}