
import { Vue, Component, Prop } from "vue-property-decorator";
import DataList from "../../../DataList/index.vue";
import { PQ_CARD_ASPECT_RATIO } from "_types";
import { DynamicContentSelectorProps } from "../../types";
@Component({
  name: "DCSelector",
  components: { DataList },
})
export default class DCSelector extends Vue {
  @Prop() data: DynamicContentSelectorProps;

  get list(): any[] {
    return (
      this.data &&
      this.data.items.map((item) => ({
        card: {
          card_images: item.image && [item.image],
          headline: item.options.title,
          show_on_homepage: false,
          show_in_related_posts: false,
          aspect_ratio: PQ_CARD_ASPECT_RATIO.SQUARE,
        },
        category: [],
        post_data: {
          post_excerpt: item.options.description && item.options.description,
        },
        tags: [],
        permalink: item.options.link,
        ...(item.options.colour_override && {
          colourOverride: item.options.colour_override,
        }),
      }))
    );
  }
}
