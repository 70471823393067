import { PQ_PROJECT_COLOURS, ThemeLink, WPImage } from "_types";

export enum DCTextModules {
    HEADLINE = 'headline',
    SUBHEADLINE = 'subheadline',
    LARGE = 'large',
    NORMAL = 'normal',
    HIGHLIGHT = 'highlight',
    BUTTON_GROUP = 'button_group',
    TABLE = 'table',
    IMAGE_GRID = 'image_grid',
    ACCORDION = 'accordion'
}

export enum DCTextOptions {
    DISABLE_MARGIN = 'disableMargin',
    RED = 'red',
    SIMPLE = 'simple',
}

export type DCTextImageBlock = {
    acf_fc_layout: DCTextModules;
    label: string;
    gallery: WPImage[];
}

export type DCTextBlock = {
    acf_fc_layout: DCTextModules;
    options?: DCTextOptions[];
    options_group?: {
        colour_override: PQ_PROJECT_COLOURS;
        options: DCTextOptions[];
    }
    headline?: string;
    subheadline?: string;
    large?: string;
    normal?: string;
    buttons?: {
        primary: {
            enable_primary_link: boolean;
            link: { link: ThemeLink }
        };
        secondary: { link: ThemeLink }[]
    };
    data?: {
        key: string;
        type: "text" | "link";
        text?: string;
        link?: { link: ThemeLink };
    }[];
    items?: {
        title: string;
        text: string;
    }[];
}