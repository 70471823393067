import { ApiRoutes, AnyObjectOfStrings, ACTIONS } from "_types";
import { Vue, Component, Watch, Mixins, Prop } from "vue-property-decorator";
import {
  EventBus,
  config,
  CookieTypes,
  Cookies,
  Locale,
  ApiFactory,
} from "_core";
import { Route } from "vue-router";

/**
 * Creates and handles API request with automatic vuex storage.
 * @function onLoad(dataFromApi): void
 * @param query: string - Provide a query string
 */
@Component
export class getApiMixin extends Vue {
  public data: any = {};
  public query: string = null;

  @Watch("query")
  runQuery() {
    this.init();
  }

  /**
   * Call onLoad(dataFromApi) to listen when data returns from API
   * */
  onLoad(data: any) {}

  init() {
    if (!this.cachedData) {
      this.$store.dispatch(ACTIONS.GET_DATA, this.computedQuery);
      EventBus.$once(this.computedQuery, (data: any) => {
        this.data = data;
        this.onLoad(data);
      });
    } else {
      this.data = this.cachedData;
      this.onLoad(this.cachedData);
    }
  }

  get cachedData() {
    return this.query && this.$store.state.storeFactory[this.computedQuery];
  }

  private get computedQuery() {
    return ApiFactory.storeNameBuilder(this.query);
  }
}
