
import { Vue, Component, Prop, Watch, Ref } from "vue-property-decorator";
import {
  SearchResult,
  EMPTY_SEARCH,
  PQCardType,
  ThemeTranslations,
  ThemeLink,
  THEME_LINKS,
  THEME_LINK_TYPE,
} from "_types";
import { SmartImage, SmartImageProps, CTAButton, SmartText } from "_components";
import { GSAP } from "_core";
import { generateID } from "_utils";
import SearchResultCard from "./Card.vue";

@Component({
  name: "SearchResults",
  components: {
    SearchResultCard,
    SmartImage,
    SmartText,
    CTAButton,
  },
})
export default class SearchResults extends Vue {
  @Prop({ default: () => EMPTY_SEARCH }) results: SearchResult;
  @Prop({ default: false, type: Boolean }) isOpen: boolean;
  @Prop() translations: ThemeTranslations;

  @Ref() searchResultRef: HTMLElement;

  rootClass = "c-header-search";
  cardData: PQCardType[] = [];
  active = 0;
  private tl: gsap.core.Timeline;

  private isVisible = false;

  @Watch("isOpen")
  handleOpenClose() {
    if (this.isOpen && !this.isVisible) {
      this.isVisible = true;
      this.animIn();
    } else if (!this.isOpen && this.isVisible) {
      this.animOut();
    }
  }

  mounted() {
    this.tl = GSAP.timeline();
    this.tl.set(this.searchResultRef, {
      y: "-100%",
    });
  }

  get imageSettings() {
    return this.clippedResults.map((card): SmartImageProps | null =>
      card && card.card && card.card.card_images
        ? {
            image: card.card.card_images[0],
            lazyload: true,
            fit: "cover",
          }
        : null
    );
  }

  get hasMoreResults() {
    return this.results.posts.length > 3;
  }

  get clippedResults() {
    return this.results.posts.slice(0, 3);
  }

  get query() {
    return this.results ? this.results.search : "";
  }

  get generateID() {
    return generateID();
  }

  get wrapperClasses() {
    return [];
  }

  get fullResultsURL(): ThemeLink {
    return {
      label: this.translations.search.view_all_results,
      theme_link: THEME_LINKS.SEARCH,
      query: this.query,
      type: THEME_LINK_TYPE.THEME_LINK,
    };
  }

  handleClear() {
    this.$emit("clear");
  }

  handleLoadCard(data: PQCardType, index: number) {
    this.cardData.splice(index, 0, data);
  }

  handleMouseOver(index: number) {
    this.active = index;
  }

  clearAnim() {
    if (this.tl.isActive()) {
      this.tl.clear();
      this.tl.eventCallback("onComplete", null);
    }
  }

  animIn() {
    this.clearAnim();
    this.tl.to(this.searchResultRef, {
      y: 0,
      duration: 0.4,
    });
  }

  animOut() {
    this.clearAnim();
    this.tl.to(this.searchResultRef, {
      y: "-100%",
      duration: 0.4,
      onComplete: () => {
        this.isVisible = false;
      },
    });
  }
}
