
import { Vue, Component, Prop } from "vue-property-decorator";
import { SmartText, SVGLibraryCustom, SmartSVG } from "_components";
// import { ThemeTranslations } from "_types";

@Component({
  name: "BreadcrumbsHome",
  components: { SmartText, SmartSVG },
})
export default class BreadcrumbsHome extends Vue {
  @Prop({ required: true, type: String }) rootClass: string;
  @Prop() title: string;

  icon = SVGLibraryCustom.ArrowUpRight;
}
