import { Logger } from "./";
import { SCSSVariables } from "./";

import { ThemeColors } from "@app/project-config";
export { ThemeColors };

const DEBUG = false;

class ColorsClass {
  logger: Logger;

  constructor() {
    this.logger = new Logger("Colors Module", DEBUG);
  }

  public toRGBA(color: ThemeColors, alpha: number = 1): string {
    const r = parseInt(color.slice(1, 3), 16);
    const g = parseInt(color.slice(3, 5), 16);
    const b = parseInt(color.slice(5, 7), 16);
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }

  public isColor(strColor: string): boolean {
    return CSS.supports("color", strColor);
  }

  get SCSSColors() {
    return SCSSVariables.themeColors;
  }
}

export const Colors = new ColorsClass();
