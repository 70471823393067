import { DebugElementOutput } from "_types";
import { config } from "_core";
import { debounce } from "ts-debounce";
import { v4 } from 'uuid'
import { createDecorator } from "vue-class-component";
import { throttle } from "lodash"

/** @module Validate **/

/**
 * Validates if string is a genuine URL via regex
 * @memberof Validate
 * @param string to validate
 * @returns boolean
 */
export const validateUrl = function (value: string): boolean {
  const expression =
    /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi;
  const regexp = new RegExp(expression);
  return regexp.test(value);
};

/**
 * Validates if string is a genuine Email address via regex
 * @memberof Validate
 * @param string to validate
 * @returns boolean
 */
export const validateEmail = function (email: string): boolean {
  const re = /\S+@\S+\.\S+/;
  return re.test(email);
};

/** @module Security **/

/**
 * Validates if string is a genuine Email address via regex
 * @memberof Security
 * @param string to sanitise
 * @returns string (sanitised)
 */
export const sanitise = function (data: string): string {
  const map = {
    "&": "&amp;",
    "<": "&lt;",
    ">": "&gt;",
    '"': "&quot;",
    "'": "&#x27;",
    "/": "&#x2F;",
  };
  const reg = /[&<>"'/]/gi;
  return data.replace(reg, (match) => map[match]);
};

/** @module Development **/

/**
 * Shows frequently used debug info when doing front end development
 * @memberof Development
 * @param HTMLElement to debug
 * @returns DebugElementOutput | void (on production)
 */
export const debugElement = function (
  element: HTMLElement
): DebugElementOutput | void {
  if (config.debug) return;
  const rect = element.getBoundingClientRect();
  return {
    debugging: element,
    top: rect.top,
    bottom: rect.top + rect.height,
    height: rect.height,
    classList: element.classList,
    transition: window.getComputedStyle(element).getPropertyValue("transition"),
    transform: window.getComputedStyle(element).getPropertyValue("transform"),
  };
};

/**
 * DEPRECATED Global logger to use to avoid console logs on production
 * @memberof Development
 * @param any to debug
 * @returns DebugElementOutput | void (on production)
 */
export const logger = function (...paras: any): void {
  if (config.debug) console.log(...paras);
};

/** @module General **/

/**
 * Debounce a function
 * @memberof General
 * @param Function
 * @returns Function
 */
export { debounce };

/**
 * Copy text to clipboard
 * @memberof General
 * @param string
 * @returns void
 */
export const copyToClipboard = function (text: string): void {
  const el = document.createElement("textarea");
  el.value = text;
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
};

/**
 * Checks if parameter is a function
 * @memberof General
 * @param any
 * @returns boolean
 */
export const isFunction = function (functionToCheck: any): boolean {
  return (
    functionToCheck && {}.toString.call(functionToCheck) === "[object Function]"
  );
};

/**
 * Generates a random number from 0 to parmater
 * @memberof General
 * @param number
 * @returns number
 */
export const generateRandomNumber = function (upTo: number): number {
  return (upTo * Math.random()) << 0;
};

/**
 * Shuffles an array
 * @memberof General
 * @param array
 * @returns array
 */
export const shuffleArray = function (array): [] {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

/**
 * Pass a number and will return true if even, or false if odd
 * @memberof General
 * @param number
 * @returns number
 */
export const isNumberEven = function (number: number): boolean {
  return number % 2 === 0;
};

/**
 * Get key in object via the value
 * @memberof General
 * @param object
 * @param any
 * @returns string
 */
export const getKeyByValue = function (object: object, value: any): string {
  return Object.keys(object).find((key) => object[key] === value);
};

/**
 * Flatten array
 * @memberof General
 * @param array
 * @returns array
 */
export const flattenArray = function (ary) {
  return [].concat(...ary);
};

/**
 * Capitalizes the first letter of a string
 * @memberof General
 * @param string
 * @returns string
 */
export const capitalizeFirstLetter = function (string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

/**
 * Checks if given object is empty or not
 * @memberof General
 * @param object
 * @returns boolean
 */
export const isEmptyObj = function (obj: object) {
  return Object.keys(obj).length === 0;
};

/**
 * Clamps a number between two values
 * @memberof General
 * @param number
 * @param number
 * @param number
 * @returns number
 */
export const clamp = function (num: number = 0, min: number = 0, max: number = 0) {
  return Math.min(Math.max(num, min), max)
};

/**
 * Generate router-link friendly path from absolute URL (relative only)
 * @memberof General
 * @param url string
 * @returns string
 */
export const getRouterLink = function (url: string): string {
  return url.startsWith(config.baseUrl) ? url.slice(config.baseUrl.length) : url;
};

/**
 * Returns uuid (unique ID) - Use for v-for
 * @memberof General
 * @returns string
 */
export const generateID = function () {
  return v4();
};

/**
 * Create bem name with modifier (add ... to conditional add in array)
 * @memberof General
 * @param string block name
 * @param boolean boolean
 * @param string modifier
 * @returns string (css class name)
 */
 export const createModifierClass = (
  block: string,
  bool: boolean,
  modifier: string
): string[] => (bool ? [`${block}--${modifier}`] : []);

/** @module Animation **/

// Internal utils function - not to be exported - Return true if numbers are less than 1 apart
const _NUMBER_IS_ALMOST_SAME = function (val1: number, val2: number): boolean {
  if (isNaN(val1) || isNaN(val2)) return false;
  if (val1 > val2 - 1 && val1 < val2 + 1) return true;
  else return false;
};

/**
 * Lerps number to target number - Returns target position if less than 1px away
 * @memberof Animation
 * @param number Initial position (should be cached)
 * @param number Target position
 * @returns boolean
 */
export const lerp = function (
  initialPosition: number,
  targetPosition: number
): number {
  if (_NUMBER_IS_ALMOST_SAME(initialPosition, targetPosition))
    return targetPosition;
  else return (initialPosition += (targetPosition - initialPosition) * 0.2);
};

/**
 * Returns mapped number from one value to another with a variable value
 * @memberof Animation
 * @param from Array of start and finish numbers to be mapped
 * @param to  Array of start and finish numbers for output
 * @param number Varible number to pin point mapping, e.g. scroll value
 * @returns number
 */
export const mapAnimation = function (
  from: [number, number],
  to: [number, number],
  s: number
): number {
  return to[0] + ((s - from[0]) * (to[1] - to[0])) / (from[1] - from[0]);
};

/**
 * Shorthand util for safe transforming a HTML Element
 * @memberof Animation
 * @param element HTMLElement to transform
 * @param x number
 * @param y number
 * @param scale number
 * @returns void
 */
export const transform = function (element: HTMLElement, x = 0, y = 0, scale = 1): void {
  if (element && element.style)
    element.style.transform = `translate3d(${x}px, ${y}px, 0px)${scale !== 1 ? ` scale(${scale})` : ""
      }`;
}

/** @module Other **/

/**
 * Returns youtube video ID from url
 * @memberof Other
 * @param string youtube url
 * @returns string
 */
export const youtubeParser = function (url): string {
  const regExp =
    /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  const match = url.match(regExp);
  return match && match[7].length == 11 ? match[7] : false;
};

export const Throttle = (waitMs: number) =>
  createDecorator((options, key) => {
    if (options.methods && options.methods[key]) {
      const originalMethod = options.methods[key];
      const throttleMethod = throttle(originalMethod, waitMs, {
        leading: true,
        trailing: false,
      });

      options.methods[key] = async function (...args: any) {
        await throttleMethod.apply(this, args);
      };
    }
  });

export * from "@app/utils";
