import { Vue, Component } from "vue-property-decorator";
import { EventBus, EventBusChannels } from "_core";

/**
 * Adds listener for scroll via RAF.
 * @function onScroll(scrollValue: number): void
 */
@Component
export class onScrollMixin extends Vue {
  /**
   * Call onScroll(scrollValue: number) to get raf scroll value
   * */
  public onScroll(scrollValue: number): void {}

  private setupOnScroll(on: boolean): void {
    on
      ? EventBus.$on(EventBusChannels.Scroll, this.onScroll)
      : EventBus.$off(EventBusChannels.Scroll, this.onScroll);
  }

  mounted() {
    this.setupOnScroll(true);
  }
  beforeDestroy() {
    this.setupOnScroll(false);
  }
}
