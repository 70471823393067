import Vue from "vue";
import Vuex from "vuex";
import createPersist from "vuex-localstorage";
import { default as storeFactory } from "./src/";
import { config } from "_core";
Vue.use(Vuex);

// Check if the value exists in local storage
const hasCacheTimestamp = parseInt(localStorage.getItem("cacheTimestamp"));

// Initialize the state based on whether the value exists or not
const initialState = {
  cacheTimestamp: hasCacheTimestamp ? hasCacheTimestamp : config.cacheTimestamp,
};


const storage = createPersist({
  namespace: 'sb-store',
  initialState,
  expires: 1.21e9, // Two Weeks
});

export default new Vuex.Store({
  modules: {
    storeFactory,
  },
  strict: config.debug,
  plugins: [
    !config.debug && config.enableCaching ? storage : (): void => { },
  ],
});

// If the value does not exist in local storage, set it to the initial value
if (!hasCacheTimestamp) {
  localStorage.setItem("cacheTimestamp", config.cacheTimestamp);
}
else if (hasCacheTimestamp !== config.cacheTimestamp) {
  localStorage.removeItem('sb-store-default');
  localStorage.removeItem('cacheTimestamp');
  localStorage.setItem("cacheTimestamp", config.cacheTimestamp); // Set the new value in local storage
}

