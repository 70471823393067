/*
    Any functions placed here will be appended to '_utils'
*/

import { PQCardType, ThemeLink, ThemeTranslations, THEME_LINKS, THEME_LINK_TYPE, WPImage } from "_types";
import { config } from '_core';
import { v4 } from 'uuid'

/** @module ThemeUtils **/

/**
 * Generates a URL for router-link for WP posts
 * @memberof ThemeUtils
 * @param card PQCardType
 * @returns string
 */
export const generatePostURL = function (card: PQCardType) {
  if (card.category.length) return `/${card.category[0].slug}/${card.post_data.post_name}`
  return `/${card.post_data.post_name}`
};

export const getRouterLink = function (url: string) {
  return url.startsWith(config.baseUrl) ? url.slice(config.baseUrl.length) : url;
};

export const generateID = function () {
  return v4();
};

export const transform = function (element: HTMLElement, x = 0, y = 0, scale = 1) {
  if (element && element.style)
    element.style.transform = `translate3d(${x}px, ${y}px, 0px)${scale !== 1 ? ` scale(${scale})` : ""
      }`;
}

export const generatePageLink = function (link: ThemeLink) {
  if (link.type === THEME_LINK_TYPE.PAGE_LINK)
    return getRouterLink(link.page_link);
  else if (link.type === THEME_LINK_TYPE.THEME_LINK)
    if (link.theme_link === THEME_LINKS.HOME_PAGE) return "/";
  if (link.theme_link === THEME_LINKS.ALL_ARTICLES) return "/posts/";
  console.warn(`no link type for ${link.type}}`);
  return "";
}

export const gridCardFetchAmount = function (windowSize: number) {
  const DEBUG = false;
  if (windowSize < config.mobBreakpoint) {
    return DEBUG ? 3 : 5;
  } else if (windowSize < 1024) {
    return DEBUG ? 3 : 11;
  } else if (windowSize < 2048) {
    return DEBUG ? 3 : 17;
  } else {
    return DEBUG ? 3 : 25;
  }
}

export const arrayContainsObject = (array: object[], object: object) => {
  return array.some((item) =>
    Object.keys(item).every((key) => item[key] === object[key])
  );
};

export const buildCaptionsFromImages = (images: WPImage[]) => {
  return images.length
    ? images
      .map((image) => image.caption)
      .filter((caption) => caption)
      .join(", ")
    : "";
}

export const displayPostType = (post: PQCardType, translations: ThemeTranslations) => {
  switch (post.post_data.post_type) {
    case 'post':
      return post.category[0].name;
    case 'publication':
      return translations.general.publication;
    default:
      return null;
  }
}

// A cross-browser safe way to convert wordpress post/page date to usable output date.
export const wordpressDateToString = (date: string): string => {
  const output = new Date(date.replace(/-/g, "/"));
  return output ? output.toLocaleDateString("en-UK") : '';
}