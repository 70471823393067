
import { Vue, Component, Prop } from "vue-property-decorator";
import { SmartText } from "_components";

@Component({
  name: "WYSIWYG-WIDGET",
  components: { SmartText },
})
export default class WYSIWYG extends Vue {
  @Prop({ required: true }) text: string;
  @Prop({ required: false, default: "body-copy" }) type: string;
  @Prop({ default: false, type: Boolean }) balance: boolean;

  // textBlocks = [];
  alignRight: any[];
  alignCenter: any[];

  // beforeMount() {
  //   console.log(99, this.text);
  //   if (!Boolean(this.text)) return;
  //   console.log(44, this.text, Boolean(this.text));
  //   //   WSYIWYG editor returns content in <p> blocks
  //   const textBlocks = this.text.replace(/\n/g, "").split("</p>");
  //   let blocksToShow = [];
  //   textBlocks.forEach((block, index) => {
  //     switch (block) {
  //       // Remove empty blocks
  //       case "":
  //         return;
  //     }

  //     blocksToShow.push(`${block}</p>`);
  //   });
  //   console.log(blocksToShow);
  //   blocksToShow.forEach((block, index) => {
  //     if (block.startsWith('<p style="text-align: right;">')) {
  //       this.alignRight.push(index);
  //     }
  //     if (block.startsWith('<p style="text-align: center;">')) {
  //       this.alignCenter.push(index);
  //     }
  //   });
  //   this.textBlocks = blocksToShow;
  // }

  get textBlocks() {
    const test =
      Boolean(this.text) &&
      this.text
        .replace(/\n/g, "")
        .split("</p>")
        .map((block) => (block !== "" ? `${block}</p>` : null))
        .filter((block) => block !== null);

    return test;
  }

  // getClass(block: string) {
  //   return {
  //     alignCenter: true,
  //   };
  // }
}
