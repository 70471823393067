
import { createModifierClass } from "@app/boilerplate/src/utils";
import { Vue, Component, Prop } from "vue-property-decorator";

enum PLACEHOLDER_SIZES {
  SMALL = "small",
  MEDIUM = "medium",
  LARGE = "large",
  IMAGE = "image",
}

@Component({
  name: "ComponentName",
})
export default class ComponentName extends Vue {
  @Prop({ required: true }) size: PLACEHOLDER_SIZES;
  rootClass = "c-placeholder";

  get generateClass() {
    return [
      this.rootClass,
      createModifierClass(this.rootClass, Boolean(this.size), this.size),
    ];
  }
}
