export type WPImage = {
  ID: number;
  id: number;
  title: string;
  filename: string;
  filesize: number;
  url: string;
  link: string;
  alt: string;
  author: string;
  description: string;
  caption: string;
  name: string;
  status: string;
  uploaded_to: number;
  date: string;
  modified: string;
  menu_order: number;
  mime_type: string;
  type: string;
  subtype: string;
  icon: string;
  width: number;
  height: number;
  sizes: WPImageSizes;
};

export type WPImageSizes = {
  thumbnail: string;
  "thumbnail-width": number;
  "thumbnail-height": number;
  medium: string;
  "medium-width": number;
  "medium-height": number;
  medium_large: string;
  "medium_large-width": number;
  "medium_large-height": number;
  large: string;
  "large-width": number;
  "large-height": number;
  "c-thumbnail": string;
  "c-thumbnail-width": number;
  "c-thumbnail-height": number;
  "c-small": string;
  "c-small-width": number;
  "c-small-height": number;
  "c-medium": string;
  "c-medium-width": number;
  "c-medium-height": number;
  "c-medium_large": string;
  "c-medium_large-width": number;
  "c-medium_large-height": number;
  "c-large": string;
  "c-large-width": number;
  "c-large-height": number;
  "x-large": string;
  "x-large-width": number;
  "x-large-height": number;
  "xx-large": string;
  "xx-large-width": number;
  "xx-large-height": number;
  "xxx-large": string;
  "xxx-large-width": number;
  "xxx-large-height": number;
  huge: string;
  "huge-width": number;
  "huge-height": number;
  "x-huge": string;
  "x-huge-width": number;
  "x-huge-height": number;
  "xx-huge": string;
  "xx-huge-width": number;
  "xx-huge-height": number;
};

export type SmartImageProps = {
  image: WPImage;
  lazyload: boolean;
  fit: "cover" | "contain";
  fullHeight?: boolean;
  forceHeight?: boolean;
  backupAltTag?: string;
  debugPlaceholder?: boolean;
  enableFullRes?: boolean;
};

export type imgPosProps = {
  test: boolean;
} & DOMRect;

export enum MeasureByOutput {
  "width" = "width",
  "height" = "height",
}

export interface ImgSize {
  width: number;
  height: number;
  ratio?: number;
}

export interface ImgStyleSize {
  width: string;
  height: string;
}
