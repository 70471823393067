
import { Vue, Component, Prop } from "vue-property-decorator";
import { SmartText, SmartSVG, SVGLibraryCustom } from "_components";
import { MainSettings, ThemeLink, THEME_LINK_TYPE, WPPostType } from "_types";
import { generateID } from "_utils";
import Homepage from "./Homepage.vue";
import CurrentPage from "./CurrentPage.vue";
import Crumb from "./Crumb.vue";
import Placeholder from "../Placeholder/index.vue";
import { DynamicPageHierarchy } from "@app/views/DynamicPage/types";

@Component({
  name: "Breadcrumbs",
  components: {
    Crumb,
    SmartText,
    SmartSVG,
    Homepage,
    CurrentPage,
    Placeholder,
  },
})
export default class Breadcrumbs extends Vue {
  @Prop() settings: MainSettings;
  @Prop() post: WPPostType;
  @Prop() breadcrumbs: DynamicPageHierarchy;
  @Prop() title: string;

  rootClass = "c-breadcrumbs";
  icon = SVGLibraryCustom.PQArrowRightBreadcrumbs;

  get uniqueID() {
    return generateID();
  }

  get translations() {
    return (
      this.settings &&
      this.settings.general &&
      this.settings.general.translation_keys
    );
  }

  get crumbs(): ThemeLink[] {
    return this.breadcrumbs && this.breadcrumbs.length
      ? this.breadcrumbs.map((crumb) => ({
          label: crumb.title,
          type: THEME_LINK_TYPE.PAGE_LINK,
          page_link: crumb.permalink,
        }))
      : [];
  }
}
