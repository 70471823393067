import actions from "./actions";
import mutations from "./mutations";

const store = {
  state: {},
  actions,
  mutations,
};

export default store;
