
import { Vue, Component, Prop, Ref } from "vue-property-decorator";
import { SmartImage, SmartText } from "_components";
import { DynamicContentSlideshowProps } from "../../types";
import Gallery from "../../../Gallery/index.vue";
import TextSelector from "../Text/index.vue";

@Component({
  name: "ParallaxGallery",
  components: { SmartImage, SmartText, Gallery, TextSelector },
})
export default class Slideshow extends Vue {
  @Prop({ required: true }) data: DynamicContentSlideshowProps;
  @Ref() containerRef: HTMLElement;

  rootClass = "c-dc-slideshow";

  get title() {
    return this.data.title && this.data.title;
  }

  get text() {
    return this.data.text_selector && this.data.text_selector;
  }

  get captionSettings() {
    return (
      this.data.gallery_caption_settings && this.data.gallery_caption_settings
    );
  }
}
