export class Logger {
  PROD = process.env.NODE_ENV === "production";
  moduleName: string;
  enabled: boolean = false;

  constructor(moduleName: string, enabled = false) {
    /*
            By adding process.env conditional, webpack
            automatically removes code from JS bundle
            on production
        */

    this.moduleName = moduleName;
    this.enabled = console && enabled && !global.__IS_JEST__;
    if (this.enabled) this.log("Init logger");
  }

  // Hidden on production
  public log(...args: any[]): void {
    if (!this.PROD && this.enabled)
      console.log(
        `%c${this.moduleName}:`,
        "background: white; color: black; padding: 5px; margin: 5px;",
        ...args
      );
  }

  // Hidden on production
  public warn(...args: any[]): void {
    if (!this.PROD && this.enabled)
      console.warn(
        `%c${this.moduleName}:`,
        "background: orange; color: black; padding: 5px; margin: 5px;",
        ...args
      );
  }

  // Shown on production
  public error(...args: any[]): void {
    console &&
      console.error(
        `%c${this.moduleName}:`,
        "background: red; color: black; padding: 5px; margin: 5px;",
        ...args
      );
  }
}
