
import { Vue, Component, Prop, Ref } from "vue-property-decorator";
import Flickity, { Options } from "flickity";

const OPTIONS: Options = {
  contain: true,
  prevNextButtons: false,
  pageDots: false,
  freeScroll: true,
  // freeScrollFriction: 0.03,
  selectedAttraction: 0.01
};

@Component({
  name: "GalleryBase",
})
export default class GalleryBase extends Vue {
  @Prop({ default: false, type: Boolean }) leftAlign: boolean;
  @Prop({ default: false, type: Boolean }) isHoverSelect: boolean;
  @Ref() wrapper: HTMLElement;

  rootClass = "c-gallery-base";
  gallery: Flickity = null;

  mounted() {
    this.gallery = new Flickity(this.wrapper, {
      ...OPTIONS,
      ...(this.isHoverSelect
        ? { selectedAttraction: 0.005 }
        : { selectedAttraction: 0.01 }),
      ...(this.isHoverSelect
        ? { freeScrollFriction: 0.5 }
        : { freeScrollFriction: 0.15 }),
      ...(this.leftAlign && { cellAlign: "left" }),
    });
    this.gallery.on("dragStart", this.dragStart);
    this.gallery.on("dragEnd", this.dragEnd);
  }

  onDestroy() {
    if (this.gallery) {
      this.gallery.off("dragStart", this.dragStart);
      this.gallery.off("dragEnd", this.dragEnd);
      this.gallery.destroy();
    }
  }

  public select(index: number): void {
    this.gallery.select(index, false, false);
  }

  public next() {
    this.gallery.next();
  }

  public prev() {
    this.gallery.previous();
  }

  private dragStart() {
    if (this.gallery) this.gallery.slider.style.pointerEvents = "none";
  }

  private dragEnd() {
    if (this.gallery) this.gallery.slider.style.pointerEvents = "auto";
  }
}
