
import { Mixins, Component, Prop, Watch, Ref } from "vue-property-decorator";
import { createModifierClass, generateID } from "_utils";
import { onResize } from "_mixins";
import { HeaderSettings } from "_types";
import { config, GSAP } from "_core";
import { LinkButton, Contact } from "../../components";
import MenuHandler from "./MenuHandler.vue";
import LanguageSelector from "../Core/LanguageSelector.vue";

@Component({
  name: "HeaderMenu",
  components: { Contact, MenuHandler, LinkButton, LanguageSelector },
})
export default class HeaderMenu extends Mixins(onResize) {
  @Prop() data: HeaderSettings;
  @Prop({ type: Boolean, default: false }) isOpen: boolean;

  @Ref() contactRef: HTMLElement;
  @Ref() colRef: MenuHandler[];
  @Ref() wrapperRef: HTMLElement;

  rootClass = "c-header-menu";
  isVisible = false;
  elementsToAnimate = [];
  private tl: gsap.core.Timeline;

  @Watch("isOpen")
  handleMenuToggle() {
    if (this.isOpen) {
      this.isVisible = true;
      this.animIn();
    } else {
      this.animOut();
    }
  }

  mounted() {
    this.calcElementsToAnimate();
  }

  get menus() {
    return this.data.menu_pages ? this.data.menu_pages : [];
  }

  get headerLinks() {
    return this.data && this.data.links_in_header
      ? this.data.links_in_header
      : [];
  }

  get uniqueID() {
    return generateID();
  }

  get generateClass() {
    return [
      this.rootClass,
      createModifierClass(this.rootClass, this.isOpen, "is-open"),
    ];
  }

  onResize() {
    if (this.tl) {
      this.tl.kill();
      this.elementsToAnimate.forEach((el) => (el.style = ""));
    }
    this.$nextTick(() => {
      this.calcElementsToAnimate();
    });
  }

  calcElementsToAnimate() {
    this.tl = GSAP.timeline();
    const elements = [];
    // Desktop anim
    if (window.innerWidth >= config.tabletBreakpoint) {
      if (this.contactRef) elements.push(this.contactRef);
      if (this.colRef.length)
        this.colRef.forEach((col) => {
          elements.push(col.$el);
        });
      this.elementsToAnimate = elements;
      this.tl.set(this.elementsToAnimate, {
        y: "-100%",
      });
      // Tablet + Mobile anim
    } else {
      if (this.wrapperRef) elements.push(this.wrapperRef);
      this.elementsToAnimate = elements;
      this.tl.set(this.elementsToAnimate, {
        y: "-150%",
      });
    }
  }

  clearAnim() {
    if (this.tl.isActive()) {
      this.tl.clear();
      this.tl.eventCallback("onComplete", null);
    }
  }

  animIn() {
    this.clearAnim();
    this.tl.to([...this.elementsToAnimate].reverse(), {
      y: 0,
      duration: 0.4,
      stagger: 0.1,
    });
  }
  animOut() {
    this.clearAnim();
    this.tl.to(this.elementsToAnimate, {
      y: "-100%",
      duration: 0.4,
      stagger: 0.1,
      onComplete: () => {
        this.isVisible = false;
      },
    });
  }
}
