import { Vue, Component } from "vue-property-decorator";
import { config, EventBus, EventBusChannels } from "_core";

type ResizePayload = { width: number; height: number; isMob: boolean };

/**
 * Adds listener for window resize.
 * @function onResize({ width, height }): void
 * @static isMob: boolean
 */
@Component
export class onResizeMixin extends Vue {
  public isMob: boolean = false;

  /**
   * Call onResize({ width: number, height: number }) to listen for window resizes
   * */
  public onResize({ width, height }): void {}

  private onResizeHandler({ width, height, isMob }: ResizePayload): void {
    this.isMob = isMob;
    this.onResize({ width, height });
  }

  private setupOnResize(on: boolean): void {
    on
      ? EventBus.$on(EventBusChannels.Resize, this.onResizeHandler)
      : EventBus.$off(EventBusChannels.Resize, this.onResizeHandler);
  }

  created() {
    this.isMob = window.innerWidth < config.mobBreakpoint;
  }

  mounted() {
    this.setupOnResize(true);
  }
  beforeDestroy() {
    this.setupOnResize(false);
  }
}
