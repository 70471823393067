import { ACTIONS, ApiRoutes, DynamicPageProps, WPPostType } from "_types";
import { Logger, ApiFactory, EventBus } from "./";
import { Route } from "vue-router";

const DEBUG = false;

const { LANGUAGE_SETTINGS, BASE_URL } = window as any;

// Fallback language is always english
const FALLBACK_LOCALE = "en";

type LanguageSettings = {
  default: string;
  all: string[];
  enabled: boolean;
};

type TranslatedLink = { locale: string; displayName: string };

class LocaleModule {
  private currentLocaleInternal: string;
  private logger: Logger;
  private currentTranslatedPages: { [key: string]: number } = {};

  private languageSettings: LanguageSettings;

  private vueStore: any;
  private currentRoute: Route;
  private query;
  private queryData;

  public availableLanguages: TranslatedLink[];

  constructor() {
    this.logger = new Logger("Locale Module", DEBUG);
    this.languageSettings = LANGUAGE_SETTINGS;

    // Run only if polylang is available
    if (this.isEnabled) this.init();
  }

  private init() {
    this.availableLanguages = this.languageSettings.all.map((localeCode) => {
      const languageNames = (Intl as any).DisplayNames
        ? new (Intl as any).DisplayNames(localeCode, {
          type: "language",
        })
        : null;
      return {
        locale: localeCode,
        displayName: languageNames
          ? languageNames.of(localeCode)
          : localeCode.toUpperCase(),
      };
    });

    // 1. Use locale from URL
    this.setLocaleFromURL();

    this.logger.log("Set", { currentLocale: this.currentLocale });
  }

  public get currentLocale() {
    return this.currentLocaleInternal;
  }

  public get isEnabled() {
    return this.languageSettings && this.languageSettings.enabled;
  }

  private setLocaleFromURL() {
    let fetchedLocale = new URL(BASE_URL).pathname.substring(1);
    // let fetchedLocale = FALLBACK_LOCALE;
    if (!fetchedLocale)
      fetchedLocale = this.languageSettings.default
        ? this.languageSettings.default
        : FALLBACK_LOCALE;
    if (Boolean(fetchedLocale)) this.currentLocaleInternal = fetchedLocale;
    return Boolean(this.currentLocaleInternal);
  }

  public switchLocale(locale: string, route: Route): void {
    this.logger.log(`Switch locale to ${locale}`, this.currentTranslatedPages);

    // Do nothing if polylang is disabled
    if (!this.isEnabled || this.languageSettings.all.length <= 1) return;

    // Do nothing if locale is already the same as target locale or lang doesn't exist
    if (locale === this.currentLocale) return;
    if (this.languageSettings.all.indexOf(locale) === -1) return;

    this.currentLocaleInternal = locale;

    // Check if language is present in related posts
    if (
      Boolean(
        Object.entries(this.currentTranslatedPages).find(
          ([name, _value]) => name === locale
        )
      )
    ) {
      this.logger.log(
        "Switching URL successfully to post:",
        this.currentTranslatedPages[locale]
      );
      this.queryPost(this.currentTranslatedPages[locale], locale);
    } else if (route.name === 'All Articles') {
      // TODO: To refactor      
      window.location.href = `${this.baseURLWithLocale}${route.path ? route.path.substring(1) : ''}`;
    }
    else {
      this.navigate();
    }
  }

  public updateRelatedTranslations(route: Route, data?: DynamicPageProps) {
    this.currentTranslatedPages =
      data && data.translations ? data.translations : {};
    this.currentRoute = route;
    this.logger.log(
      "Updated stash of translated posts at the ready:",
      route,
      this.currentTranslatedPages
    );
  }

  public setupStore(vueStore) {
    this.logger.log("Load locale with vue store", { vueStore });
    this.vueStore = vueStore;
  }

  private queryPost(postId: number, targetLocale: string) {
    this.query = ApiFactory.getQuery(
      ApiRoutes.QueryPost,
      this.currentRoute,
      null,
      { post_id: postId, target_locale: targetLocale }
    );

    if (!this.cachedData) {
      this.vueStore.dispatch(ACTIONS.GET_DATA, this.computedQuery);
      EventBus.$once(this.computedQuery, (data: any) => {
        this.queryData = data;
        this.navigate(data);
      });
    } else {
      this.queryData = this.cachedData;
      this.navigate(this.cachedData);
    }
  }

  private get cachedData() {
    return this.query && this.vueStore.state.storeFactory[this.computedQuery];
  }

  private get computedQuery() {
    return this.query && ApiFactory.storeNameBuilder(this.query);
  }

  private get baseURLWithLocale() {
    const lang =
      this.currentLocale !== this.languageSettings.default
        ? `${this.currentLocale}/`
        : "";
    return `${new URL(BASE_URL).origin}/${lang}`;
  }

  private navigate(postData?: { category: any; post_data: WPPostType, permalink: string }) {
    const target = postData ? postData.permalink : this.baseURLWithLocale;
    this.logger.log("NAVIGATE:", target);
    window.location.href = target;
  }

  public updateRoute(route: Route) {
    this.currentRoute = route ? route : null;
    this.currentTranslatedPages = {};
    this.query = null;
    this.queryData = null;
  }
}

export const Locale = new LocaleModule();
