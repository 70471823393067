
import { Vue, Component, Prop } from "vue-property-decorator";
import { SmartText } from "_components";
import {
  ThemeLink,
  ThemeTranslations,
  THEME_LINKS,
  THEME_LINK_TYPE,
} from "_types";
import { LinkButton } from "../components";

@Component({
  name: "BreadcrumbsHome",
  components: { LinkButton, SmartText },
})
export default class BreadcrumbsHome extends Vue {
  @Prop({ required: true, type: String }) rootClass: string;
  @Prop() translations: ThemeTranslations;

  get link(): ThemeLink {
    return {
      label: this.translations.breadcrumbs.homepage,
      type: THEME_LINK_TYPE.THEME_LINK,
      theme_link: THEME_LINKS.HOME_PAGE,
    };
  }
}
