
import { DynamicContentAccordionProps } from "../../types";
import { Vue, Component, Prop } from "vue-property-decorator";
import { generateID } from "_utils";
import { SmartText, SmartSVG, SVGLibraryCustom } from "_components";
import SingleTextBlock from "../Text/TextBlock.vue";
import { createModifierClass } from "_utils";

@Component({
  name: "DynamicContentAccordion",
  components: { SmartText, SingleTextBlock, SmartSVG },
})

export default class DynamicContentAccordion extends Vue {

  @Prop({ required: true }) data: DynamicContentAccordionProps;

  rootClass = "c-dc-accordion";
  SVGLibraryCustom = SVGLibraryCustom;
  tabVisibility = {};

  get title() {
    return this.data.title;
  }

  get tabs() {
    return this.data.accordion_content;
  }

  get isSimple() {
    return this.data?.simple_mode;
  }

  get uniqueID() {
    return generateID();
  }

  get generateClass() {
    return [
      this.rootClass,
      ...createModifierClass(this.rootClass, this.isSimple, "simple"),
    ];
  }

  toggleVisibility(index: number): void {
    if(!this.tabVisibility[index]) Vue.set(this.tabVisibility, index, true);
    else Vue.set(this.tabVisibility, index, false)
  }
}
