
import { Vue, Component, Prop, Ref } from "vue-property-decorator";
import { DynamicContentModule } from "_types";
import * as Modules from "./Modules";

@Component({
  name: "DynamicContent",
})
export default class DynamicContent extends Vue {
  @Prop({ required: true }) data: DynamicContentModule[];
  @Ref() wrapper: HTMLElement;
  @Ref() blockRef: HTMLElement[];
  @Ref() moduleRef: any[];

  rootClass = "c-dynamic-content";
  private animationBlocks = [];

  getModule(block: DynamicContentModule) {
    return Modules[block.acf_fc_layout];
  }

  isExistingBlock(block: DynamicContentModule) {
    return Object.entries(Modules).some(
      (mappedBlock) => mappedBlock[0] === block.acf_fc_layout
    );
  }

  public elementsToAnimate() {
    this.animationBlocks = [];
    if (this.blockRef && this.blockRef.length)
      this.blockRef.some((block) => {
        if (block.offsetTop > window.innerHeight + this.wrapper.offsetTop)
          return true;
        this.animationBlocks.push(block);
      });
    return this.animationBlocks;
  }

  public calcAfterAnim() {
    if (this.animationBlocks && this.animationBlocks.length)
      this.animationBlocks.forEach((_block, index) => {
        if (this.moduleRef[index] && this.moduleRef[index].calc)
          this.moduleRef[index].calc();
      });
  }
}
