
import { Vue, Component, Prop } from "vue-property-decorator";
import { SmartText } from "_components";
import { generateID, createModifierClass } from "_utils";
import { DynamicContentTextProps } from "../../types";
import SingleTextBlock from "./TextBlock.vue";
@Component({
  name: "DCText",
  components: { SmartText, SingleTextBlock },
})
export default class DCText extends Vue {
  @Prop({ required: true }) data: DynamicContentTextProps;
  @Prop({ default: false, type: Boolean }) fullWidth: boolean;
  @Prop({ default: false, type: Boolean }) openInNewTab: boolean;

  rootClass = "c-dc-text";

  get uniqueID() {
    return generateID();
  }

  get generateClass() {
    return [
      this.rootClass,
      ...createModifierClass(this.rootClass, this.fullWidth, "full-width"),
    ];
  }

  get textElements() {
    return this.data.text_selector.length && this.data.text_selector;
  }
}
