
import { Mixins, Component, Prop, Ref } from "vue-property-decorator";
import { onView } from "_mixins";

import { Options } from "plyr";
import "plyr/dist/plyr.css";
export const LoadPlyr = () =>
  import(/* webpackChunkName: "__PLYR__" */ "plyr/dist/plyr.polyfilled");

const OPTIONS: Options = {
  ratio: "16:9",
  resetOnEnd: true,
  youtube: {
    iv_load_policy: 3,
    modestbranding: 1,
    playsinline: 1,
    showinfo: 0,
    enablejsapi: 0,
    fs: 0,
  },
};

@Component({
  name: "VideoPlayer",
})
export default class VideoPlayer extends Mixins(onView) {
  @Prop({ required: true, type: String }) videoId: string;
  @Ref() player: HTMLElement;

  playerInstance: Plyr = null;
  showPlayer = false;
  hideInitiator = false;

  mounted() {
    LoadPlyr().then((module) => {
      this.playerInstance = new module.default(this.player, OPTIONS);
      this.init();
    });
  }

  beforeDestroy() {
    if (this.playerInstance) {
      this.playerInstance.destroy();
      this.playerInstance.off("ready", this.setupPlayer);
    }
  }

  init() {
    if (this.playerInstance) this.playerInstance.on("ready", this.setupPlayer);
  }
  setupPlayer() {
    this.showPlayer = true;
  }
  onView(inView: Boolean) {
    if (!this.playerInstance) return;
    if (!inView && this.playerInstance.playing) this.playerInstance.pause();
  }
  handleClick(e) {
    if (this.playerInstance) {
      this.playerInstance.play();
      this.hideInitiator = true;
    }
  }
}
