import { Logger } from "./";

export enum CookieTypes {
  CookiesEnabled = "cookies_enabled",
  Locale = "locale",
}

export enum CookieValues {
  Enabled = "enabled",
  Disabled = "disabled",
}

const DEBUG = false;
class CookieClass {
  private logger: Logger;
  private cookiesEnabled: boolean = false;
  private cookieQueue: {
    cookie: CookieTypes | string;
    value: CookieValues | string;
  }[] = [];

  constructor() {
    this.logger = new Logger("Cookie Module", DEBUG);
    if (this.checkIfCookiesEnabled()) {
      this.logger.log("Cookies are enabled");
      this.cookiesEnabled = true;
    } else {
      this.logger.log("Cookies not enabled");
    }
  }

  get isCookiesEnabled(): boolean {
    return this.cookiesEnabled;
  }

  public setCookie(
    cookie: CookieTypes | string,
    value: CookieValues | string
  ): void {
    this.logger.log("Setting cookie:", { cookie, value });
    if (this.cookiesEnabled) localStorage.setItem(cookie, value);
    else this.cookieQueue.push({ cookie, value });
  }

  public getCookie(cookie: CookieTypes): string {
    return localStorage.getItem(cookie);
  }

  /**
   * Only enable cookies with users consent!
   */
  public enableCookies() {
    this.logger.log("Enabling cookies");
    this.cookiesEnabled = true;
    this.setCookie(CookieTypes.CookiesEnabled, CookieValues.Enabled);
    this.cookieQueue.forEach((cookie) => {
      this.setCookie(cookie.cookie, cookie.value);
    });
    this.cookieQueue = [];
  }

  public disableCookies() {
    this.logger.log("Disabling cookies");
    this.cookiesEnabled = false;
    this.clearCookies();
  }

  private checkIfCookiesEnabled(): boolean {
    return (
      localStorage.getItem(CookieTypes.CookiesEnabled) === CookieValues.Enabled
    );
  }

  private clearCookies(): void {
    localStorage.clear();
  }
}

export const Cookies = new CookieClass();
