export class TextBalancer {
  public static balance(target: HTMLElement): void {
    if (!target) return;
    this.balanceReset(target);
    if (this.textElementIsMultipleLines(target)) {
      this.squeezeContainer(target, target.clientHeight, 0, target.clientWidth);
    }
  }

  private static balanceReset(target): void {
    target.style.maxWidth = "";
  }

  private static squeezeContainer(
    element: HTMLElement,
    originalHeight: number,
    bottomRange: number,
    topRange: number
  ): void {
    if (bottomRange >= topRange) {
      element.style.maxWidth = topRange + "px";
      return;
    }
    const mid = (bottomRange + topRange) / 2;
    element.style.maxWidth = mid + "px";
    if (element.clientHeight > originalHeight)
      this.squeezeContainer(element, originalHeight, mid + 1, topRange);
    else this.squeezeContainer(element, originalHeight, bottomRange + 1, mid);
  }

  private static textElementIsMultipleLines(element: HTMLElement): boolean {
    const ORIGINAL_ELEMENT_TEXT = element.innerHTML;
    const HEIGHT_OFFSET = 10;
    let elementWords = element.innerText.split(" ");
    const firstWord = document.createElement("span");
    firstWord.innerHTML = elementWords[0];
    elementWords = elementWords.slice(1);
    element.innerHTML = "";
    element.appendChild(firstWord);
    const heightOfSingleWord = element.offsetHeight;
    element.innerHTML += " " + elementWords.join(" ");
    element.innerHTML = ORIGINAL_ELEMENT_TEXT;
    firstWord.remove();
    return element.offsetHeight - HEIGHT_OFFSET > heightOfSingleWord;
  }
}
