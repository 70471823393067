import {
  MetaPropertyCharset,
  MetaPropertyEquiv,
  MetaPropertyName,
  MetaPropertyMicrodata as MetaPropertyMicroData,
  MetaPropertyProperty,
  ScriptPropertyJson,
  ScriptPropertySrc,
  ScriptPropertySrcCallback,
  ScriptPropertyText,
  MetaInfo,
} from "vue-meta";
import { WPImage } from "../src/components/SmartImage/src/types";

export { MetaInfo };

export const defaults = {
  noscript: "This website requires JavaScript.",
  themeColor: "#000000",
};

export enum MetaTypes {
  "icon" = "icon",
  "viewport" = "viewport",
  "description" = "description",
  "author" = "author",
  "keywords" = "keywords",
  "themeColor" = "theme-color",
}

export enum MetaProperties {
  "ogTitle" = "og:title",
  "ogDescription" = "og:description",
  "ogImage" = "og:image",
  "ogUrl" = "og:url",
  "ogSiteName" = "og:site_name",
}

export enum MetaNames {
  "twitterCard" = "twitter:card",
  "twitterImageAlt" = "twitter:image:alt",
}

export type SeoGoogleAnalytics = {
  enable: boolean;
  g_tag: string;
};

export type SeoMetaPixel = {
  enable: boolean;
  pixel_id: string;
};

export type SeoGoogleTagManager = {
  enable: boolean;
  container_id: string;
}

export type SeoSettings = {
  author: string;
  description: string;
  keywords: string;
  title: string;
  title_template: string;
  favicon: WPImage | false;
  google_analytics: SeoGoogleAnalytics;
  google_tag_manager: SeoGoogleTagManager;
  meta_pixel: SeoMetaPixel;
  other_settings: {
    theme_color: string;
  };
  social_media_seo: SocialMediaSeo;
};

export type SocialMediaSeo = {
  description?: string;
  image?: WPImage | false;
  image_alt?: string;
  site_name?: string;
  title?: string;
  website_url?: string;
};

export type SeoFromWPPage = {
  description: string;
  keywords: string;
  title: string;
  social_media: {
    title: string;
    description: string;
    image: WPImage | false;
  };
};

export type MetaInfoType = (
  | MetaPropertyCharset
  | MetaPropertyEquiv
  | MetaPropertyName
  | MetaPropertyMicroData
  | MetaPropertyProperty
)[];

export type MetaInfoScriptType = (
  | ScriptPropertyText
  | ScriptPropertySrc
  | ScriptPropertySrcCallback
  | ScriptPropertyJson
)[];

export type DynamicPageSEO = {
  title: string;
  description: string;
  keywords: string;
  social_media: {
    description: string;
    image: WPImage;
    title: string;
  };
};
