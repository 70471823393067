import { ApiRoutes, ACTIONS, RouterPageNames, DynamicPageProps } from "_types";
import { Vue, Component, Watch, Prop } from "vue-property-decorator";
import { EventBus, ApiFactory, Locale, EventBusChannels } from "_core";
import { Route } from "vue-router";
import { config } from "_core";

/**
 * Creates and handles API requests for WP pages (Content)
 * @function initCMS(routeName, route): void
 * @param routeName: ApiRoutes
 * @param route: Route
 */
@Component
export class getCMSDataMixin extends Vue {
  @Prop() initialData: any;

  public CMSData: any = {};
  public CMSQuery: string = null;

  beforeMount() {
    if (this.initialData) this.CMSData = this.initialData;
    else this.initMixin();
  }

  @Watch("CMSQuery")
  runCMSQuery() {
    if (this.CMSQuery) this.initCMSGetter();
  }

  public initCMS(routeName: ApiRoutes, route: Route) {
    this.CMSQuery = ApiFactory.getQuery(routeName, route);
  }

  /** 
    Call onLoad(dataFromApi) to listen when data returns from API 
    */
  onCMSLoad(data: any) { }

  /** 
    Call initMixin() to know when Mixin is ready for calling
    - If data is already present, it will not be called
    */
  initMixin() { }

  private initCMSGetter() {
    if (!this.cachedCMSData) {
      this.$store.dispatch(ACTIONS.GET_DATA, this.computedCMSQuery);
      EventBus.$once(this.computedCMSQuery, (data: DynamicPageProps) => {
        this.handleOnLoad(data);
      });
    } else {
      this.handleOnLoad(this.cachedCMSData);
    }
  }

  private handleOnLoad(data: DynamicPageProps) {
    EventBus.$emit(EventBusChannels.PageLoaded);
    Locale.updateRelatedTranslations(this.$route, data);
    if (data.status === 404 && config.enable404Push) this.pushTo404();
    else {
      this.CMSData = data;
      this.onCMSLoad(data);
    }
  }

  private pushTo404() {
    this.$router.replace({ name: RouterPageNames.FOUR_O_FOUR });
  }

  private get computedCMSQuery() {
    return ApiFactory.storeNameBuilder(this.CMSQuery);
  }

  get cachedCMSData() {
    return (
      this.CMSQuery && this.$store.state.storeFactory[this.computedCMSQuery]
    );
  }
}
