
import { Vue, Component, Prop } from "vue-property-decorator";
import { SmartText, SmartSVG, SVGLibraryCustom } from "_components";
import {
  createModifierClass,
  displayPostType,
  wordpressDateToString,
} from "_utils";
import { PQCardType, ThemeTranslations, THEME_LINK_TYPE } from "_types";
import { TagHandler, LinkLogic } from "../components";

@Component({
  name: "DataListRow",
  components: { SmartText, SmartSVG, TagHandler, LinkLogic },
})
export default class DataListRow extends Vue {
  @Prop() data: PQCardType;
  @Prop({ default: false, type: Boolean }) isActive: boolean;
  @Prop() translations: ThemeTranslations;

  rootClass = "c-data-list-row";
  arrowIcon = SVGLibraryCustom.PQArrowLink;

  get url() {
    const { permalink } = this.data;
    return typeof permalink === "string"
      ? {
          type: THEME_LINK_TYPE.PAGE_LINK,
          page_link: permalink,
        }
      : permalink;
  }

  get title() {
    const { card, post_data } = this.data;
    return card && card.headline ? card.headline : post_data.post_title;
  }

  get postType() {
    return displayPostType(this.data, this.translations);
  }

  get description() {
    const excerpt = this.data.post_data.post_excerpt;
    return excerpt
      ? excerpt.length > 144
        ? `${excerpt.substring(0, 144)}...`
        : excerpt
      : null;
  }

  get date() {
    return wordpressDateToString(this.data.post_data.post_date);
  }

  get tags() {
    return this.data.tags && this.data.tags;
  }

  get colourOverride() {
    return this.data && this.data.colourOverride && this.data.colourOverride;
  }

  get generateClass() {
    return [
      this.rootClass,
      ...createModifierClass(this.rootClass, this.isActive, "active"),
      ...createModifierClass(
        this.rootClass,
        Boolean(this.colourOverride),
        this.colourOverride
      ),
    ];
  }
}
