
import { Vue, Component, Prop } from "vue-property-decorator";
import { LinkButton } from "../../../components";
import { SmartText } from "_components";
import { generateID, createModifierClass } from "_utils";
import { DCTextBlock, DCTextOptions } from "./types";
@Component({
  name: "DynamicContentTableModule",
  components: { SmartText, LinkButton },
})
export default class DynamicContentTableModule extends Vue {
  @Prop() data: DCTextBlock;

  rootClass = "c-dc-text-table";

  get classes() {
    return [
      this.rootClass,
      ...createModifierClass(this.rootClass, this.isRed, "red"),
    ];
  }

  get isRed() {
    const { data } = this;
    const readTarget =
      data && data.options ? data.options : data.options_group?.options;
    return readTarget && readTarget.includes(DCTextOptions.RED);
  }

  get ID() {
    return generateID();
  }

  get rows() {
    return this.data && this.data.data?.length ? this.data.data : [];
  }
}
