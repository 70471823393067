
import { Vue, Component, Prop } from "vue-property-decorator";
import { GalleryCaptionSettingTypes } from "_types";
import { SmartImage, SmartImageProps, SmartText } from "_components";
import { DynamicContentImageProps } from "../../types";

@Component({
  name: "DynamicContentImage",
  components: { SmartImage, SmartText },
})
export default class DynamicContentImage extends Vue {
  @Prop({ required: true }) data: DynamicContentImageProps;
  rootClass = "c-dc-image";

  get imageSettings(): SmartImageProps {
    return {
      image: this.data.image,
      lazyload: true,
      fit: "contain",
    };
  }

  get hasCaption() {
    return (
      this.data.options.gallery_caption_settings.type !==
      GalleryCaptionSettingTypes.DISABLED
    );
  }

  get caption() {
    const { type, static_caption } = this.data.options.gallery_caption_settings;
    return type === GalleryCaptionSettingTypes.STATIC
      ? static_caption
      : this.data.image.caption;
  }
}
