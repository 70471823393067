
import { Vue, Component, Prop } from "vue-property-decorator";
import { SmartText, WYSIWYG } from "_components";
import { DCTextBlock, DCTextModules, DCTextOptions } from "./types";

import ButtonGroup from "./ButtonGroup.vue";
import TextTable from "./TextTable.vue";
import ImageGrid from "./ImageGrid.vue";
import Accordion from "./Accordion.vue";
import { createModifierClass } from "@app/boilerplate/src/utils";

const TEXT_MAP = {
  [DCTextModules.HEADLINE]: "hero-H2",
  [DCTextModules.SUBHEADLINE]: "hero-p-subheadline",
  [DCTextModules.LARGE]: "hero-p-large",
  [DCTextModules.NORMAL]: "hero-p",
  [DCTextModules.HIGHLIGHT]: "hero-highlight",
};

@Component({
  name: "DynamicContentTextModule",
  components: {
    SmartText,
    WYSIWYG,
    ButtonGroup,
    TextTable,
    ImageGrid,
    Accordion,
  },
})
export default class DynamicContentTextModule extends Vue {
  @Prop({ required: true }) data: DCTextBlock;
  @Prop({ default: false, type: Boolean }) openInNewTab: boolean;

  rootClass = "c-dc-text";

  get layout() {
    return this.data.acf_fc_layout;
  }

  get textToDisplay() {
    return this.data[this.layout];
  }

  get smartTextType() {
    return TEXT_MAP[this.layout]
      ? TEXT_MAP[this.layout]
      : TEXT_MAP[DCTextModules.NORMAL];
  }

  get isButtonGroup() {
    return this.layout === DCTextModules.BUTTON_GROUP;
  }

  get isWysiwyg() {
    return this.layout !== DCTextModules.HEADLINE;
  }

  get isHighlight() {
    return this.layout === DCTextModules.HIGHLIGHT;
  }

  get isTable() {
    return this.layout === DCTextModules.TABLE;
  }

  get isAccordion() {
    return this.layout === DCTextModules.ACCORDION;
  }

  get isImageGrid() {
    return this.layout === DCTextModules.IMAGE_GRID;
  }

  get noMargin() {
    const { data } = this;
    const readTarget =
      data && data.options ? data.options : data.options_group?.options;
    return readTarget && readTarget.includes(DCTextOptions.DISABLE_MARGIN);
  }

  get simplified() {
    const { data } = this;
    const readTarget =
      data && data.options ? data.options : data.options_group?.options;
    return readTarget && readTarget.includes(DCTextOptions.SIMPLE);
  }

  get classes() {
    const root = `${this.rootClass}__block`;
    return [
      root,
      ...createModifierClass(root, this.noMargin, "no-margin"),
      ...createModifierClass(root, this.simplified, "simplified"),
    ];
  }
}
