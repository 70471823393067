
import { Mixins, Component, Prop, Ref } from "vue-property-decorator";
import { SmartImage, SmartImageProps, SmartText } from "_components";
import { onScroll, onView, onResize } from "_mixins";
import {
  mapAnimation,
  generateID,
  lerp,
  buildCaptionsFromImages,
} from "_utils";
import { GalleryCaptionSettingTypes } from "_types";
import { DynamicContentImageWithTextProps } from "../../types";
import SingleTextBlock from "../Text/TextBlock.vue";
import { DCTImageWithTextOrientation, DCTImageWithTextType } from "./types";
import { createModifierClass } from "_utils";

@Component({
  name: "DCImageWithText",
  components: { SmartImage, SingleTextBlock, SmartText },
})
export default class DCImageWithText extends Mixins(
  onScroll,
  onView,
  onResize
) {
  @Prop({ required: true }) data: DynamicContentImageWithTextProps;
  @Ref() wrapperRef: HTMLElement;
  @Ref() textRef: HTMLElement;
  @Ref() imageRef: HTMLElement;

  rootClass = "c-dc-image-with-text";
  measurements = { start: 0, end: 0, amounts: [0, 0] };
  imageLoadCounter = 0;

  textCache = 0;
  imageCache = 0;

  get images(): SmartImageProps[] {
    return this.data.images.length
      ? this.data.images.map((image) => ({
          image,
          lazyload: true,
          fit: "contain",
        }))
      : [];
  }

  get hasLargeText() {
    return (
      this.data.options.type === DCTImageWithTextType.LARGE_TEXT
    )
  }

  get hasLargeImages() {
    return (
      this.data.options.type === DCTImageWithTextType.LARGE_IMAGE
    )
  }

  get textElements() {
    return this.data.text_selector.length && this.data.text_selector;
  }

  get flipped() {
    return (
      this.data.options.orientation ===
      DCTImageWithTextOrientation.IMAGE_ON_LEFT
    );
  }

  get uniqueID() {
    return generateID();
  }

  get hasCaption() {
    return (
      this.data.options.gallery_caption_settings.type !==
      GalleryCaptionSettingTypes.DISABLED
    );
  }

  get caption() {
    const { type, static_caption } = this.data.options.gallery_caption_settings;
    return type === GalleryCaptionSettingTypes.STATIC
      ? static_caption
      : buildCaptionsFromImages(this.data.images);
  }

  get wrapperClass() {
    return [
      this.rootClass,
      ...createModifierClass(
        this.rootClass,
        this.flipped,
        "flipped"
      ),
    ];
  }

  get imagesClass() {
    return [
      `${this.rootClass}__images`,
      ...createModifierClass(
        `${this.rootClass}__images`,
        this.hasLargeText,
        "hasLargeText"
      ),
      ...createModifierClass(
        `${this.rootClass}__images`,
        this.hasLargeImages,
        "hasLargeImages"
      ),
    ];
  }

  onScroll(scroll: number) {
    const { start, end, amounts } = this.measurements;

    if (this.isInView) {
      if (scroll < start) this.transform(0, 0);
      else if (scroll >= start && scroll <= end) {
        this.transform(
          mapAnimation([start, end], [0, amounts[0]], scroll),
          mapAnimation([start, end], [0, amounts[1]], scroll)
        );
      } else if (scroll > end) this.transform(amounts[0], amounts[1]);
    }
  }

  transform(targetText: number, targetImage: number) {
    if (isNaN(targetText) || isNaN(targetImage)) return;
    const textLerp = lerp(this.textCache, targetText);
    const imageLerp = lerp(this.imageCache, targetImage);
    this.textRef.style.transform = `translateY(${textLerp}px) translateZ(0)`;
    this.imageRef.style.transform = `translateY(${imageLerp}px) translateZ(0)`;
    this.textCache = textLerp;
    this.imageCache = imageLerp;
  }

  public calc() {
    const { wrapperRef, textRef, imageRef } = this;
    const top = wrapperRef.offsetTop;

    if (top) {
      const margin = window
        ? parseInt(window.getComputedStyle(wrapperRef)["padding-left"])
        : 0;
      const start = top - 75 - margin;
      const end = top + wrapperRef.offsetHeight - window.innerHeight + margin;

      const moveText = imageRef.offsetHeight > textRef.offsetHeight;
      const fullAmount = moveText
        ? wrapperRef.offsetHeight - textRef.offsetHeight
        : wrapperRef.offsetHeight - imageRef.offsetHeight;
      const threshold = end - start;
      const isShortAnim = fullAmount / 2 > threshold;

      this.measurements = {
        start,
        end: isShortAnim ? start + fullAmount : end,
        amounts: moveText ? [fullAmount, 0] : [0, fullAmount],
      };
    }
  }

  onResize() {
    this.$nextTick(() => this.calc());
  }

  imageLoaded() {
    this.imageLoadCounter += 1;
    if (this.imageLoadCounter === this.images.length) this.onResize();
  }
}
