
import { Vue, Component, Prop } from "vue-property-decorator";
import { SmartSVG, SVGLibraryCustom } from "_components";
import { DynamicPageHierarchySingle } from "@app/views/DynamicPage/types";
import { LinkButton } from "../components";
@Component({
  name: "CrumbGenerator",
  components: { LinkButton, SmartSVG },
})
export default class CrumbGenerator extends Vue {
  @Prop() crumb: DynamicPageHierarchySingle;
  @Prop({ required: true, type: String }) rootClass: string;

  icon = SVGLibraryCustom.PQArrowRightBreadcrumbs;
}
