
import { Mixins, Component, Prop, Ref } from "vue-property-decorator";
import { SmartImage } from "_components";
import { WPImage } from "_types";
import { GSAP } from "_core";
import { generateID } from "_utils";
import { onView, onResize } from "_mixins";

const DELAY = 5;
const DURATION = 4;

@Component({
  name: "ParallaxGallery",
  components: { SmartImage },
})
export default class ParallaxGallery extends Mixins(onView, onResize) {
  @Prop({ required: true, type: Array }) images: WPImage[];
  @Prop({ default: DELAY, type: Number }) delay: number;
  @Prop({ default: DURATION, type: Number }) duration: number;

  rootClass = "c-parallax-gallery";

  @Ref() containerRef: HTMLElement;
  @Ref() imageWrapper: HTMLElement[];
  @Ref() imageRef: SmartImage[];

  private tl: gsap.core.Timeline;
  private hasAnimation = false;

  cachedWidth = 0;

  get formattedImages() {
    return this.images.map((image) => ({
      image,
      fit: "cover",
    }));
  }

  mounted() {
    this.cachedWidth = window.innerWidth;
    if (this.formattedImages.length > 1) this.setupAnimation();
  }

  onResize() {
    if (this.hasAnimation && this.cachedWidth !== window.innerWidth) {
      this.tl.kill();
      this.$nextTick(() => this.setupAnimation());
    }
  }

  onView(inView: boolean) {
    if (this.hasAnimation) {
      if (inView) this.tl.play();
      else this.tl.pause();
    }
  }

  private setupAnimation() {
    this.hasAnimation = true;
    const width = this.containerRef.offsetWidth;
    const { imageWrapper: wrapper, imageRef, duration, delay } = this;

    this.tl = GSAP.timeline({ repeat: -1, delay: Math.random() * 3});
    this.tl.set(imageRef[0].$el, { x: 0, zIndex: 0 });
    wrapper.forEach((image, index) => {
      if (index !== 0) {
        this.tl.set(image, { x: width });
      }
    });
    wrapper.forEach((image, index) => {
      if (index !== 0) {
        this.tl.to(image, { x: 0, duration }, `+=${delay}`);
        this.tl.fromTo(
          imageRef[index].$el,
          { x: width * -1 },
          { x: 0, duration },
          `<`
        );
      }
    });

    this.tl.set(wrapper[0], { x: width, zIndex: 1 }, `+=${delay}`);
    this.tl.to(wrapper[0], { x: 0, duration }, "<");
    this.tl.fromTo(imageRef[0].$el, { x: width * -1 }, { x: 0, duration }, "<");
  }

  private generateID() {
    return generateID();
  }
}
