
import { Vue, Component, Prop, Ref } from "vue-property-decorator";
import { SmartImage, SmartImageProps, SmartText } from "_components";
import { generateID, mapAnimation } from "_utils";
import {
  WPImage,
  GalleryCaptionSettings,
  GalleryCaptionSettingTypes,
} from "_types";
import GalleryBase from "../GalleryBase/index.vue";
import NextPrevButtons from "./NextPrevButtons.vue";

const DEFAULT_CAPTIONS: GalleryCaptionSettings = {
  type: GalleryCaptionSettingTypes.DISABLED,
  static_caption: "",
};

@Component({
  name: "Gallery",
  components: { SmartImage, SmartText, GalleryBase, NextPrevButtons },
})
export default class Gallery extends Vue {
  @Prop({ required: true, type: Array }) images: WPImage[];
  @Prop({ required: false, default: () => DEFAULT_CAPTIONS })
  captionSettings: GalleryCaptionSettings;
  @Ref() gallery: GalleryBase;

  rootClass = "c-gallery";

  get uniqueID() {
    return generateID();
  }

  get formattedImages(): SmartImageProps[] {
    return this.images.map((image) => ({
      image,
      fit: "cover",
      lazyload: false,
    }));
  }

  get generateStyle() {
    return this.images.map((image) => ({
      width: `${mapAnimation([image.height, 0], [image.width, 0], 500)}px`,
    }));
  }

  get captions() {
    return this.images.map((image) => image.caption && image.caption);
  }

  get showNormalCaptions() {
    return this.captionSettings.type === GalleryCaptionSettingTypes.NORMAL;
  }

  get showStaticCaptions() {
    return this.captionSettings.type === GalleryCaptionSettingTypes.STATIC;
  }

  get staticCaption() {
    return this.showStaticCaptions && this.captionSettings.static_caption;
  }

  next() {
    this.gallery.next();
  }

  prev() {
    this.gallery.prev();
  }
}
