/*
    Add SVGs to be accessible to CL and SmartSVG by simply
    adding it to the ./library folder and adding an enum
    matching the file name below.
*/

export enum SVGLibraryCustom {
  ArrowUpRight = "arrow-up-right",
  ArrowLeft = "arrow-left",
  ArrowRight = "arrow-right",
  BoilerplateBackButton = "boilerplate-back-button",
  BoilerplateClose = "boilerplate-close",
  BoilerplateCopy = "boilerplate-copy",
  BoilerplateError = "boilerplate-error",
  LhLogo = "lh-logo",
  PQ2023Logo = "pq-2023-logo",
  PQArrowLink = 'pq-arrow-link',
  PQArrowRight = 'pq-arrow-right',
  PQArrowLeft = 'pq-arrow-left',
  PQAudio = 'pq-audio',
  PQClose = 'pq-close',
  PQEmail = 'pq-email',
  PQFacebook = 'pq-facebook',
  PQFilter = 'pq-filter',
  PQInstagram = 'pq-instagram',
  PQLogo = "pq-logo",
  PQTwitter = 'pq-twitter',
  PQYoutube = 'pq-youtube',
  MenuBurger = "menu-burger",
  MagnifyingGlass = "magnifying-glass",
  ChevronDown = "chevron-down",
  PQHeartOutline = "pq-heart-outline",
  PQHeart = "pq-heart",
  PQCalendar = "pq-calendar",
  PQPlay = 'pq-play',
  PQFlickr = 'pq-flickr',
  PQTimeline = 'pq-timeline',
  PQAgenda = 'pq-agenda',
  PQPlus = 'pq-plus',
  PQMinus = 'pq-minus',
  PQResetZoom = 'pq-reset-zoom',
  PQPosition = 'pq-position',
  PQLocation = 'pq-location',
  PQMainLocation = 'pq-main-location',
  PQMapLegend = 'pq-map-legend',
  PQProgrammeQ = 'pq-programme-q',
  PQArrowRightBreadcrumbs = 'pq-arrow-right-breadcrumbs',
  PQMarker = 'pq-marker',
  PQLegendHeadphones = 'pq-legend-headphones',
  PQLegendPhone = 'pq-legend-phone',
  PQLegendRegistration = 'pq-legend-registration',
  PQLegendMoving = 'pq-legend-moving',
  PQLegendDuration = 'pq-legend-duration',
  PQMap = 'pq-map',
  PQAllSections = 'pq-all-sections',
}
