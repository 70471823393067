
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { Cookies } from "_core";
import { MainSettings } from "_types";
import { SmartText, WYSIWYG } from "_components";
import { CTAButton } from "../components";

@Component({
  name: "CookieDialog",
  components: { SmartText, WYSIWYG, CTAButton },
})
export default class CookieDialog extends Vue {
  @Prop({ required: true }) settings: MainSettings;



  rootClass = "c-cookie-dialog";
  showCookieDialog = false;

  @Watch("translations")
  onGdprSettingsLoad() {
    if (!Cookies.isCookiesEnabled && Boolean(this.translations.cookie_dialog_message)) {
      this.showCookieDialog = true
    }
  }

  get translations() {
    const { settings } = this;
    return (
      settings && settings.general && settings.general.translation_keys.gdpr
    );
  }

  get dialogMessage() {
    return this.translations && this.translations.cookie_dialog_message;
  }

  get accept() {
    return this.translations && this.translations.accept;
  }

  get reject() {
    return this.translations && this.translations.reject;
  }

  handleAccept() {
    Cookies.enableCookies();
    this.showCookieDialog = false;
  }

  handleReject() {
    Cookies.disableCookies();
    this.showCookieDialog = false;
  }
}
