require("intersection-observer");

import { config, Cookies, CookieTypes, Locale } from "_core";
import { VueConstructor } from "vue/types/umd";

export class initApp {
  private vueInstance: VueConstructor;

  constructor(props: VueConstructor) {
    this.vueInstance = props;
    this.vueInstance.prototype.$projectName = config.project.name;
    this.setupDebug();
    this.setupObservers();
    this.setupPerf();
    config.hasTouchScreen = this.isTouchDevice();
    // this.setupLocale();
  }
  private isTouchDevice(): boolean {
    return !!(
      ("ontouchstart" in window || navigator.maxTouchPoints) // works on most browsers
    );
  }
  private setupPerf() {
    function isFastEnough() {
      const start = performance.now();
      let i = 10000;
      while (i--) {
        Math.sqrt(i);
      }
      return performance.now() - start < 1;
    }

    config.isHighPerf = config.options.enablePerf ? isFastEnough() : true;
  }
  private interSectionCallback = function (entries: any): void {
    entries.forEach((entry: any): void => {
      const target = entry.target.__vue__;
      if (!target) return;
      if (entry.isIntersecting) target.enterView && target.enterView();
      else target.leaveView && target.leaveView();
    });
  };
  private interSectionCallbackImg = function (entries: any): void {
    entries.forEach((entry: any): void => {
      const target = entry.target.__vue__;
      if (!target) return;
      if (entry.isIntersecting) target.enterLazyView && target.enterLazyView();
      else target.leaveLazyView && target.leaveLazyView();
    });
  };
  public setupDebug(): void {
    this.vueInstance.prototype.$debug = config.debug;
  }
  public setupObservers(): void {
    // Observer for everything minus images
    this.vueInstance.prototype.$observer = new IntersectionObserver(
      this.interSectionCallback
    );

    // Observer for actual loading of images
    this.vueInstance.prototype.$imgObserver = new IntersectionObserver(
      this.interSectionCallbackImg,
      {
        rootMargin: config.smartImageConfig.rootMarginIntersectionObserver,
      }
    );
  }

  // public setupLocale(): void {
  // }
  //   console.log('setupLocale');
  //   if (!Cookies.getCookie(CookieTypes.Locale)) {
  //     const browserLocale = () => {
  //       if (window && window.navigator.language) {
  //         const detectedLocale = window.navigator.language.substring(0, 2);
  //         if (config.languageSettings.all.includes(detectedLocale)) return detectedLocale;
  //       }
  //       return null
  //     };

  //     const fetchedLocale = browserLocale();
  //     const locale = (fetchedLocale ? fetchedLocale : config.languageSettings.default);
  //     // Cookies.setCookie(CookieTypes.Locale, locale);
  //   }

  // }
}
