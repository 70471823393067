/*
    Any types/enums/interface placed here will be appended to '_types'
*/

import { WPCategoryType, ThemeLink, MainSettingsBase, WPImage, WPPostType, WPTagType } from "_types";
import { EVENT_TAKEOVER_PRIMARY_COLOUR } from "./views/Home/types";
export { THEME_LINK_TYPE, THEME_LINKS, ThemeLink, ThemeLinkLogic } from '_types';
export { HomePageCMS } from "./views/Home/types";
export { DynamicPageProps } from "./views/DynamicPage/types";
export { DynamicContentModule } from "./components/DynamicContent/types";

export type MainSettings = {
  header: HeaderSettings;
  footer: FooterSettings;
  categories: WPCategoryType[];
  general: GeneralSettings;
} & MainSettingsBase;

// Edit these per theme
export type HeaderSettings = {
  links_in_header: { link: ThemeLink }[];
  translations: {
    homepage: string;
    articles: string;
    current_link: string;
    all_articles: string;
    filters: string;
  }
  contact: PQHeaderMenuContact;
  menu_pages: PQHeaderMenuBlock[];
  event_takeover: {
    enable: boolean;
    primary_colour: EVENT_TAKEOVER_PRIMARY_COLOUR
  }
};

export type FooterSettings = {
  enable: boolean;
  links: { link: ThemeLink }[];
  newsletter: {
    enable_newsletter: boolean;
    api_key: string;
    newsletter_text: string;
    input_placeholder: string;
    success_message: string;
    invalid_email_address_message: string;
    email_already_exists_message: string;
  }
  contact: { enable_contact: boolean } & PQHeaderMenuContact
};

export type GeneralSettings = {
  translation_keys: ThemeTranslations;
  exclusive_content: {
    login_page: string;
    industry_guide: string;
    digital_archive: string;
  }
  routing: {
    archive: string;
    projects: string;
    publications: string;
    exclusive_content_login: string; //TODO: Delete
  },

}

export type PQHeaderMenuContact = {
  email: string;
  contact_details: string;
  text: string;
  social_media: { link: string, type: PQ_HEADER_SOCIAL_MEDIA_TYPE }[];
}

export type PQHeaderMenuBlock = {
  title: string;
  links: { link: ThemeLink }[]
}

export enum PQ_HEADER_SOCIAL_MEDIA_TYPE {
  Twitter = "twitter",
  Facebook = "facebook",
  Instagram = "instagram",
  Youtube = "youtube",
  Flickr = 'flickr'
}

export enum PQ_CARD_ASPECT_RATIO {
  SQUARE = "square",
  WIDE = "wide",
  TALL = "tall",
}

export type PQCardType = {
  card: {
    card_images: WPImage[];
    headline: string;
    show_on_homepage: boolean;
    show_in_related_posts: boolean;
    aspect_ratio: PQ_CARD_ASPECT_RATIO;
  };
  category: WPCategoryType[];
  post_data: WPPostType;
  permalink: string;
  tags: WPTagType[];
  colourOverride?: PQ_PROJECT_COLOURS;
}

export type ThemeTranslations = {
  general: {
    publication: string;
  };
  breadcrumbs: {
    homepage: string;
    current_link: string;
    articles: string;
    archive: string;
    projects: string;
    publications: string;
  };
  articles: {
    all_articles: string;
    filters: string;
  };
  search: {
    search: string;
    search_results_for: string;
    no_results_found_for: string;
    view_all_results: string;
    clear_search: string;
  }
  gdpr: {
    cookie_dialog_message: string;
    accept: string;
    reject: string;
  }
  four_o_four: {
    message: string;
    link_to_home: string;
    link_to_news: string;
  };
  advanced_timeline: {
    my_schedule: string;
    all_activities: string;
    agenda_view: string;
    timeline_view: string;
    no_events: string;
  }
}

export enum GalleryCaptionSettingTypes {
  DISABLED = 'disabled',
  NORMAL = 'normal',
  STATIC = 'static',
}

export type GalleryCaptionSettings = {
  type: GalleryCaptionSettingTypes,
  static_caption: string;
}

export enum PQ_PROJECT_COLOURS {
  BLACK = 'black',
  WHITE = 'white',
  RED = 'red',
  PINK = 'pink',
  ORANGE = 'orange',
  GREEN = 'green',
  PURPLE = 'purple',
  BLUE = 'blue',
  LIGHT_BLUE = 'light-blue',
  YELLOW = 'yellow',
  GREY = 'grey',
  LIGHT_GREEN = 'light-green',
}
