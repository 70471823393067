import { Vue, Component, Watch } from "vue-property-decorator";
import { ApiRoutes, ACTIONS, SearchResult, EMPTY_SEARCH } from "_types";
import { EventBus, ApiFactory } from "_core";

/**
 * Creates and handles API request with automatic vuex storage.
 * @function onSearchLoad(dataFromApi): void
 * @param searchQuery: string - Provide a query string
 */
@Component
export class getSearchMixin extends Vue {
  public searchData: SearchResult = EMPTY_SEARCH;
  public searchLoading = false;
  private searchQuery: string = null;

  @Watch("searchQuery")
  runSearchQuery() {
    if (this.searchQuery) this.initSearch();
    else this.clearSearch();
  }

  /**
   * Call onSearchLoad(SearchResult) to listen when data returns from API
   * */
  onSearchLoad(data: SearchResult) {}

  initSearch() {
    if (!this.cachedSearchData) {
      this.$store.dispatch(ACTIONS.GET_DATA, this.computedQuery);
      this.searchLoading = true;
      EventBus.$once(this.computedQuery, (data: SearchResult) => {
        this.searchData = data;
        this.searchLoading = false;
        this.onSearchLoad(data);
      });
    } else {
      this.searchData = this.cachedSearchData;
      this.onSearchLoad(this.cachedSearchData);
    }
  }

  get cachedSearchData() {
    return (
      this.searchQuery && this.$store.state.storeFactory[this.computedQuery]
    );
  }

  private get computedQuery() {
    return ApiFactory.storeNameBuilder(this.searchQuery);
  }

  public search(query: string, filter?: { type?: string; category?: string }) {
    this.searchQuery =
      Boolean(query) &&
      ApiFactory.getQuery(ApiRoutes.Search, this.$route, {
        query: encodeURIComponent(query.trim()),
        ...(filter && filter),
      });
  }

  public clearSearch() {
    this.searchLoading = false;
    this.searchData = EMPTY_SEARCH;
    this.onSearchLoad(EMPTY_SEARCH);
  }
}
