enum FontWeight {
  "regular",
  "italic",
}

enum FontSubset {
  "latin",
}

type GoogleFont = {
  font: string;
  subsets: FontSubset[];
  variants: FontWeight[];
};

export enum FontModes {
  "custom" = "custom",
  "google" = "google",
  "disabled" = "disabled",
}

export type FontSettingsDetail = {
  google_font?: GoogleFont;
  mode: FontModes;
};

export type FontSettings = {
  body_font: FontSettingsDetail;
  alt_font: FontSettingsDetail;
  hero_font: FontSettingsDetail;
};

type FontSettingsByConfig = {
  name: string;
  tag: string;
};

export type FontSettingsType = {
  fontClasses: {
    hero: FontSettingsByConfig[];
    body: FontSettingsByConfig[];
    alt: FontSettingsByConfig[];
  };
  customFonts: {
    hero_font: string | null;
    body_font: string | null;
    alt_font: string | null;
  };
};
