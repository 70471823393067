
import { Vue, Component, Prop } from "vue-property-decorator";
import { SmartText } from "_components";
import { WPTagType } from "_types";
import { generateID, createModifierClass } from "_utils";

@Component({
  name: "TagHandler",
  components: { SmartText },
})
export default class TagHandler extends Vue {
  @Prop() tags: WPTagType[];
  @Prop({ default: false, type: Boolean }) noMargin: boolean;
  @Prop({ default: false, type: Boolean }) invert: boolean;
  @Prop({ default: false, type: Boolean }) alignRight: boolean;

  rootClass = "c-tag-handler";

  get uniqueID() {
    return generateID();
  }

  get generateClass() {
    return [
      this.rootClass,
      ...createModifierClass(this.rootClass, this.noMargin, "no-margin"),
      ...createModifierClass(this.rootClass, this.invert, "invert"),
      ...createModifierClass(this.rootClass, this.alignRight, "align-right"),
    ];
  }
}
