import Vue from "vue";
export { EventBusChannelsThemeSpecific } from "@app/project-config";
export const EventBus = new Vue();

export enum EventBusChannels {
  // General
  Resize = "resize",
  Scroll = "scroll",
  Blur = "blur",
  Focus = "focus",

  // Router
  ChangingPage = "changing:page",
  PageLoaded = 'loaded:page',

  // Fonts
  FontsInit = "fonts:init",
  FontsAttached = "fonts:attached",
  FontsLoaded = "fonts:loaded",

  // Modal
  ModalInit = "modal:init",
  ModalClose = "modal:close",

  // Cookies
  CookiesEnabled = "cookies:enabled",
  CookiesDisabled = "cookies:disabled",

  // ExclusiveContent Login Page Detected
  HeaderOverride = "header:override",

  // Map modifier for header
  IsOpenHeaderOverride = 'header:isopen:override'
}
