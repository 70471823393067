/*
    '_components' as an import points to this file. Internal
    boilerplate components should be imported here, then all
    theme specific components are imported afterwards. 
*/

export { default as SmartText } from "./SmartText/index.vue";
export { default as WYSIWYG } from "./WYSIWYG/index.vue";

export { default as SmartImage } from "./SmartImage/index.vue";
export { SmartImageProps } from "./SmartImage/src/types";

export { default as VideoPlayer } from "./VideoPlayer/index.vue";

// Import SmartSVG and all global collections of SVGs (Theme specific and within boilerplate)
export { default as SmartSVG } from "./SmartSVG/index.vue";

export { default as LottiePlayer } from './LottiePlayer/index.vue';

export const PasswordProtectorPage = () =>
  import(
    /* webpackChunkName: "__PASSWORD__" */ "./PasswordProtector/index.vue"
  );

export * from "./SmartSVG/library/";
export * from "@app/components/SVGLibrary/";

// DEV ONLY
export const DevTools = () =>
  import(
    /* webpackChunkName: "__DEV_TOOLS__" */ "@boilerplate/DevTools/index.vue"
  );



// Import all theme components
export * from "@app/components/components";
