
import { Mixins, Component, Prop, Ref } from "vue-property-decorator";
import { SmartImage, SmartImageProps, SmartText } from "_components";
import { onScroll, onView, onResize } from "_mixins";
import {
  mapAnimation,
  transform,
  generateID,
  lerp,
  buildCaptionsFromImages,
} from "_utils";
import { GalleryCaptionSettingTypes } from "_types";
import { DynamicContentImageGalleryProps } from "../../types";
import { DCTImageGalleryOrientation } from "./types";
import SingleTextBlock from "../Text/TextBlock.vue";
import { config } from "_core";

@Component({
  name: "DCImageGallery",
  components: { SmartImage, SingleTextBlock, SmartText },
})
export default class DCImageGallery extends Mixins(onScroll, onView, onResize) {
  @Prop({ required: true }) data: DynamicContentImageGalleryProps;
  @Ref() wrapperRef: HTMLElement;
  @Ref() primaryImageRef: HTMLElement;
  @Ref() secondaryImagesRef: HTMLElement;
  @Ref() pseudoRef: HTMLElement;

  rootClass = "c-dc-image-gallery";
  measurements: {
    start: number;
    mid: number;
    end: number;
    startPos: { x: number; y: number; scale: number }[];
  } | null = null;
  imageLoadCounter = 0;
  scrollCache = [];

  get secondaryImages(): SmartImageProps[] {
    return this.data.secondary_images.length
      ? this.data.secondary_images.map((image) => ({
          image,
          lazyload: true,
          fit: "contain",
        }))
      : [];
  }

  get primaryImage(): SmartImageProps {
    return this.data.primary_image
      ? {
          image: this.data.primary_image[0],
          lazyload: true,
          fit: "contain",
        }
      : null;
  }

  get flipped() {
    return (
      this.data.options.orientation ===
      DCTImageGalleryOrientation.PRIMARY_IMAGE_ON_RIGHT
    );
  }

  get id() {
    return generateID();
  }

  get hasCaption() {
    return (
      this.data.options.gallery_caption_settings.type !==
      GalleryCaptionSettingTypes.DISABLED
    );
  }

  get primaryCaption() {
    const prim = this.data.primary_image[0];
    return (
      prim &&
      this.data.options.gallery_caption_settings.type ===
        GalleryCaptionSettingTypes.NORMAL &&
      prim.caption
    );
  }

  get secondaryCaption() {
    const captions = this.data.options.gallery_caption_settings;
    return captions.type === GalleryCaptionSettingTypes.STATIC
      ? captions.static_caption
      : buildCaptionsFromImages(this.data.secondary_images);
  }

  onResize() {
    if (this.measurements) this.calc();
  }

  onScroll(scroll: number) {
    if ((!this.isInView || !this.measurements) || (window.innerWidth < config.mobBreakpoint)) return;

    const { start, mid, end, startPos } = this.measurements;
    const targets = Array.from(this.secondaryImagesRef.children);

    if (scroll < start) {
      targets.forEach((child: HTMLElement, index) => {
        this.cacheTransform(
          child,
          index,
          startPos[index].x,
          startPos[index].y,
          startPos[index].scale
        );
      });
    } else if (scroll >= start && scroll < mid) {
      targets.forEach((child: HTMLElement, index) => {
        this.cacheTransform(
          child,
          index,
          startPos[index].x,
          mapAnimation([start, mid], [startPos[index].y, 0], scroll),
          startPos[index].scale
        );
      });
    } else if (scroll >= mid && scroll <= end) {
      targets.forEach((child: HTMLElement, index) => {
        this.cacheTransform(
          child,
          index,
          mapAnimation([mid, end], [startPos[index].x, 0], scroll),
          0,
          mapAnimation([mid, end], [startPos[index].scale, 1], scroll)
        );
      });
    } else if (scroll > end) {
      targets.forEach((child: HTMLElement, index) => {
        this.cacheTransform(child, index, 0, 0, 1);
      });
    }
  }

  cacheTransform(element: HTMLElement, index, x, y, scale) {
    const cached = this.scrollCache[index]
      ? this.scrollCache[index]
      : { x: 0, y: 0, scale: 0 };
    const lerped = {
      x: x,
      y: lerp(cached.y, y),
      scale: lerp(cached.scale, scale),
    };

    transform(element, lerped.x, lerped.y, lerped.scale);
    this.scrollCache[index] = lerped;
  }

  calc() {
    const startPos = Array.from(this.pseudoRef.children).map(
      (child: HTMLElement) => ({
        width: child.offsetWidth,
        left: child.offsetLeft,
      })
    );

    const endPos = Array.from(this.secondaryImagesRef.children).map(
      (child: HTMLElement) => ({
        width: child.offsetWidth,
        left: child.offsetLeft,
        top: child.offsetTop,
      })
    );

    const startModPos = startPos.map((pos, index) => ({
      x: pos.left - endPos[index].left,
      y: endPos[index].top * -1,
      scale: pos.width / endPos[index].width,
    }));

    const startPoint =
      this.wrapperRef.offsetTop + endPos[0].top - window.innerHeight;
    const end =
      this.wrapperRef.offsetTop +
      this.wrapperRef.offsetHeight -
      window.innerHeight;

    this.measurements = {
      start:
        startPoint <= this.wrapperRef.offsetTop
          ? startPoint
          : this.wrapperRef.offsetTop,
      mid: (end - startPoint) / 2 + startPoint,
      end,
      startPos: startModPos,
    };
  }

  imageLoaded() {
    this.imageLoadCounter += 1;
    if (
      this.imageLoadCounter ===
      this.secondaryImages.length + Number(Boolean(this.primaryImage))
    ) {
      this.calc();
    }
  }
}
