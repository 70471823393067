
import { Vue, Component, Prop } from "vue-property-decorator";
import { SVGCore } from "./core";

@Component({
  name: "SmartSVG",
})
export default class SmartSVG extends Vue {
  @Prop({ required: true, type: String }) icon: string;
  @Prop({ default: "", type: String }) type: string;

  rootClass = "bc-svg";

  get iconObject() {
    return SVGCore.getIcon(this.icon);
  }

  get iconURL() {
    return this.iconObject && this.iconObject.category !== "custom"
      ? SVGCore.buildURL(this.iconObject)
      : null;
  }

  get generateClass() {
    return [
      this.rootClass,
      ...(this.type ? [`${this.rootClass}--${this.type}`] : []),
    ];
  }

  get dynamicIcon() {
    if (this.iconObject) {
      if (this.iconObject.category === "custom") {
        return () =>
          import(
            /* webpackChunkName: "icon-custom" */
            `@app/components/SVGLibrary/library/${this.iconObject.name}.svg`
          ).finally(() => {
            this.$emit("loaded");
          });
      } else {
        return () =>
          import(
            /* webpackChunkName: "icon" */
            `${this.iconURL}`
          ).finally(() => {
            this.$emit("loaded");
          });
      }
    }
  }
}
