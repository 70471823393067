import { Logger } from "./logger";
import { axios, Locale, config } from "_core";
import { AnyObjectOfStrings, ApiRoutes, RouterPageNames } from "_types";
import { Route } from "vue-router";

const DEBUG = false;

const headers = {
  Accept: "application/json",
  "Content-Type": "application/json;charset=UTF-8",
};

class ApiFactoryClass {
  logger: Logger;

  public storeNamePrefix = "api:get:";

  constructor() {
    this.logger = new Logger("API Factory V2", DEBUG);
  }

  get baseURL() {
    return `${config.baseOrigin}/${config.api.location}`;
  }

  public getApi(callback: Function, prefixedQuery: string) {
    const name = this.removePrefix(prefixedQuery);
    const endpoint = `${this.baseURL}${name}`;
    axios
      .get(endpoint, { headers })
      .then((response) => {
        const { data } = response;
        this.logger.log(`API response of: ${name}`, data);
        callback(data);
      })
      .catch((error) => {
        this.logger.error(
          `Error: Api-factory (route: ${name})`,
          endpoint,
          error
        );
        throw error;
      });
  }

  public getQuery(
    routeName: ApiRoutes,
    route: Route,
    search?: { query: string; filter?: { type?: string; category?: string } },
    customParams?: { [key: string]: string | number }
  ) {
    switch (routeName) {
      case ApiRoutes.HomePage:
        if (this.isAuth(route))
          return this.createURL(
            [ApiRoutes.DynamicPage],
            route.query as AnyObjectOfStrings
          );
        return this.createURL([ApiRoutes.Static, ApiRoutes.HomePage]);
      case ApiRoutes.DynamicPage:
        if (this.isAuth(route)) {
          return this.createURL([ApiRoutes.DynamicPage], {
            ...{ url: route.fullPath, slug: route.params.slug, ...Object.assign({}, route.query), },
          });
        };
        return this.createURL([ApiRoutes.DynamicPage], {
          ...{ url: route.fullPath, slug: route.params.slug },
        });
      case ApiRoutes.Settings:
        return this.createURL([ApiRoutes.Static, ApiRoutes.Settings]);
      case ApiRoutes.Search:
        return this.createURL([ApiRoutes.Search], {
          ...{ query: search.query },
          ...(search.filter && search.filter),
        });
      case ApiRoutes.QueryPost:
        return this.createURL([ApiRoutes.QueryPost], {
          ...(customParams && customParams),
        });
      default:
        return routeName;
    }
  }

  public isAuth(route: Route): boolean {
    return this.isDraft(route) && config.auth.isLoggedIn;
  }

  public hasCache(context, name: string): boolean {
    this.logger.log(`Has cache? (route: ${name})`, !!context.state[name]);
    return !!context.state[name];
  }

  public storeNameBuilder(name: string) {
    return `${this.storeNamePrefix}${name}`;
  }

  public removePrefix(prefixedQuery: string) {
    return prefixedQuery.substring(0, this.storeNamePrefix.length) ===
      this.storeNamePrefix
      ? prefixedQuery.substring(this.storeNamePrefix.length)
      : prefixedQuery;
  }

  private isDraft(route: Route) {
    return Boolean(route.query.preview);
  }

  private createURL(slugs: ApiRoutes[], params?: AnyObjectOfStrings | any) {
    const formattedParams = this.buildParams({
      ...Object.assign({}, params),
      ...(config.languageSettings.enabled && { lang: Locale.currentLocale }),
    });
    return `${slugs.join("/")}${formattedParams && "?"}${formattedParams}`;
  }

  private buildParams(params?: AnyObjectOfStrings) {
    return (
      params &&
      Object.entries(params)
        .map((param) => `${param[0]}=${param[1]}`)
        .join("&")
    );
  }
}

export const ApiFactory = new ApiFactoryClass();
