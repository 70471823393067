
import { Vue, Component, Prop } from "vue-property-decorator";
import { SmartText, SmartSVG, SVGLibraryCustom } from "_components";
import { createModifierClass } from "_utils";
import { ThemeLink, ThemeLinkLogic } from "_types";
import { LinkLogic } from "../components";

enum BUTTON_TYPES {
  DEFAULT = "default",
  BLOCK = "block",
  SIMPLE = "simple",
}

@Component({
  name: "CTAButton",
  components: { LinkLogic, SmartText, SmartSVG },
})
export default class CTAButton extends Vue {
  @Prop({ default: BUTTON_TYPES.DEFAULT }) type: BUTTON_TYPES;
  @Prop({ default: false, type: Boolean }) secondary: boolean;
  @Prop({ default: false, type: Boolean }) invert: boolean;
  @Prop({ required: false, type: String }) to: string;
  @Prop({ default: true, type: Boolean }) showIcon: boolean;
  @Prop({ default: null, type: String }) icon: string | null;
  @Prop() link: ThemeLink | ThemeLinkLogic;
  @Prop({ default: "", type: String }) colorOverride: string;
  @Prop({ default: false, type: Boolean }) openInNewTab: boolean;

  rootClass = "c-cta-button";

  click() {
    this.$emit("click");
  }

  get hasColorOverride() {
    return Boolean(this.colorOverride);
  }

  get iconToLoad() {
    return this.icon ? this.icon : SVGLibraryCustom.PQArrowLink;
  }

  get iconClassName() {
    const base = `${this.rootClass}__svg`;
    return [
      base,
      ...createModifierClass(this.rootClass, Boolean(this.icon), "override"),
    ];
  }

  get classes() {
    return [
      this.rootClass,
      `${this.rootClass}--${this.type}`,
      ...createModifierClass(this.rootClass, this.secondary, "secondary"),
      ...createModifierClass(this.rootClass, this.invert, "invert"),
      ...createModifierClass(
        `${this.rootClass}--${this.type}`,
        this.hasColorOverride,
        this.colorOverride
      ),
    ];
  }
}
