
import { Mixins, Component, Prop, Ref } from "vue-property-decorator";
import { VideoPlayer } from "_components";
import { onScroll, onView, onResize } from "_mixins";
import { mapAnimation, generateID } from "_utils";
import { DynamicContentVideoWithTextProps } from "../../types";
import SingleTextBlock from "../Text/TextBlock.vue";
import { DCTVideoWithTextOrientation } from "./types";

@Component({
  name: "DCVideoWithText",
  components: { VideoPlayer, SingleTextBlock },
})
export default class DCVideoWithText extends Mixins(
  onScroll,
  onView,
  onResize
) {
  @Prop({ required: true }) data: DynamicContentVideoWithTextProps;
  @Ref() wrapperRef: HTMLElement;
  @Ref() textRef: HTMLElement;
  @Ref() videoRef: HTMLElement;

  rootClass = "c-dc-video-with-text";
  measurements = { start: 0, end: 0, amounts: [0, 0] };

  get videoId(): string {
    return this.data.video;
  }

  get textElements() {
    return this.data.text_selector.length && this.data.text_selector;
  }

  get flipped() {
    return (
      this.data.options.orientation === DCTVideoWithTextOrientation.VIDEO_ON_LEFT
    );
  }

  get uniqueID() {
    return generateID();
  }

  onScroll(scroll: number) {
    // this.calc();
    // const { start, end, amounts } = this.measurements;
    // if (this.isInView) {
    //   if (scroll < start) {
    //     this.textRef.style.transform = null;
    //     this.videoRef.style.transform = null;
    //   } else if (scroll >= start && scroll <= end) {
    //     const textAnim = mapAnimation([start, end], [0, amounts[0]], scroll);
    //     const videoAnim = mapAnimation([start, end], [0, amounts[1]], scroll);
    //     this.textRef.style.transform = `translateY(${textAnim}px)`;
    //     this.videoRef.style.transform = `translateY(${videoAnim}px)`;
    //   }
    //   if (scroll > end) {
    //     this.textRef.style.transform = `translateY(${amounts[0]}px)`;
    //     this.videoRef.style.transform = `translateY(${amounts[1]}px)`;
    //   }
    // }
  }

  calc() {
    const { wrapperRef, textRef, videoRef } = this;
    const margin = window
      ? parseInt(window.getComputedStyle(wrapperRef)["padding-left"])
      : 0;
    const start = wrapperRef.offsetTop - 75 - margin;
    const end =
      wrapperRef.offsetTop +
      wrapperRef.offsetHeight -
      window.innerHeight +
      margin;

    this.measurements = {
      start,
      end,
      amounts:
        videoRef.offsetHeight > textRef.offsetHeight
          ? [wrapperRef.offsetHeight - textRef.offsetHeight, 0]
          : [0, wrapperRef.offsetHeight - videoRef.offsetHeight],
    };
  }

  onResize() {
    // this.$nextTick(() => this.calc());
  }
}
