import * as SVGLibraryCollection from "./library/";
import * as SVGLibraryCustomCollection from "@app/components/SVGLibrary/";

type IconMapType = {
  name: string;
  category: string;
};

export type IconMapTypeComponentLibrary = {
  name: string;
  icons: string[];
};

class SVGCoreClass {
  private iconMap: IconMapType[] = [];

  constructor() {
    this.iconMap = this.createIconMap().flat();
  }

  public getIcon(iconName: string) {
    const selectedIcon = this.iconMap.find((icon) => icon.name === iconName);
    return selectedIcon ?? selectedIcon;
  }

  public buildURL(icon: IconMapType) {
    return `./library/${icon.category}/${icon.name}.svg`;
  }

  private createIconMap() {
    return Object.entries(this.fullSVGLibrary).map((SVGLibrary) =>
      Object.entries(SVGLibrary[1]).map((icon: [string, string]) => ({
        name: icon[1],
        category: this.removePrefix(SVGLibrary[0]).toLowerCase(),
      }))
    );
  }

  // Internal use only
  get fullSVGLibrary() {
    return { ...SVGLibraryCollection, ...SVGLibraryCustomCollection };
  }

  private removePrefix(enumName: string) {
    return enumName.replace(/SVGLibrary/g, "");
  }

  // For component library display purposes
  get getIconMap() {
    return Object.entries(this.fullSVGLibrary).map((SVGLibrary) => ({
      name: this.removePrefix(SVGLibrary[0]),
      icons: Object.entries(SVGLibrary[1]).map(
        (icon: [string, string]) => icon[1]
      ),
    }));
  }

  public getIconEnumName(name: string) {
    let lastEnumTested: string;
    Object.keys(this.fullSVGLibrary).some((key) => {
      lastEnumTested = Object.keys(this.fullSVGLibrary[key]).find(
        (innerKey) => this.fullSVGLibrary[key][innerKey] === name
      );
      return lastEnumTested;
    });
    return lastEnumTested;
  }

  /**
   * Comment this function out when not using (removes
   * from production). Use this to quickly build enums
   * by running 'ls -1' in svg folder, and using that
   * data as an array. Copy object from console log.
   */

  // enumBuilder(arr: string[]) {
  //   const convertToCamelCase = (str: string) => {
  //     return (
  //       str.charAt(0).toUpperCase() +
  //       str
  //         .split("-")
  //         .reduce((a, b) => a + b.charAt(0).toUpperCase() + b.slice(1))
  //         .slice(1)
  //     );
  //   };
  //   const newObj = {};
  //   arr.forEach((el) => {
  //     newObj[convertToCamelCase(el)] = el;
  //   });
  //   return newObj;
  // }
}

export const SVGCore = new SVGCoreClass();
