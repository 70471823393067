import { GalleryCaptionSettings } from "_types";

export enum DCTImageWithTextOrientation {
    IMAGE_ON_LEFT = 'imageOnLeft',
    IMAGE_ON_RIGHT = 'imageOnRight',
}

export enum DCTImageWithTextType {
    HALF_WIDTH = 'halfWidth',
    LARGE_TEXT = 'largeText',
    LARGE_IMAGE = 'largeImage',
}

export type DCImageWithTextOptions = {
    orientation: DCTImageWithTextOrientation;
    type: DCTImageWithTextType
    gallery_caption_settings: GalleryCaptionSettings;
}