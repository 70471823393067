
import { PQCardType } from "@app/types";
import { Vue, Component, Prop } from "vue-property-decorator";
import { SmartText } from "_components";
import { createModifierClass, wordpressDateToString } from "_utils";
// import TagHandler from "../TagHandler/index.vue";

@Component({
  name: "PostGridCardMeta",
  components: { SmartText },
})
export default class PostGridCardMeta extends Vue {
  @Prop() card: PQCardType;
  @Prop({ default: false, type: Boolean }) isWide: boolean;

  rootClass = "c-post-grid-card-meta";

  get title() {
    return this.card.card.headline
      ? this.card.card.headline
      : this.card.post_data.post_name;
  }

  get fontType() {
    return this.isWide ? "hero-H3-normal" : "hero-H4-normal";
  }

  get date() {
    return wordpressDateToString(this.card.post_data.post_date);
  }

  get category() {
    return (
      this.card.category &&
      this.card.category.length &&
      this.card.category[0].name
    );
  }

  // get tags() {
  //   return this.card.tags;
  // }

  get generateClasses() {
    return [
      this.rootClass,
      createModifierClass(this.rootClass, this.isWide, "is-wide"),
    ];
  }
}
