// import { apiFactory } from "./api/api-factory";
import { EventBus, ApiFactory } from "_core";
import { MUTATIONS } from "_types";

export enum ACTIONS {
  GET_DATA = "getData",
}

export default {
  [ACTIONS.GET_DATA]: (context, prefixedQuery: string): Promise<void> => {
    if (ApiFactory.hasCache(context, prefixedQuery)) return;
    else {
      new Promise((resolve) => {
        ApiFactory.getApi(resolve, prefixedQuery);
      })
        .then((data) => {
          context.commit(MUTATIONS.STORE_DATA, { prefixedQuery, data });
          EventBus.$emit(prefixedQuery, data);
        })
        .catch((error) => {
          throw error;
        });
    }
  },
};
