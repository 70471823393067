
import { Mixins, Component, Prop, Ref } from "vue-property-decorator";
import { SmartText } from "_components";
import { mapAnimation, lerp } from "_utils";
import { onScroll, onView, onResize } from "_mixins";
import { config } from "_core";

@Component({
  name: "PageTitle",
  components: { SmartText },
})
export default class PageTitle extends Mixins(onScroll, onView, onResize) {
  @Prop({ required: true, type: String }) title: string;
  @Ref() titleRef: SmartText;
  @Ref() wrapperRef: HTMLElement;

  rootClass = "c-page-title";

  hasAnimation = false;
  measurements = { start: 0, end: 0, amounts: [] };
  scrollCache = [];

  handleSplit() {
    this.calc();
  }

  onScroll(scroll: number) {
    if (this.hasAnimation && this.isInView) {
      const targets = this.titleRef.$el
        ? Array.from(this.titleRef.$el.children)
        : [];
      const { start, end, amounts } = this.measurements;
      if (scroll >= start && scroll <= end) {
        targets.forEach((element: HTMLElement, index) => {
          const cached = this.scrollCache[index] ? this.scrollCache[index] : 0;
          const lerped = lerp(
            cached,
            mapAnimation([start, end], [amounts[index], 0], scroll)
          );
          element.style.transform = `translateX(${lerped}px) translateZ(0)`;
          this.scrollCache[index] = lerped;
        });
      }
    }
  }

  calc() {
    const max =
      window.innerWidth < config.mobBreakpoint
        ? window.innerWidth / 15
        : window.innerWidth / 12;
    const targets = this.titleRef.$el
      ? Array.from(this.titleRef.$el.children)
      : [];
    let smallestFontSize = Infinity,
      smallestLineHeight = Infinity;
    let isAnyLineTooWide = false;

    this.hasAnimation = targets.length > 1;

    if (this.hasAnimation) {
      this.measurements = {
        start: 0,
        end: this.wrapperRef.offsetHeight * 2,
        amounts: targets.map((element: HTMLElement, index) => {
          let baseTransform = mapAnimation(
            [0, targets.length - 1],
            [max, 0],
            index
          );
          const lineWidth =
            Array.from(element.getElementsByClassName("word")).reduce(
              (sum, wordElement) => {
                let wordStyle = window.getComputedStyle(wordElement, null);
                return (
                  sum +
                  wordElement.getBoundingClientRect().width +
                  [
                    "padding-left",
                    "padding-right",
                    "margin-left",
                    "margin-right",
                  ].reduce(
                    (total, prop) =>
                      total + parseFloat(wordStyle.getPropertyValue(prop)),
                    0
                  )
                );
              },
              0
            ) + baseTransform; // add baseTransform to lineWidth

          const widthTrigger = window.innerWidth - 16; // 16px earlier
          const fontSizeReductionFactor = 0.9; // 10% more reduction
          if (lineWidth > widthTrigger) {
            baseTransform = 0; // Reset transform if lineWidth is too wide
            isAnyLineTooWide = true;
            Array.from(element.getElementsByClassName("word")).forEach(
              (wordElement: HTMLElement) => {
                let currentFontSize = parseFloat(
                  window
                    .getComputedStyle(wordElement, null)
                    .getPropertyValue("font-size")
                );
                let newSize =
                  currentFontSize *
                  (widthTrigger / lineWidth) *
                  fontSizeReductionFactor;
                smallestFontSize = Math.min(smallestFontSize, newSize);
                wordElement.style.fontSize = `${newSize}px`;

                let currentLineHeight = parseFloat(
                  window
                    .getComputedStyle(wordElement, null)
                    .getPropertyValue("line-height")
                );
                let newLineHeight = isNaN(currentLineHeight)
                  ? newSize * 1.2
                  : currentLineHeight * (widthTrigger / lineWidth);
                smallestLineHeight = Math.min(
                  smallestLineHeight,
                  newLineHeight
                );
                wordElement.style.lineHeight = `${newLineHeight}px`;
              }
            );
          }
          return baseTransform;
        }),
      };

      if (isAnyLineTooWide) {
        this.hasAnimation = false;
        targets.forEach((element: HTMLElement) => {
          element.style.transform = "none";
        });
      }

      if (isFinite(smallestFontSize) && isFinite(smallestLineHeight)) {
        Array.from(this.titleRef.$el.getElementsByClassName("word")).forEach(
          (wordElement: HTMLElement) => {
            wordElement.style.fontSize = `${smallestFontSize}px`;
            wordElement.style.lineHeight = `${smallestLineHeight}px`;
          }
        );
      }
      this.set();
    }
    this.$emit("ready");
  }

  set() {
    const targets = this.titleRef.$el
      ? Array.from(this.titleRef.$el.children)
      : [];
    const { start, end, amounts } = this.measurements;
    if (targets && this.hasAnimation) {
      targets.forEach((element: HTMLElement, index) => {
        const amount = mapAnimation([start, end], [amounts[index], 0], 0);
        element.style.transform = `translateX(${amount}px) translateZ(0)`;
        this.scrollCache[index] = amount;
      });
    }
  }

  onResize() {
    this.calc();
  }
}
