
import { Vue, Component, Prop } from "vue-property-decorator";
import { SmartImage, SmartImageProps, SmartText } from "_components";
import { generateID } from "_utils";
import { DCTextImageBlock } from "./types";
@Component({
  name: "DynamicContentImageGridModule",
  components: {
    SmartText,
    SmartImage,
  },
})
export default class DynamicContentImageGridModule extends Vue {
  @Prop() data: DCTextImageBlock;
  rootClass = "c-dc-text-image-grid";

  get label() {
    return this.data.label;
  }

  get ID() {
    return generateID();
  }

  get images(): SmartImageProps[] {
    return this.data.gallery && this.data.gallery.length
      ? this.data.gallery.map((image) => ({
          image,
          fit: "contain",
          lazyload: false,
        }))
      : [];
  }
}
