
import { Vue, Component } from "vue-property-decorator";
import { SmartSVG, SVGLibraryCustom } from "_components";
@Component({
  name: "GalleryNextPrevButtons",
  components: { SmartSVG },
})
export default class GalleryNextPrevButtons extends Vue {
  rootClass = "c-gallery-buttons";
  SVGLibraryCustom = SVGLibraryCustom;

  next() {
    this.$emit("next");
  }

  prev() {
    this.$emit("prev");
  }
}
