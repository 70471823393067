
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { SmartText, SmartSVG, SVGLibraryCustom } from "_components";
import { createModifierClass } from "_utils";

@Component({
  name: "TextInput",
  components: { SmartText, SmartSVG },
})
export default class TextInput extends Vue {
  @Prop({ type: String, default: "" }) placeholder: string;
  @Prop({ default: "Send input data", type: String }) ariaLabel: string;
  @Prop({ default: false, type: Boolean }) isAlt: boolean;
  @Prop({ type: String, default: null }) value: string;
  @Prop({ type: Boolean, default: false }) hideButton: boolean;
  @Prop({ type: String, default: '' }) type: string;

  rootClass = "c-input-text";
  SVGLibraryCustom = SVGLibraryCustom;

  innerValue = "";

  @Watch('innerValue')
  onInnerValueChanged(val: string, oldVal: string) {
    this.$emit('input', val);
  }

  get classes() {
    return [
      this.rootClass,
      ...createModifierClass(this.rootClass, this.isAlt, "is-alt"),
    ];
  }

  mounted() {
    if(this.value) this.innerValue = this.value;
  }

  click() {
    this.$emit("click", this.innerValue);
  }
}
