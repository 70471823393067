// Add routes here - they are automatically handled by the boilerplate

import { RouterPageNames } from "_types";

const Home = () =>
  import(/* webpackChunkName: "__HOME_PAGE__" */ "./views/Home/index.vue");

const DynamicPage = () =>
  import(
    /* webpackChunkName: "__DYNAMIC_PAGE__" */ "./views/DynamicPage/index.vue"
  );

const AllArticles = () =>
  import(
    /* webpackChunkName: "__DYNAMIC_PAGE__" */ "./views/AllArticles/index.vue"
  );

const PageNotFound = () =>
  import(/* webpackChunkName: "__404__" */ "./views/404/index.vue");

// const BoilerplateTester = () =>
//   import(
//     /* webpackChunkName: "__BOILERPLATE_TESTER_DEV__" */ "./views/BoilerplateTester/index.vue"
//   );

const SearchResult = () =>
  import(/* webpackChunkName: "__SEARCH__" */ "./views/SearchResult/index.vue");

export enum PROJECT_ROUTES {
  SEARCH = "search",
  ALL_ARTICLES = 'allArticles'
}

export const Routes = [
  {
    path: "/",
    name: RouterPageNames.HOME,
    component: Home,
  },
  {
    path: "/posts/",
    name: PROJECT_ROUTES.ALL_ARTICLES,
    component: AllArticles,
  },
  {
    path: "/search",
    name: PROJECT_ROUTES.SEARCH,
    component: SearchResult
  },
  {
    path: "/404",
    name: RouterPageNames.FOUR_O_FOUR,
    component: PageNotFound,
  },
  {
    path: "/:slug",
    name: RouterPageNames.PAGE,
    component: DynamicPage,
  },
  {
    path: "/**/:slug",
    name: RouterPageNames.PAGE_OR_POST,
    component: DynamicPage,
  },
  {
    path: "*",
    redirect: {
      name: RouterPageNames.FOUR_O_FOUR,
    },
  },
];
