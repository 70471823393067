
import { Vue, Component, Prop } from "vue-property-decorator";
import { createModifierClass, getRouterLink } from "_utils";
import { PQCardType, PQ_CARD_ASPECT_RATIO } from "_types";
import ParallaxGallery from "../ParallaxGallery/index.vue";
import Meta from "./Meta.vue";

@Component({
  name: "PostGridCard",
  components: { ParallaxGallery, Meta },
})
export default class PostGridCard extends Vue {
  @Prop() card: PQCardType;
  @Prop({ default: false, type: Boolean }) isWide: boolean;

  rootClass = "c-post-grid-card";

  get images() {
    return this.card && this.card.card.card_images;
  }

  get url() {
    return this.card ? getRouterLink(this.card.permalink) : "";
  }

  get generateImageClasses() {
    const root = `${this.rootClass}__img`;
    const ratio = this.card && this.card.card.aspect_ratio;
    return (
      this.images && [
        root,
        createModifierClass(
          root,
          ratio === PQ_CARD_ASPECT_RATIO.SQUARE,
          "square"
        ),
        createModifierClass(root, ratio === PQ_CARD_ASPECT_RATIO.WIDE, "wide"),
        createModifierClass(root, ratio === PQ_CARD_ASPECT_RATIO.TALL, "tall"),
      ]
    );
  }
}
