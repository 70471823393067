
import { Mixins, Component, Prop, Ref } from "vue-property-decorator";
import { SmartImage, SmartText, SmartSVG, SmartImageProps } from "_components";
import { onScroll, onView, onResize } from "_mixins";
import { mapAnimation, generateID, lerp, createModifierClass } from "_utils";
import { PQCardType, ThemeTranslations } from "_types";
import DataListRow from "./DataListRow.vue";

@Component({
  name: "DataList",
  components: { SmartImage, SmartText, SmartSVG, DataListRow },
})
export default class DataList extends Mixins(onScroll, onView, onResize) {
  @Prop({ type: Array, default: () => [] }) list: PQCardType[];
  @Prop() translations: ThemeTranslations;
  @Prop({ default: false, type: Boolean }) isSearch: boolean;
  @Ref() wrapperRef: HTMLElement;
  @Ref() contentRef: HTMLElement;
  @Ref() imageRef: HTMLElement;

  rootClass = "c-data-list";
  active = 0;
  currentItem: PQCardType = null;
  measurements = { start: 0, end: 0, amounts: [0, 0] };
  contentCache = 0;
  imageCache = 0;
  pageTop = 0;

  get uniqueID() {
    return generateID();
  }

  get images(): SmartImageProps[] {
    return this.list.map(
      (card) =>
        card.card &&
        card.card.card_images && {
          image: card.card.card_images[0],
          lazyload: true,
          fit: "contain",
        }
    );
  }

  get spacerStyle() {
    return (
      this.maxHeight && {
        paddingBottom: `${this.maxHeight}%`,
      }
    );
  }

  get maxHeight() {
    return (
      Math.max(
        ...this.list.map(
          (card) =>
            card.card &&
            card.card.card_images &&
            card.card.card_images[0].height / card.card.card_images[0].width
        )
      ) * 100
    );
  }

  get generateClass() {
    return [
      this.rootClass,
      ...createModifierClass(this.rootClass, this.isSearch, "is-search"),
    ];
  }

  onScroll(scroll: number) {
    const { start, end, amounts } = this.measurements;

    if (this.isInView) {
      if (scroll < start) this.transform(0, 0);
      else if (scroll >= start && scroll <= end) {
        this.transform(
          mapAnimation([start, end], [0, amounts[0]], scroll),
          mapAnimation([start, end], [0, amounts[1]], scroll)
        );
      } else if (scroll > end) this.transform(amounts[0], amounts[1]);
    }
  }

  transform(targetContent: number, targetImage: number) {
    if (isNaN(targetContent) || isNaN(targetImage)) return;
    const textLerp = lerp(this.contentCache, targetContent);
    const imageLerp = lerp(this.imageCache, targetImage);
    this.contentRef.style.transform = `translateY(${textLerp}px) translateZ(0)`;
    this.imageRef.style.transform = `translateY(${imageLerp}px) translateZ(0)`;
    this.contentCache = textLerp;
    this.imageCache = imageLerp;
  }

  public calc() {
    const { wrapperRef, contentRef, imageRef } = this;
    const top = wrapperRef.offsetTop;

    if (top) {
      this.pageTop = top;
      const margin = window
        ? parseInt(window.getComputedStyle(wrapperRef)["padding-left"])
        : 0;
      const start = top - 75 - margin;
      const end = top + wrapperRef.offsetHeight - window.innerHeight + margin;

      const moveContent = imageRef.offsetHeight > contentRef.offsetHeight;
      const fullAmount = moveContent
        ? wrapperRef.offsetHeight - contentRef.offsetHeight
        : wrapperRef.offsetHeight - imageRef.offsetHeight;
      const threshold = end - start;
      const isShortAnim = fullAmount / 2 > threshold;

      this.measurements = {
        start,
        end: isShortAnim ? start + fullAmount : end,
        amounts: moveContent ? [fullAmount, 0] : [0, fullAmount],
      };
    }
  }

  onResize() {
    this.$nextTick(() => this.calc());
  }

  onView(inView: boolean) {
    if (inView && this.wrapperRef.offsetTop !== this.pageTop) this.calc();
  }

  imageLoaded() {
    this.onResize();
  }

  changeCurrentItem(item: PQCardType, index: number) {
    this.active = index;
    this.currentItem = item;
    this.calc();
  }
}
