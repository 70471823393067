import { config } from "_core";

if (!process.env.HMR) {
  // @ts-ignore
  __webpack_public_path__ = `${config.themePath}/dist/`
}

import Vue from "vue";
import Vuex from "vuex";
import VueMeta from "vue-meta";
import { router } from "./router";
import store from "./store/";
import { initApp } from "./init/";
import App from "@app/App.vue";

new initApp(Vue);

Vue.config.devtools = false;
Vue.config.productionTip = false;
Vue.use(Vuex);
Vue.use(VueMeta);

new Vue({
  el: "#app",
  store,
  router,
  render: (h) => h(App),
});
