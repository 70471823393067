import {
  SeoSettings,
  SeoFromWPPage,
  SocialMediaSeo,
  DynamicPageProps,
  MetaInfoType,
  MetaInfoScriptType,
  MetaInfo,
} from "_types";
import { config, Cookies } from "_core";
import { isEmptyObj } from "_utils";
import {
  defaults,
  MetaNames,
  MetaProperties,
  MetaTypes,
} from "../../types/seo";

export class Seo {
  // To be called initially and use as an app base SEO
  public static getAppMetaData(settings: SeoSettings): MetaInfo {
    if (!this.hasSettings(settings)) return undefined;
    const { title_template, favicon } = settings;
    return {
      ...(title_template && { titleTemplate: `%s ${title_template}` }),
      ...(favicon && { link: [{ rel: MetaTypes.icon, href: favicon.url, sizes: 'any' }, { rel: 'apple-touch-icon', href: favicon.url }, { rel: 'mask-icon', href: favicon.url }] }),
      noscript: [{ innerHTML: defaults.noscript }],
      meta: this.generateBaseMeta(settings),
      script: this.generateBaseScripts(settings),
    };
  }

  // Special case for home page - just removal of title template
  public static getHomePageMetaData(settings: SeoSettings): MetaInfo {
    if (!this.hasSettings(settings)) return undefined;
    const { title } = settings;
    return {
      ...(title && { title }),
      titleTemplate: null,
    };
  }

  public static getDynamicPageMetaData(data: DynamicPageProps): MetaInfo {
    if (!this.hasSettings(data)) return undefined;
    const seoData = data.acf && data.acf.seo;
    return seoData
      ? {
        title: seoData.title ? seoData.title : data.post_data.post_title,
        meta: this.generateMetaData(seoData),
      }
      : undefined;
  }

  // Static base meta getters

  private static generateBaseSocialMediaSeo(
    seoData: SocialMediaSeo
  ): MetaInfoType {
    const {
      title,
      description,
      image,
      image_alt: imageAlt,
      website_url: websiteURL,
      site_name: siteName,
    } = seoData;
    return [
      title && { property: MetaProperties.ogTitle, content: title },
      description && {
        property: MetaProperties.ogDescription,
        content: description,
      },
      image && { property: MetaProperties.ogImage, content: image.url },
      image && { name: MetaNames.twitterCard, content: "summary_large_image" },
      imageAlt && { name: MetaNames.twitterImageAlt, content: imageAlt },
      websiteURL && { property: MetaProperties.ogUrl, content: websiteURL },
      siteName && { property: MetaProperties.ogSiteName, content: siteName },
    ].filter((value) => Boolean(value));
  }

  private static generateBaseMeta(settings: SeoSettings): MetaInfoType {
    const {
      social_media_seo: socialMediaSEO,
      description,
      author,
      keywords,
      other_settings: otherSettings,
    } = settings;
    const { themeColor } = defaults;
    return [
      ...this.generateBaseSocialMediaSeo(socialMediaSEO),
      description && {
        vmid: MetaTypes.description,
        name: MetaTypes.description,
        content: description,
      },
      author && {
        vmid: MetaTypes.author,
        name: MetaTypes.author,
        content: author,
      },
      keywords && {
        vmid: MetaTypes.keywords,
        name: MetaTypes.keywords,
        content: keywords,
      },
      config.enableStaticTheme && {
        name: MetaTypes.themeColor,
        content: otherSettings.theme_color
          ? otherSettings.theme_color
          : themeColor,
      },
      {
        name: MetaTypes.viewport,
        content: "width=device-width, initial-scale=1.0"
      }
    ].filter((value) => Boolean(value));
  }

  private static generateBaseScripts(
    settings: SeoSettings
  ): MetaInfoScriptType {
    const { google_analytics: googleAnalytics, meta_pixel: metaPixel, google_tag_manager: googleTagManager } = settings;
    const gtmEnabled = googleTagManager.enable && Cookies.isCookiesEnabled;
    const analyticsEnabled = googleAnalytics.enable && Cookies.isCookiesEnabled;
    const metaPixelEnabled = metaPixel.enable && Cookies.isCookiesEnabled;
    return [
      analyticsEnabled && {
        src: `https://www.googletagmanager.com/gtag/js?id=${googleAnalytics.g_tag} `,
        async: true,
      },
      analyticsEnabled && {
        innerHTML: `window.dataLayer = window.dataLayer || []; function gtag() { dataLayer.push(arguments); } gtag('js', new Date()); gtag('config', '${googleAnalytics.g_tag}');`,
      },
      gtmEnabled && {
        innerHTML: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${googleTagManager.container_id}');`,
      },
      metaPixelEnabled && {
        innerHTML: `!function(f,b,e,v,n,t,s) {if(f.fbq)return;n=f.fbq=function(){n.callMethod? n.callMethod.apply(n,arguments):n.queue.push(arguments)}; if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0'; n.queue=[];t=b.createElement(e);t.async=!0; t.src=v;s=b.getElementsByTagName(e)[0]; s.parentNode.insertBefore(t,s)}(window, document,'script', 'https://connect.facebook.net/en_US/fbevents.js'); fbq('init', '${metaPixel.pixel_id}'); fbq('track', 'PageView');`
      }
    ].filter((value) => Boolean(value));
  }

  // Static dynamic meta getters
  private static generateMetaData(seoData: SeoFromWPPage): MetaInfoType {
    const { keywords, description } = seoData;
    const { title, description: seoDescription, image } = seoData.social_media;
    const imageAlt = image ? image.alt : null;
    return [
      keywords && {
        vmid: MetaTypes.keywords,
        name: MetaTypes.keywords,
        content: keywords,
      },
      description && {
        vmid: MetaTypes.description,
        name: MetaTypes.description,
        content: description,
      },
      title && { property: MetaProperties.ogTitle, content: title },
      seoDescription && {
        property: MetaProperties.ogDescription,
        content: seoDescription,
      },
      image && { property: MetaProperties.ogImage, content: image.url },
      image && { name: MetaNames.twitterCard, content: "summary_large_image" },
      imageAlt && { name: MetaNames.twitterImageAlt, content: imageAlt },
    ].filter((value) => Boolean(value));
  }

  // Helpers

  private static hasSettings(settings: object): boolean {
    return Boolean(settings) && !isEmptyObj(settings);
  }
}
