import { Payload } from "./types";

export enum MUTATIONS {
  STORE_DATA = "storeData",
}

export default {
  [MUTATIONS.STORE_DATA]: (state, payload: Payload) => {
    state[payload.prefixedQuery] = payload.data;
  },
};
