
import { ThemeTranslations } from "@app/types";
import { Vue, Component, Prop } from "vue-property-decorator";
import { SmartText, SmartSVG, SVGLibraryCustom } from "_components";
@Component({
  name: "HeaderSearchInput",
  components: { SmartText, SmartSVG },
})
export default class HeaderSearchInput extends Vue {
  @Prop() value;
  @Prop() translations: ThemeTranslations;

  rootClass = "c-header";

  SVGLibraryCustom = SVGLibraryCustom;

  get hasQuery() {
    return this.value.length > 3;
  }

  get searchTextKey() {
    return this.translations && this.translations.search.search;
  }

  handleClick() {
    if (this.hasQuery) this.$emit("clearSearch");
  }
}
