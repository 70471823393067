import ProjectConfig from "@app/project-config";
import Router, { Route } from "vue-router";

export class PasswordProtectorClass {
  private isEnabled: boolean;

  constructor() {
    this.isEnabled =
    ProjectConfig.enablePasswordProtection;
  }

  public setupPasswordProtection(router: Router) {
    router.beforeEach((to: Route, from: Route, next): void => {
      if (this.isEnabled) {
        if (!localStorage.getItem('sbh-password') && to.name !== 'site-guard') {
          next({ name: 'site-guard' })
        }
        else next()
      } else next()
    })
  }
}

export const PasswordProtector = new PasswordProtectorClass();