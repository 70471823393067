
import { Mixins, Component, Prop, Ref } from "vue-property-decorator";
import { LottiePlayer } from "_components";
import { onResize } from "_mixins";
import Loading from "./Loading.vue";
import PQHover from "./pq-hover.json";

import { EventBus, EventBusChannels } from "_core";

@Component({
  name: "HeaderLogo",
  components: { LottiePlayer, Loading },
})
export default class HeaderLogo extends Mixins(onResize) {
  @Prop() hideLogo: boolean;
  @Ref() hoverAnim: LottiePlayer;
  @Ref() loadAnim: Loading;

  PQHover = PQHover;

  rootClass = "c-header-logo";
  loading = false;
  hovering = false;
  hoverDirection = 1;

  handleHover(isHovering: boolean) {
    if (this.isMob) return;
    if (this.loading || isHovering === this.hovering) return;
    if (this.$route.path === "/") {
      this.hovering = false;
      this.hoverDirection = -1;
    } else {
      this.hovering = isHovering;
      this.hoverDirection = isHovering ? 1 : -1;
    }
    this.hoverAnim.play();
  }

  initLoader() {
    EventBus.$once(EventBusChannels.ChangingPage, () => {
      this.loading = true;
      this.loadAnim.play();
      // console.log("change page");
      EventBus.$once(EventBusChannels.PageLoaded, () => {
        this.loading = false;
        this.initLoader();
        // console.log("load page");
      });
    });
  }
}
