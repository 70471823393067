
import { Vue, Component, Prop } from "vue-property-decorator";
import {
  SmartSVG,
  SVGLibraryBrand,
  SVGLibraryCustom,
  WYSIWYG,
  SmartText,
} from "_components";
import { LinkButton } from "../components";
import {
  PQHeaderMenuContact,
  PQ_HEADER_SOCIAL_MEDIA_TYPE,
  ThemeLink,
  THEME_LINK_TYPE,
} from "_types";
import { generateID } from "_utils";

@Component({
  name: "MenuContact",
  components: {
    SmartSVG,
    WYSIWYG,
    LinkButton,
    SmartText,
  },
})
export default class MenuContact extends Vue {
  @Prop({ required: true }) contact: PQHeaderMenuContact;

  rootClass = "c-contact";

  get uniqueID() {
    return generateID();
  }

  get contactDetails() {
    return this.contact && this.contact.contact_details;
  }

  get text() {
    return this.contact?.text;
  }

  get email(): ThemeLink {
    return (
      this.contact.email && {
        label: this.contact.email,
        type: THEME_LINK_TYPE.EXTERNAL_URL,
        external_url: `mailto:${this.contact.email}`,
      }
    );
  }

  get socials() {
    return this.contact.social_media ? this.contact.social_media : [];
  }

  getIcon(icon: PQ_HEADER_SOCIAL_MEDIA_TYPE) {
    switch (icon) {
      case PQ_HEADER_SOCIAL_MEDIA_TYPE.Twitter:
        return SVGLibraryCustom.PQTwitter;
      case PQ_HEADER_SOCIAL_MEDIA_TYPE.Facebook:
        return SVGLibraryCustom.PQFacebook;
      case PQ_HEADER_SOCIAL_MEDIA_TYPE.Instagram:
        return SVGLibraryCustom.PQInstagram;
      case PQ_HEADER_SOCIAL_MEDIA_TYPE.Youtube:
        return SVGLibraryCustom.PQYoutube;
      case PQ_HEADER_SOCIAL_MEDIA_TYPE.Flickr:
        return SVGLibraryCustom.PQFlickr;
    }
  }
}
