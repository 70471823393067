export enum SVGLibraryBrand {
  FiveHundredPx = "500px",
  Adobe = "adobe",
  Airbnb = "airbnb",
  AmazonOriginal = "amazon-original",
  AmazonPay = "amazon-pay",
  Amazon = "amazon",
  Amex = "amex",
  AndroidOriginal = "android-original",
  Android = "android",
  Angellist = "angellist",
  Angular = "angular",
  AppStore = "app-store",
  AppleMusic = "apple-music",
  ApplePay = "apple-pay",
  Apple = "apple",
  Atlassian = "atlassian",
  Aws = "aws",
  Azure = "azure",
  BehanceOriginal = "behance-original",
  Behance = "behance",
  Bitbucket = "bitbucket",
  Bitcoin = "bitcoin",
  Blogger = "blogger",
  BluetoothOriginal = "bluetooth-original",
  Bootstrap = "bootstrap",
  Btc = "btc",
  Chrome = "chrome",
  Chromecast = "chromecast",
  Cloudflare = "cloudflare",
  Codepen = "codepen",
  Connectdevelop = "connectdevelop",
  Cpanel = "cpanel",
  CreativeCommons = "creative-commons",
  Css3 = "css3",
  Dev = "dev",
  Digitalocean = "digitalocean",
  DinersClub = "diners-club",
  Discord = "discord",
  Discover = "discover",
  Docker = "docker",
  Dribbble = "dribbble",
  DropboxOriginal = "dropbox-original",
  Dropbox = "dropbox",
  DrupalOriginal = "drupal-original",
  Drupal = "drupal",
  Edge = "edge",
  Envato = "envato",
  FacebookFilled = "facebook-filled",
  FacebookMessenger = "facebook-messenger",
  FacebookOriginal = "facebook-original",
  FacebookOval = "facebook-oval",
  Facebook = "facebook",
  Figma = "figma",
  FirefoxOriginal = "firefox-original",
  Firefox = "firefox",
  Flickr = "flickr",
  Gatsby = "gatsby",
  Git = "git",
  GithubOriginal = "github-original",
  Github = "github",
  Goodreads = "goodreads",
  GoogleDrive = "google-drive",
  GooglePay = "google-pay",
  GoogleWallet = "google-wallet",
  Google = "google",
  HackerNews = "hacker-news",
  Html5 = "html5",
  Imdb = "imdb",
  InstagramFilled = "instagram-filled",
  InstagramOriginal = "instagram-original",
  Instagram = "instagram",
  Java = "java",
  Javascript = "javascript",
  Jcb = "jcb",
  JoomlaOriginal = "joomla-original",
  Joomla = "joomla",
  Jsfiddle = "jsfiddle",
  Laravel = "laravel",
  Line = "line",
  LinkedinOriginal = "linkedin-original",
  Linkedin = "linkedin",
  Magento = "magento",
  Mailchimp = "mailchimp",
  Markdown = "markdown",
  Mastercard = "mastercard",
  Medium = "medium",
  MicrosoftEdge = "microsoft-edge",
  Microsoft = "microsoft",
  Netlify = "netlify",
  NodejsAlt = "nodejs-alt",
  Nodejs = "nodejs",
  Npm = "npm",
  Opera = "opera",
  Patreon = "patreon",
  PaypalOriginal = "paypal-original",
  Paypal = "paypal",
  Php = "php",
  Pinterest = "pinterest",
  PlayStore = "play-store",
  Playstation = "playstation",
  Producthunt = "producthunt",
  Python = "python",
  Quora = "quora",
  React = "react",
  Reddit = "reddit",
  Shopify = "shopify",
  Sketch = "sketch",
  Skype = "skype",
  SlackLine = "slack-line",
  Slack = "slack",
  Slideshare = "slideshare",
  Snapchat = "snapchat",
  SoundcloudOriginal = "soundcloud-original",
  Soundcloud = "soundcloud",
  SpotifyOriginal = "spotify-original",
  Spotify = "spotify",
  Squarespace = "squarespace",
  Stackoverflow = "stackoverflow",
  Steam = "steam",
  Stripe = "stripe",
  Stumbleupon = "stumbleupon",
  Swift = "swift",
  TelegramOriginal = "telegram-original",
  Telegram = "telegram",
  Tiktok = "tiktok",
  Trello = "trello",
  Tumblr = "tumblr",
  Twitch = "twitch",
  TwitterFilled = "twitter-filled",
  TwitterOriginal = "twitter-original",
  Twitter = "twitter",
  Ubuntu = "ubuntu",
  Unsplash = "unsplash",
  Vimeo = "vimeo",
  Visa = "visa",
  Vk = "vk",
  WeChat = "wechat",
  Whatsapp = "whatsapp",
  Windows = "windows",
  WordpressFilled = "wordpress-filled",
  Wordpress = "wordpress",
  Xbox = "xbox",
  Yahoo = "yahoo",
  Ycombinator = "ycombinator",
  Youtube = "youtube",
}
// }

export enum SVGLibraryDirection {
  AngleDoubleDown = "angle-double-down",
  AngleDoubleLeft = "angle-double-left",
  AngleDoubleRight = "angle-double-right",
  AngleDoubleUp = "angle-double-up",
  ArrowDownCircle = "arrow-down-circle",
  ArrowDown = "arrow-down",
  ArrowLeftCircle = "arrow-left-circle",
  ArrowLeft = "arrow-left",
  ArrowRightCircle = "arrow-right-circle",
  ArrowRight = "arrow-right",
  ArrowTopLeft = "arrow-top-left",
  ArrowTopRight = "arrow-top-right",
  ArrowUpCircle = "arrow-up-circle",
  ArrowUp = "arrow-up",
  ArrowsHorizontal = "arrows-horizontal",
  ArrowsVertical = "arrows-vertical",
  ChevronDownCircle = "chevron-down-circle",
  ChevronDown = "chevron-down",
  ChevronLeftCircle = "chevron-left-circle",
  ChevronLeft = "chevron-left",
  ChevronRightCircle = "chevron-right-circle",
  ChevronRight = "chevron-right",
  ChevronUpCircle = "chevron-up-circle",
  ChevronUp = "chevron-up",
  DirectionAlt = "direction-alt",
  DirectionLtr = "direction-ltr",
  DirectionRtl = "direction-rtl",
  Direction = "direction",
  Enter = "enter",
  ExitDown = "exit-down",
  ExitUp = "exit-up",
  Exit = "exit",
  PointerDown = "pointer-down",
  PointerLeft = "pointer-left",
  PointerRight = "pointer-right",
  PointerTop = "pointer-top",
  ShiftLeft = "shift-left",
  ShiftRight = "shift-right",
}

// export enum SVGLibraryInterface {
//   BoltAlt = "bolt-alt",
//   Bolt = "bolt",
//   CheckBox = "check-box",
//   CheckmarkCircle = "checkmark-circle",
//   Checkmark = "checkmark",
//   CircleMinus = "circle-minus",
//   CirclePlus = "circle-plus",
//   Close = "close",
//   CrossCircle = "cross-circle",
//   Eye = "eye",
//   FlagAlt = "flag-alt",
//   Flag = "flag",
//   Funnel = "funnel",
//   Hand = "hand",
//   HeartFilled = "heart-filled",
//   Heart = "heart",
//   Help = "help",
//   Infinite = "infinite",
//   Information = "information",
//   Invention = "invention",
//   Key = "key",
//   Lifering = "lifering",
//   List = "list",
//   LockAlt = "lock-alt",
//   Lock = "lock",
//   Minus = "minus",
//   MoreAlt = "more-alt",
//   More = "more",
//   Music = "music",
//   Plus = "plus",
//   Pointer = "pointer",
//   PowerSwitch = "power-switch",
//   Protection = "protection",
//   QuestionCircle = "question-circle",
//   RadioButton = "radio-button",
//   Shield = "shield",
//   Teabag = "teabag",
//   ThumbsDown = "thumbs-down",
//   ThumbsUp = "thumbs-up",
//   TicketAlt = "ticket-alt",
//   Ticket = "ticket",
//   Unlock = "unlock",
//   Warning = "warning",
// }

// export enum SVGLibraryMedia {
//   Backward = "backward",
//   Forward = "forward",
//   FrameExpand = "frame-expand",
//   FullScreen = "full-screen",
//   Gallery = "gallery",
//   Pause = "pause",
//   Play = "play",
//   Shuffle = "shuffle",
//   Stop = "stop",
//   Video = "video",
//   VolumeHigh = "volume-high",
//   VolumeLow = "volume-low",
//   VolumeMedium = "volume-medium",
//   VolumeMute = "volume-mute",
//   Volume = "volume",
// }

export enum SVGLibraryTechnology {
  // AddFiles = "add-files",
  // AlarmClock = "alarm-clock",
  // Alarm = "alarm",
  // Archive = "archive",
  // Ban = "ban",
  // Bluetooth = "bluetooth",
  // BookmarkAlt = "bookmark-alt",
  // Bookmark = "bookmark",
  // Bulb = "bulb",
  // Calendar = "calendar",
  // Camera = "camera",
  // CloudCheck = "cloud-check",
  // CloudDownload = "cloud-download",
  // CloudNetwork = "cloud-network",
  // CloudSync = "cloud-sync",
  // CloudUpload = "cloud-upload",
  // CodeAlt = "code-alt",
  // Code = "code",
  // Cog = "cog",
  // Cogs = "cogs",
  // ControlPanel = "control-panel",
  // Cut = "cut",
  // Dashboard = "dashboard",
  // Database = "database",
  // DisplayAlt = "display-alt",
  // Display = "display",
  // Domain = "domain",
  // Download = "download",
  // EmptyFile = "empty-file",
  Files = "files",
  // Folder = "folder",
  // Game = "game",
  // Harddrive = "harddrive",
  // HeadphoneAlt = "headphone-alt",
  // Headphone = "headphone",
  // Image = "image",
  // Keyboard = "keyboard",
  // LaptopPhone = "laptop-phone",
  // Laptop = "laptop",
  // Link = "link",
  // Magnet = "magnet",
  // Magnifier = "magnifier",
  // MapMarker = "map-marker",
  // Map = "map",
  // Menu = "menu",
  // Mic = "mic",
  // Microphone = "microphone",
  // Mobile = "mobile",
  // Mouse = "mouse",
  // Package = "package",
  // Plug = "plug",
  // Printer = "printer",
  // Reload = "reload",
  // RemoveFile = "remove-file",
  // Rocket = "rocket",
  // RssFeed = "rss-feed",
  // Save = "save",
  // ScrollDown = "scroll-down",
  // SearchAlt = "search-alt",
  // Search = "search",
  // ShareAlt1 = "share-alt-1",
  // ShareAlt = "share-alt",
  // Share = "share",
  // ShoppingBasket = "shopping-basket",
  // Shortcode = "shortcode",
  // Signal = "signal",
  // StarFilled = "star-filled",
  // Star = "star",
  // Tab = "tab",
  // Timer = "timer",
  // TrashCan = "trash-can",
  // Unlink = "unlink",
  // Upload = "upload",
  // User = "user",
  // Users = "users",
  // WebsiteAlt = "website-alt",
  // Website = "website",
  // Zip = "zip",
  // ZoomIn = "zoom-in",
  ZoomOut = "zoom-out",
}
