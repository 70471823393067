
import { Vue, Component, Prop, Ref } from "vue-property-decorator";
import { generatePageLink } from "_utils";
import { PQHeaderMenuBlock } from "_types";
import { SmartText, SmartSVG, SVGLibraryCustom } from "_components";
import LinkButton from "../../LinkButton/index.vue";
import { config } from "_core";

@Component({
  name: "HeaderMenuHandler",
  components: { SmartText, SmartSVG, LinkButton },
})
export default class HeaderMenuHandler extends Vue {
  @Prop() menu: PQHeaderMenuBlock;
  @Prop({ required: true, type: Number }) index: number;
  @Prop({ required: true, type: String }) uniqueID: string;

  @Ref() linkRef: LinkButton[];

  rootClass = "c-header-menu";
  SVGLibraryCustom = SVGLibraryCustom;

  generatePageLink(link) {
    return generatePageLink(link);
  }

  get hasColumns() {
    return this.menu.links.length > 5 && window.innerWidth > config.largeDesktopBreakpoint;
  }

  get linkRefs() {
    return this.linkRef.length ? this.linkRef : [];
  }
}
