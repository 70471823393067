
import { config } from "_core";
import { ImageCore } from "./src/core";
import { WPImage, SmartImageProps, ImgStyleSize } from "./src/types";
import { Component, Mixins, Prop, Ref } from "vue-property-decorator";
import { onView, onResize } from "_mixins";
import { createModifierClass } from "_utils";

const Errored = () =>
  import(/* webpackChunkName: "__SMART_IMAGE_ERROR__" */ "./src/Errored.vue");

const DEBUG = false;

@Component({
  name: "SmartImage",
  components: {
    Errored,
  },
})
export default class SmartImage extends Mixins(onView, onResize) {
  /**
   * Pass a SmartImageProps type {object} to create    */
  @Prop({ required: true, type: Object as () => SmartImageProps })
  settings: SmartImageProps;
  @Ref() wrapperRef: HTMLElement;
  @Ref() imageRef: HTMLImageElement;

  public imageCore: ImageCore = null;

  public src: string = null;
  private img: WPImage = null;
  private alt = "";
  mime = false;
  contain = false;
  cover = false;
  enableWebP = false;
  isError = false;
  debugPlaceholder = false;
  fullHeight = false;

  style: ImgStyleSize = {
    width: "",
    height: "",
  };

  // For emitted events =
  private initiallyLoaded = false;
  private initiallyViewed = false;
  private readyEventEmitted = false;

  // Internal
  private $imgObserver: IntersectionObserver;

  rootClass = "bc-image";

  get getClass() {
    return [
      ...createModifierClass(this.rootClass, this.contain, "contain"),
      ...createModifierClass(this.rootClass, this.cover, "cover"),
      ...createModifierClass(
        this.rootClass,
        this.debugPlaceholder,
        "debugPlaceholder"
      ),
    ];
  }

  beforeMount() {
    this.img = this.settings.image;
    this.debugPlaceholder = this.settings.debugPlaceholder;
    this.fullHeight = this.settings.fullHeight;
    if (config && config.smartImageConfig.enableWebP) this.enableWebP = true;
    this.imageCore = new ImageCore(this.settings, DEBUG);
    this.imageCore.setupMeta(this);
    this.contain = this.imageCore.isContain;
    this.cover = this.imageCore.isCover;
    this.alt = this.imageCore.alt;
  }
  mounted() {
    this.init();
    this.setSize();
    this.observe(true);

    if (!this.imageCore.isLazy) this.mountImage(false);
  }
  beforeDestroy() {
    this.observe(false);
  }

  onResize(val) {
    const needsRemount = this.init();
    this.setSize();
    if (needsRemount) {
      this.mountImage(true);
    }
  }

  onLoad(isRemounting: boolean) {
    if (!this.initiallyLoaded) this.initiallyLoaded = true;
    this.$emit("onLoad", isRemounting);
    this.checkOnAndEmitReady();
  }

  onView(val) {
    if (!this.initiallyViewed) this.initiallyViewed = true;
    this.$emit("onView");
    this.checkOnAndEmitReady();
  }

  private init(): boolean {
    return this.imageCore.init(this.wrapperRef.getBoundingClientRect());
  }

  private toggleVisibility(toggle: boolean): void {
    this.imageCore.isVisible = toggle;
  }

  private enterLazyView(): void {
    if (this.imageCore.isVisible) return;
    else if (this.imageCore.isLazy && !this.imageCore.imgDataDecoded) {
      this.mountImage(false);
    }
  }

  private observe(isOn: boolean): void {
    if (this.$imgObserver) {
      isOn
        ? this.$imgObserver.observe(this.$el)
        : this.$imgObserver.unobserve(this.$el);
    }
  }

  private setSize(): void {
    this.$nextTick(() => {
      this.style = this.imageCore.getCurrentSize();
    });
  }

  private mountImage(isRemounting: boolean): void {
    this.toggleVisibility(true);
    this.imageCore.isLoading = true;
    this.src = this.imageCore.getUrlOfCorrectSize();
    this.$nextTick(() => {
      if (this.imageRef)
        this.imageRef
          .decode()
          .then(() => {
            this.isError = false;
            this.imageCore.isLoading = false;
            this.imageCore.imgDataDecoded = true;
            this.onLoad(isRemounting);
          })
          .catch(() => {
            if (this.enableWebP) {
              this.enableWebP = false;
              this.mountImage(isRemounting);
            } else {
              this.isError = true;
            }
          });
    });
  }

  private checkOnAndEmitReady() {
    if (this.readyEventEmitted) return;
    if (this.initiallyLoaded && this.initiallyViewed) {
      this.readyEventEmitted = true;
      this.$emit("onReady");
    }
  }
}
