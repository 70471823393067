
import { Component, Mixins, Prop, Ref } from "vue-property-decorator";
import { Core, SmartTextCoreClass, FontClass } from "./src/core";
import { TextBalancer } from "./src/balancer";
import { EventBus, EventBusChannels, SplitType } from "_core";
import { createModifierClass } from "_utils";
import { onView, onResize } from "_mixins";
import { VNode } from "vue";

@Component
export default class SmartText extends Mixins(onView, onResize) {
  @Prop({ required: true }) type: string;

  @Prop({ default: false, type: Boolean }) balance: boolean;
  @Prop({ default: false, type: Boolean }) placeholder: boolean;
  @Prop({ default: false, type: Boolean }) splitLines: boolean;
  @Prop({ default: false, type: Boolean }) splitChars: boolean;

  // Text Modifiers
  @Prop({ default: false, type: Boolean }) noMargin: boolean;
  @Prop({ default: false, type: Boolean }) bold: boolean;
  @Prop({ default: false, type: Boolean }) italic: boolean;
  @Prop({ default: false, type: Boolean }) truncate: boolean;
  @Prop({ default: false, type: Boolean }) underline: boolean;
  @Prop({ default: false, type: Boolean }) inline: boolean;

  @Ref() textElement: HTMLElement;

  rootClass = "bc-text";

  public textData: string = "";
  private core: SmartTextCoreClass;
  private fontLoaded: boolean = false;
  private fontClass: FontClass = {
    name: "",
    tag: "",
    group: "",
    fontFamily: "",
  };

  private lineSplitInstance: SplitType = null;

  get getHTMLTag() {
    return this.fontClass.tag ? this.fontClass.tag : "p";
  }

  get getClassNames() {
    return [
      this.rootClass,
      `bc-text-${this.fontClass.group}--${this.fontClass.name}`,
      ...createModifierClass(this.rootClass, this.noMargin, "noMargin"),
      ...createModifierClass(this.rootClass, this.bold, "bold"),
      ...createModifierClass(this.rootClass, this.italic, "italic"),
      ...createModifierClass(this.rootClass, this.truncate, "truncate"),
      ...createModifierClass(this.rootClass, this.underline, "underline"),
      ...createModifierClass(this.rootClass, this.inline, "inline"),
      ...createModifierClass(this.rootClass, this.splitLines, "splitLines"),
    ];
  }

  get getStyle() {
    return this.fontClass.fontFamily
      ? { fontFamily: this.fontClass.fontFamily }
      : {};
  }

  get shouldSplit() {
    return this.splitChars || this.splitLines;
  }

  beforeMount() {
    this.core = Core;
    this.textData = this.getTextString();
    this.updateClass();
  }

  beforeUpdate() {
    const textData = this.getTextString();
    if (textData !== this.textData) {
      this.textData = textData;
      this.balanceElement();
    }
  }

  onResize() {
    this.balanceElement();
    this.split();
  }

  public onView(inView: boolean): void {
    this.$emit("onView", inView);
  }

  private getTextString(): string {
    let checkForContent = (hasContent, node: VNode): string =>
      hasContent || node.tag || (node.text && node.text.trim());
    return this.$slots.default
      ? this.$slots.default.reduce(checkForContent, false)
      : "";
  }

  private updateClass() {
    this.fontClass = Object.assign({}, this.core.getClass(this.type));
    if (this.fontClass.fontFamily) {
      this.core
        .hasFontLoaded(this.fontClass.fontFamily)
        .then(() => this.init(true));
    } else {
      this.init(false);
      EventBus.$once(EventBusChannels.FontsAttached, () => this.updateClass());
    }
  }

  private init(loadedFont = false) {
    this.fontLoaded = loadedFont;
    this.balanceElement();
    if (this.shouldSplit && !this.balance && loadedFont) this.split();
  }

  get isSafari(): boolean {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
  }

  private balanceElement() {
    if (this.balance && this.fontLoaded && this.textData !== "")
      this.$nextTick(() => {
        this.restoreSplit();
        if(!this.isSafari) TextBalancer.balance(this.textElement);
        this.split();
      });
  }

  private split() {
    if (this.shouldSplit) {
      this.lineSplitInstance = new SplitType(this.textElement, {
        types: this.splitLines ? "words" : "chars",
      });
      if (this.splitLines) this.splitIntoLines();
      this.$emit("split");
    }
  }

  private groupBy = (arr, property) => {
    return Object.values(
      arr.reduce((acc, cur) => {
        acc[cur[property]] = [...(acc[cur[property]] || []), cur];
        return acc;
      }, {})
    );
  };

  private splitIntoLines() {
    const temp = this.groupBy(
      Array.from(this.textElement.children).map((element: HTMLElement) => ({
        y: element.getBoundingClientRect().y,
        element,
      })),
      "y"
    );
    this.textElement.innerHTML = "";

    temp.forEach((line) => {
      const tempElement = document.createElement("div");
      tempElement.className = "line";
      (line as any).forEach((test2) => {
        tempElement.appendChild(test2.element);
        tempElement.innerHTML += " ";
      });
      this.textElement.appendChild(tempElement);
    });
  }

  private restoreSplit() {
    if (this.shouldSplit && this.lineSplitInstance) {
      this.lineSplitInstance.revert();
    }
  }
}
