import { GalleryCaptionSettings } from "_types";

export enum DCTImageGalleryOrientation {
  PRIMARY_IMAGE_ON_LEFT = 'primaryImageOnLeft',
  PRIMARY_IMAGE_ON_RIGHT = 'primaryImageOnRight',
}

export type DCImageGalleryOptions = {
  orientation: DCTImageGalleryOrientation;
  gallery_caption_settings: GalleryCaptionSettings;
}