
import { Vue, Component, Prop } from "vue-property-decorator";
import { generateID } from "_utils";
import { DCTextBlock } from "./types";
import { CTAButton } from "../../../components";

@Component({
  name: "DynamicContentButtonModule",
  components: { CTAButton },
})
export default class DynamicContentButtonModule extends Vue {
  @Prop() data: DCTextBlock;
  @Prop({ default: false, type: Boolean }) openInNewTab: boolean;
  rootClass = "c-dc-text";

  get uniqueID() {
    return generateID();
  }

  get primary() {
    const { buttons } = this.data;
    return (
      this.data &&
      buttons &&
      buttons.primary &&
      buttons.primary.enable_primary_link &&
      buttons.primary.link.link
    );
  }

  get secondary() {
    const { buttons } = this.data;
    return this.data && buttons && buttons.secondary.length
      ? buttons.secondary
      : [];
  }

  get color() {
    return this.data && this.data.options_group.colour_override
      ? this.data.options_group.colour_override
      : "";
  }
}
