
import { Vue, Component, Prop, Ref, Watch } from "vue-property-decorator";
import { LottiePlayer } from "_components";
import MenuBurgerAnim from "./menu-burger-anim.json";

@Component({
  name: "MenuButton",
  components: { LottiePlayer },
})
export default class MenuButton extends Vue {
  @Prop({ type: Boolean, default: false }) menuIsOpen: boolean;
  @Prop({ type: Boolean, default: false }) hide: boolean;
  @Ref() anim: LottiePlayer;

  rootClass = "c-header";

  MenuBurgerAnim = MenuBurgerAnim;

  direction = 1;

  @Watch("menuIsOpen")
  handleOpen() {
    this.direction = this.menuIsOpen ? 1 : -1;
    this.anim.play();
  }

  public handleClick() {
    this.$emit("click");
  }
}
