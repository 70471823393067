
import { DCTextBlock, DCTextOptions } from "./types";
import { Vue, Component, Prop } from "vue-property-decorator";
import { generateID } from "_utils";
import { SmartText, SmartSVG, SVGLibraryCustom, WYSIWYG } from "_components";
import { createModifierClass } from "_utils";

@Component({
  name: "DynamicContentAccordion",
  components: { SmartText, SmartSVG, WYSIWYG },
})

export default class DynamicContentAccordion extends Vue {

  @Prop() data: DCTextBlock;

  rootClass = "c-dc-text-accordion";
  SVGLibraryCustom = SVGLibraryCustom;
  tabVisibility = {};

  get classes() {
    return [
      this.rootClass,
      ...createModifierClass(this.rootClass, this.isRed, "red"),
    ];
  }

  get isRed() {
    const { data } = this;
    const readTarget =
      data && data.options ? data.options : data.options_group?.options;
    return readTarget && readTarget.includes(DCTextOptions.RED);
  }

  get ID() {
    return generateID();
  }

  get tabs() {
    return this.data && this.data.items?.length ? this.data.items : [];
  }

  toggleVisibility(index: number): void {
    if(!this.tabVisibility[index]) Vue.set(this.tabVisibility, index, true);
    else Vue.set(this.tabVisibility, index, false)

    setTimeout(() => {
      this.$parent!.$emit('resize')
    }, 150) // Timing of accordion toggling animation is 125ms so we wait for 150ms for this to finish.
  }
}
