import { Logger } from "./";
import { capitalizeFirstLetter } from "_utils";

import ExportedVariables from "../../../styles/_export.module.scss";

const DEBUG = false;
class SCSSVariablesClass {
  logger: Logger;
  variableTable: {
    name: string;
    group: string;
    value: { [key: string]: string };
  }[];

  constructor() {
    this.logger = new Logger("SCSSVariables", DEBUG);
  }

  get gridSettings() {
    return {
      desktopCols: ExportedVariables["themeSettings-columns"],
      mobileCols: ExportedVariables["themeSettings-mob-columns"],
    };
  }

  get themeColors() {
    if (!this.variableTable) this.createLibrary();
    return this.variableTable.find((block) => block.group === "themeColors")
      .value;
  }

  get allVariables() {
    return this.variableTable ? this.variableTable : this.createLibrary();
  }

  private createLibrary() {
    const obj = {};
    Object.entries(ExportedVariables).map(([name, value]) => {
      const [category, ...rest] = name.split("-");
      obj[category] = {
        ...obj[category],
        ...{ [`$${rest.join("-")}`]: value },
      };
    });
    this.variableTable = Object.entries(obj).map(([name, value]) => {
      return {
        name: capitalizeFirstLetter(name.replace(/([A-Z])/g, " $1").trim()),
        group: name,
        value: value as { [key: string]: string },
      };
    });
    return this.variableTable;
  }
}

export const SCSSVariables = new SCSSVariablesClass();
