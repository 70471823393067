import { config, EventBus, EventBusChannels, Logger } from "_core";
import { FontModes, FontSettings, FontSettingsDetail } from "_types";

// Dynamically import as sometimes it's not used
const WebFont = () =>
  import(/* webpackChunkName: "__WEB_FONT_LOADER__" */ "webfontloader");

const DEBUG = false;

export class FontLoader {
  settings: FontSettings;
  logger: Logger;
  fontsToDownload: string[];

  constructor() {
    this.logger = new Logger("Fonts", DEBUG);
  }

  private loadFonts() {
    if (this.fontsToDownload.length > 0) {
      WebFont()
        .then((webfont) => {
          webfont.load({
            classes: false,
            events: false,
            google: {
              families: this.fontsToDownload,
            },
          });
        })
        .finally(() => {
          this.logger.log("Loaded fonts:", this.fontsToDownload);
        });
    }
  }

  buildGoogleName(name: string, key: keyof FontSettings): string {
    return `${name}:${config.fontSettings.fontSizes},swap`;
  }

  private getCustomFonts() {
    const applyFont = (
      font: FontSettingsDetail,
      key: keyof FontSettings,
      isCustom: boolean
    ) => {
      if (isCustom)
        config.fontSettings.fontsToUse[key] = config.fontSettings.backup[key];
      else {
        const name: string = font.google_font.font;
        if (!this.fontsToDownload.indexOf(name)) return;
        this.fontsToDownload.push(this.buildGoogleName(name, key));
        config.fontSettings.fontsToUse[key] = name;
      }
    };

    if (this.settings) {
      Object.keys(this.settings).map((key: keyof FontSettings) => {
        const fontSettings = this.settings[key];
        switch (fontSettings.mode) {
          case FontModes.custom:
            applyFont(fontSettings, key, true);
            break;
          case FontModes.google:
            applyFont(fontSettings, key, false);
            break;
          case FontModes.disabled:
            applyFont(
              this.settings.body_font,
              key,
              this.settings.body_font.mode === FontModes.custom
            );
        }
      });
    } else {
      this.logger.warn("No font settings from API to load");
    }
  }

  private getDebugFont() {
    const debugName = "debug_font" as keyof FontSettings;
    const fontToUse = config.fontSettings.backup[debugName];
    config.fontSettings.fontsToUse[debugName] = fontToUse;
    return this.buildGoogleName(fontToUse, debugName);
  }

  public setupFonts(settings: FontSettings): void {
    this.settings = settings;
    this.fontsToDownload = [];

    if (config.debug) this.fontsToDownload.push(this.getDebugFont());
    this.getCustomFonts();

    config.fontSettings.fontsLoaded = true;
    this.loadFonts();
    EventBus.$emit(EventBusChannels.FontsInit);
  }
}
