
import { Vue, Component, Prop } from "vue-property-decorator";
import { Locale } from "_core";
import { SmartText, SmartSVG, SVGLibraryCustom } from "_components";
import { createModifierClass, generateID } from "_utils";

@Component({
  name: "HeaderLanguageSelector",
  components: { SmartText, SmartSVG },
})
export default class HeaderLanguageSelector extends Vue {
  @Prop({ default: false, type: Boolean }) invert: boolean;
  rootClass = "c-header-lang-selector";
  SVGLibraryCustom = SVGLibraryCustom;

  showLanguageList: boolean = false;

  get languages() {
    return Locale.availableLanguages ? Locale.availableLanguages : [];
  }

  get currentLanguage() {
    return Locale.currentLocale ? Locale.currentLocale : null;
  }

  get enableLangSelector() {
    return Locale.isEnabled;
  }

  get generateID() {
    return generateID();
  }

  get generateClass() {
    return [
      this.rootClass,
      ...createModifierClass(this.rootClass, this.invert, "invert"),
    ];
  }

  switchLocale(locale: string) {
    Locale.switchLocale(locale, this.$route);
  }
}
