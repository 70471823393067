
import { Vue, Component, Ref } from "vue-property-decorator";
import { LottiePlayer } from "_components";

import Load1 from "./load-1.json";
import Load2 from "./load-2.json";
import Load3 from "./load-3.json";
@Component({
  name: "PQLogoLoader",
  components: { LottiePlayer },
})
export default class PQLogoLoader extends Vue {
  @Ref() loadAnim: LottiePlayer | any;

  @Ref() loadAnim2: LottiePlayer;
  rootClass = "c-header-logo-loader";

  Load1 = Load1;
  Load2 = Load2;
  Load3 = Load3;

  animToShow = Load1;
  loading = false;
  _p1 = true;
  private _p2 = false;
  private _p3 = false;

  //   playSequence(player, list) {
  //     const playNext = () => {
  //       player.load(list[0]);
  //       list.push(list.shift());
  //     };
  //     player.addEventListener("complete", () => playNext());

  //     playNext();

  //     return () => {
  //       player.removeEventListener("complete");
  //     };
  //   }

  handleEndPhase() {
    const { _p1, _p2, _p3 } = this;
    console.log("hello", { _p1, _p2, _p3 });
    if (this._p1) {
      this.animToShow = Load2;
      this._p1 = false;
      this._p2 = true;
      this.playPlayer();

      //   this.loadAnim.load(Load2);
      //   this.loadAnim1.stop();
      //   this.loadAnim1.destroy();
      //   this._p1 = false;
      //   this._p2 = true;
      //   this.loadAnim2.play();
    } else if (this._p2) {
      this.animToShow = Load3;
      this._p2 = false;
      this._p3 = true;
      this.playPlayer();
    } else if (this._p3) {
      this.animToShow = Load1;
      this._p3 = false;
      this.loading = true;
      this.loadAnim.pause();
    }
    // // this.$nextTick(() => {
    // //   this.loadAnim1.init();
    // // });
    // console.log("test2");
  }

  public play() {
    this._p1 = true;
    this.loading = true;
    this.playPlayer();
  }

  private playPlayer() {
    this.$nextTick(() => {
      this.loadAnim.play();
    });
  }
}
