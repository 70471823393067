
import { Vue, Component, Prop } from "vue-property-decorator";
import { SmartText, SmartSVG, SVGLibraryCustom } from "_components";
import { displayPostType, getRouterLink, wordpressDateToString } from "_utils";
import { PQCardType, ThemeTranslations } from "_types";

@Component({
  name: "SearchResultCard",
  components: { SmartText, SmartSVG },
})
export default class SearchResultCard extends Vue {
  @Prop({ required: true }) card: PQCardType;
  @Prop() translations: ThemeTranslations;

  rootClass = "c-header-search__card";

  SVGLibraryCustom = SVGLibraryCustom;

  get title() {
    const { card, post_data } = this.card;
    return card && card.headline ? card.headline : post_data.post_title;
  }

  get postType() {
    return displayPostType(this.card, this.translations);
  }

  get date() {
    return wordpressDateToString(this.card.post_data.post_date);
  }

  get url() {
    return getRouterLink(this.card.permalink);
  }

  get description() {
    const excerpt = this.card.post_data.post_excerpt;
    return excerpt
      ? excerpt.length > 144
        ? `${excerpt.substring(0, 144)}...`
        : excerpt
      : null;
  }

  mouseEnter() {
    this.$emit("mouseenter");
  }
}
