import {
  WPImage,
  MeasureByOutput,
  ImgSize,
  SmartImageProps,
  ImgStyleSize,
} from "./types";
import { config } from "_core";
import { logger } from "_utils";
import { SmartImage } from "../..";

export class ImageCore {
  private debug: boolean;
  private image: WPImage;
  private imageRatio: number;

  public isCover: boolean;
  public isContain: boolean;
  public selectedImage: string;
  public displayedSize: ImgSize;
  public isLazy: boolean;
  public isVisible: boolean;
  public isLoading: boolean;
  public imgDataDecoded: boolean;
  public forceHeight: boolean;
  public alt: string;
  public enableFullRes: boolean;

  constructor(props: SmartImageProps, debug: boolean) {
    this.debug = debug;
    this.image = props.image;
    this.imageRatio = props.image.width / props.image.height;
    this.isLazy = props.lazyload === undefined ? true : props.lazyload;
    this.imgDataDecoded = false;
    this.isVisible = false;
    this.forceHeight = props.forceHeight;
    this.isCover = props.fit === "cover";
    this.isContain =
      props.fit === undefined || !props.fit || props.fit === "contain";
    this.alt = props.image.alt
      ? props.image.alt
      : props.backupAltTag
        ? props.backupAltTag
        : "Image";
    this.enableFullRes = props.enableFullRes;
  }

  public init(wrapperMeasurements: DOMRect): boolean {
    this.displayedSize =
      this.getSmallestSizePossibleInContainer(wrapperMeasurements);
    const selectedImage = this.findWPImageOfBestFit(this.displayedSize);
    if (!this.selectedImage) {
      this.selectedImage = selectedImage;
    } else {
      const indexOfNew =
        config.smartImageConfig.imageSizes.indexOf(selectedImage);
      const indexOfCached = config.smartImageConfig.imageSizes.indexOf(
        this.selectedImage
      );

      if (indexOfNew > indexOfCached) {
        this.debugLogger(
          `After resize, changing ${this.selectedImage} to ${selectedImage}`
        );
        this.selectedImage = selectedImage;
        return true;
      }
    }
    this.debugLogger("Init image");
    return false;
  }

  public setupMeta(instance: SmartImage) {
    if (instance && this.image) {
      instance.mime = this.image.mime_type;
      if (this.image.alt) {
        instance.alt = this.image.alt;
      } else {
        this.debugLogger('Please specify "alt" tag');
      }
    }
  }

  private debugLogger(...args): void {
    if (this.debug) {
      logger("Smart Image Core: ", args);
    }
  }

  public getUrlOfCorrectSize(): string | null {
    return this.image.sizes[this.selectedImage]
      ? this.image.sizes[this.selectedImage]
      : null;
  }

  private getSmallestSizePossibleInContainer(
    wrapperMeasurements: DOMRect
  ): ImgSize {
    const findSmallestImageRequiredWithoutShrinkingContain = (
      wrapperMeasurements: DOMRect
    ): ImgSize => {
      return {
        width: this.forceHeight
          ? wrapperMeasurements.height * this.imageRatio
          : wrapperMeasurements.width,
        height: this.forceHeight
          ? wrapperMeasurements.height
          : wrapperMeasurements.width / this.imageRatio,
        ratio: this.imageRatio,
      };
    };

    const findSmallestImageRequiredWithoutShrinkingCover = (
      wrapperMeasurements: DOMRect
    ): ImgSize => {
      const measureBy =
        wrapperMeasurements.width / this.image.width >
          wrapperMeasurements.height / this.image.height
          ? MeasureByOutput.width
          : MeasureByOutput.height;
      const smallestSizePossible: ImgSize =
        measureBy === MeasureByOutput.width
          ? {
            width: wrapperMeasurements.width,
            height: wrapperMeasurements.width / this.imageRatio,
            ratio: this.imageRatio,
          }
          : {
            width: wrapperMeasurements.height * this.imageRatio,
            height: wrapperMeasurements.height,
            ratio: this.imageRatio,
          };

      return smallestSizePossible;
    };
    if (this.isCover)
      return findSmallestImageRequiredWithoutShrinkingCover(
        wrapperMeasurements
      );
    else if (this.isContain)
      return findSmallestImageRequiredWithoutShrinkingContain(
        wrapperMeasurements
      );
  }

  // Returns the lowest res image to show without being smaller than the visible area
  public findWPImageOfBestFit(imgSize: ImgSize): string {
    const pixelRatio = window.devicePixelRatio ? window.devicePixelRatio : 1;
    const width = imgSize.width * pixelRatio;
    const height = imgSize.height * pixelRatio;
    let rightSize;
    config.smartImageConfig.imageSizes.some((size: string): boolean => {
      rightSize = size;
      return (
        this.image.sizes[`${size}-width`] > width &&
        this.image.sizes[`${size}-height`] > height
      );
    });
    return config.smartImageConfig.imageSizes[
      config.smartImageConfig.imageSizes.indexOf(rightSize)
    ];
  }

  public getCurrentSize = (): ImgStyleSize => {
    return {
      width: `${this.displayedSize.width}px`,
      height: `${this.displayedSize.height}px`,
    };
  };
}
