import { Vue, Component } from "vue-property-decorator";

/**
 * Adds listener for viewport entering/exiting.
 * @function onView(inView: boolean): void
 * @static isInView: boolean
 */
@Component
export class onViewMixin extends Vue {
  private $observer: IntersectionObserver;
  private inView: boolean = false;

  /**
   * Call onView(inView: boolean) to know whether component is in viewport
   * */
  public onView(inView: boolean): void {}

  /**
   * Call static getter isInView: boolean to know whether component is in viewport
   * */
  public get isInView() {
    return this.inView;
  }

  private enterView(): void {
    if (this.inView) return;
    this.inView = true;
    this.onView(true);
  }
  private leaveView(): void {
    if (!this.inView) return;
    this.inView = false;
    this.onView(false);
  }

  private setupInView(on: boolean): void {
    if (this.$el.nodeType === 1 && this.$observer) {
      on
        ? this.$observer.observe(this.$el)
        : this.$observer.unobserve(this.$el);
    }
  }

  mounted() {
    this.setupInView(true);
  }
  beforeDestroy() {
    this.setupInView(false);
  }
}
