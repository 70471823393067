
import { Mixins, Component, Prop, Ref } from "vue-property-decorator";
import { SmartImage } from "_components";
import { WPImage } from "_types";
import { GSAP } from "_core";
import { generateID } from "_utils";
import { onView, onResize } from "_mixins";
import NextPrevButtons from "../Gallery/NextPrevButtons.vue";

const DURATION = 4;

@Component({
  name: "ParallaxGallery",
  components: { SmartImage, NextPrevButtons },
})
export default class ParallaxGallery extends Mixins(onView, onResize) {
  @Prop({ required: true, type: Array }) images: WPImage[];
  @Prop({ default: DURATION, type: Number }) duration: number;

  rootClass = "c-manual-parallax-gallery";

  @Ref() containerRef: HTMLElement;
  @Ref() imageWrapper: HTMLElement[];
  @Ref() imageRef: SmartImage[];

  private tl: gsap.core.Timeline;
  private hasAnimation = false;
  private currentIndex = 0;
  private isAnimating = false;

  get formattedImages() {
    const images = this.images.map((image) => ({
      image,
      fit: "cover",
    }));

    images.push(images.shift())

    // make slideshow start at first image
    return images;
  }

  mounted() {
    if (this.formattedImages.length > 1) this.setupAnimation();
  }

  onResize() {
    if (this.hasAnimation) {
      this.tl.kill();
      this.$nextTick(() => this.setupAnimation());
    }
  }

  onView(inView: boolean) {
    if (this.hasAnimation) {
      if (inView) this.tl.play();
      else this.tl.pause();
    }
  }

  private setupAnimation() {
    this.hasAnimation = true;
    const { imageRef } = this;

    this.tl = GSAP.timeline({ repeat: -1 });
    this.tl.set(imageRef[0].$el, { x: 0, zIndex: 0 });
  }

  private generateID() {
    return generateID();
  }

  next() {
    this.animate()
  }

  prev() {
    this.animate(false)
  }

  async animate(forward: boolean = true) {
    if (this.isAnimating) return;

    this.isAnimating = true;

    const width = this.containerRef.offsetWidth;
    const { imageWrapper: wrapper, imageRef, duration } = this;

    this.tl = GSAP.timeline({ paused: true });

    let indexToAnimate: number;
    let referencedIndex: number;

    if (forward) indexToAnimate = this.currentIndex;
    else {
      referencedIndex = this.currentIndex === 0 ? wrapper.length - 1 : this.currentIndex - 1
      indexToAnimate = referencedIndex === 0 ? wrapper.length - 1 : referencedIndex - 1;
    }

    if (forward) {
      wrapper.forEach((image, index) => {
        if (index !== (forward ? indexToAnimate - 1 : indexToAnimate + 1)) {
          this.tl.set(image, { zIndex: 0 });
        }
      });

      this.tl.set(wrapper[indexToAnimate], { x: width, zIndex: 1 });
      this.tl.to(wrapper[indexToAnimate], { x: 0, duration });
      this.tl.fromTo(
        imageRef[indexToAnimate].$el,
        { x: width * -1 },
        { x: 0, duration },
        "<"
      );
    } else {
      wrapper.forEach((image, index) => {
        if (index !== indexToAnimate) {
          if (index === referencedIndex) this.tl.set(image, { zIndex: 0 });
          else this.tl.set(image, { zIndex: -1 });
        }
      });

      this.tl.set(wrapper[indexToAnimate], { x: width * -1, zIndex: 1 });
      this.tl.to(wrapper[indexToAnimate], { x: 0, duration });
      this.tl.fromTo(
        imageRef[indexToAnimate].$el,
        { x: width },
        { x: 0, duration },
        "<"
      );
    }

    await this.tl.play();

    if (forward) {
      if (this.currentIndex === wrapper.length - 1) this.currentIndex = 0;
      else this.currentIndex = this.currentIndex + 1
    } else {
      if (this.currentIndex === 0) this.currentIndex = wrapper.length - 1;
      else this.currentIndex = this.currentIndex - 1
    }

    this.isAnimating = false;
  }
}
