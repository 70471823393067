
import { Vue, Component, Prop, Ref, Watch } from "vue-property-decorator";
import { generateID } from "_utils";
import {
  LottiePlayer,
  AnimationConfigWithData,
  AnimationDirection,
} from "lottie-web";
import lottie from "lottie-web/build/player/lottie_light.min.js";

@Component({
  name: "LottiePlayerHandler",
})
export default class LottiePlayerHandler extends Vue {
  @Prop({ required: true }) animationData;
  @Prop({ default: false }) loop;
  @Prop({ type: Boolean, default: false }) autoPlay: boolean;
  @Prop({ type: Number, default: 1 }) speed: AnimationDirection;
  @Prop({ type: Number, default: 1 }) direction: AnimationDirection;
  @Ref() animRef: Element;

  anim: LottiePlayer | any = null;
  rootClass = "bc-lottie-player";

  @Watch("speed")
  updateSpeed() {
    this.setSpeed(this.speed);
  }

  @Watch("direction")
  updateDirection() {
    this.setDirection(this.direction);
  }

  @Watch("animationData")
  reinit() {
    this.destroy();
    this.init();
  }

  get config(): AnimationConfigWithData {
    return {
      container: this.animRef,
      renderer: "svg",
      loop: this.loop,
      autoplay: this.autoPlay,
      animationData: this.animationData,
      name: generateID(),
    };
  }

  mounted() {
    this.init();
  }

  beforeDestroy() {
    this.destroy();
  }

  public init() {
    this.anim = lottie.loadAnimation(this.config);
    this.setSpeed(this.speed);
    this.setDirection(this.direction);
    this.initListeners();
  }

  private initListeners() {
    this.anim.addEventListener("loopComplete", () => {
      this.$emit("loopComplete", this.anim);
    });
    this.anim.addEventListener("complete", () => {
      this.$emit("onComplete", this.anim);
    });
  }

  private removeListeners() {
    this.anim.removeEventListener("loopComplete", () => {
      this.$emit("loopComplete", this.anim);
    });
    this.anim.removeEventListener("complete", () => {
      this.$emit("onComplete", this.anim);
    });
  }

  public play() {
    if (this.anim) this.anim.play();
  }
  public stop() {
    if (this.anim) this.anim.stop();
  }
  public pause() {
    if (this.anim) this.anim.pause();
  }

  public load(toLoad) {
    if (this.anim) this.anim.load(toLoad);
  }

  public destroy() {
    this.removeListeners();
    if (this.anim) this.anim.destroy();
  }

  public setSpeed(speed: number) {
    if (this.anim) this.anim.setSpeed(speed);
  }

  public setDirection(direction: AnimationDirection) {
    if (this.anim) this.anim.setDirection(direction);
  }
}
