
import { Component, Prop, Mixins, Watch } from "vue-property-decorator";
import { getSearch, onScroll, onResize, getCMSData } from "_mixins";
import { EventBus, EventBusChannels } from "_core";
import { SmartText, SmartSVG, SVGLibraryCustom, LinkButton } from "_components";
import {
  MainSettings,
  SearchResult,
  EMPTY_SEARCH,
  RouterPageNames,
} from "_types";
import { createModifierClass } from "_utils";

import Menu from "./Menu/index.vue";
import PageBlur from "./Menu/PageBlur.vue";
import SearchResults from "./Search/SearchResults.vue";
import LanguageSelector from "./Core/LanguageSelector.vue";
import SearchInput from "./Core/SearchInput.vue";
import Logo from "./Logo/index.vue";
import MenuButton from "./MenuButton/index.vue";

@Component({
  name: "Header",
  components: {
    SmartText,
    SmartSVG,
    Menu,
    SearchResults,
    LanguageSelector,
    SearchInput,
    PageBlur,
    LinkButton,
    Logo,
    MenuButton,
  },
})
export default class Header extends Mixins(
  getSearch,
  onScroll,
  onResize,
  getCMSData
) {
  @Prop({ required: true }) settings: MainSettings;

  query: string = "";
  results = EMPTY_SEARCH;
  rootClass = "c-header";
  SVGLibraryCustom = SVGLibraryCustom;
  isFixed = false;
  menuIsOpen = false;
  resultsIsOpen = false;
  showLogo = true;
  CMSData: any;
  headerOverride: boolean = false;
  isOpenOverride: boolean = false;

  @Watch("query")
  runSearch() {
    if (this.query.length < 3) {
      this.results = EMPTY_SEARCH;
    } else {
      this.toggleResultsMenu(true);
      this.search(this.query);
    }
  }

  get classes() {
    const isFixed =
      this.isFixed ||
      this.menuIsOpen ||
      this.showResults ||
      this.isOpenOverride;
    const takeover = this.takeoverEnabled && !isFixed;
    const takeoverColour = this.settings.header.event_takeover.primary_colour;
    return [
      this.rootClass,
      createModifierClass(this.rootClass, isFixed, "is-fixed"),
      createModifierClass(
        this.rootClass,
        takeover,
        `takeover-${takeoverColour}`
      ),
    ];
  }

  get postData() {
    return (
      this.CMSData && this.CMSData.acf && this.CMSData.acf.__DYNAMIC_PAGE__
    );
  }

  get takeoverEnabled() {
    const { settings, $route } = this;
    if ($route.query.preview) return;

    return (
      (settings &&
        $route.name === RouterPageNames.HOME &&
        settings.header.event_takeover.enable) ||
      this.headerOverride
    );
  }

  onSearchLoad(data: SearchResult) {
    this.results = data;
  }

  onScroll(scroll: number) {
    this.isFixed = scroll > 0;
  }

  onResize() {
    this.closeAll();
  }

  toggleMenu() {
    this.menuIsOpen = !this.menuIsOpen;
    if (this.menuIsOpen && this.resultsIsOpen) this.resultsIsOpen = false;
  }

  toggleResultsMenu(isOn: boolean) {
    this.resultsIsOpen = isOn;
    if (this.menuIsOpen && this.resultsIsOpen) this.menuIsOpen = false;
  }

  clearSearch() {
    this.query = "";
    this.results = EMPTY_SEARCH;
    this.toggleResultsMenu(false);
  }

  handleFocus() {
    this.showLogo = false;
    if (this.query.length >= 3) this.toggleResultsMenu(true);
  }

  closeAll() {
    this.menuIsOpen = false;
    this.toggleResultsMenu(false);
  }

  get showResults() {
    return this.resultsIsOpen && this.query.length > 3;
  }

  get headerLinks() {
    return this.settings &&
      this.settings.header &&
      this.settings.header.links_in_header
      ? this.settings.header.links_in_header
      : [];
  }

  get translations() {
    return this.settings && this.settings.general.translation_keys;
  }

  mounted() {
    EventBus.$on(EventBusChannels.ChangingPage, () => {
      if (this.menuIsOpen) this.toggleMenu();
      if (this.resultsIsOpen) this.toggleResultsMenu(false);
    });

    EventBus.$on(EventBusChannels.HeaderOverride, (value) => {
      this.headerOverride = value;
    });

    EventBus.$on(EventBusChannels.IsOpenHeaderOverride, (value) => {
      this.isOpenOverride = value;
    });
  }
}
