
import { Mixins, Component, Prop, Ref } from "vue-property-decorator";
import { createModifierClass, generateID, mapAnimation, lerp } from "_utils";
import { onResize, onScroll, onView } from "_mixins";
import { config } from "_core";
import { PQCardType } from "_types";
import Card from "./Card.vue";

@Component({
  name: "PostGrid",
  components: { Card },
})
export default class PostGrid extends Mixins(onResize, onScroll, onView) {
  @Prop() cards: PQCardType[];
  @Prop({ default: false, type: Boolean }) mid: boolean;
  @Prop({ default: false, type: Boolean }) bottom: boolean;
  @Ref() wrapper: HTMLElement;
  @Ref() col1: HTMLElement;
  @Ref() col2: HTMLElement;
  @Ref() col3: HTMLElement;
  @Ref() col4: HTMLElement;

  columns = null;
  measurements = { start: 0, end: 0, columnMax: [] };
  colCache = [];

  rootClass = "c-post-grid";

  get uniqueID() {
    return generateID();
  }

  mounted() {
    this.init();
  }

  onResize() {
    this.init();
  }

  public init() {
    const size = window.innerWidth;
    if (size < config.mobBreakpoint) {
      this.columns = 1;
    } else if (size < 1024) {
      this.columns = 2;
    } else if (size < 2048) {
      this.columns = 3;
    } else {
      this.columns = 4;
    }
    this.$nextTick(() => this.calc());
  }

  get posts() {
    let newArr = [];
    if (this.cards.length && this.columns) {
      let counter = 0;
      this.cards.forEach((post: PQCardType) => {
        if (!newArr[counter]) newArr[counter] = [];
        newArr[counter].push(post);
        counter >= this.columns - 1 ? (counter = 0) : counter++;
      });
    }
    return newArr;
  }

  get generateClass() {
    return [
      this.rootClass,
      createModifierClass(this.rootClass, this.columns === 1, "one-column"),
      createModifierClass(this.rootClass, this.columns === 2, "two-columns"),
      createModifierClass(this.rootClass, this.columns === 3, "three-columns"),
      createModifierClass(this.rootClass, this.columns === 4, "four-columns"),
      createModifierClass(this.rootClass, this.mid, "mid"),
      createModifierClass(this.rootClass, this.bottom, "bottom"),
    ];
  }

  get cols() {
    return [this.col1, this.col2, this.col3, this.col4].filter((col) => col);
  }

  onScroll(scroll: number) {
    if (!this.isInView) return;
    const { start, end, columnMax } = this.measurements;

    if (scroll < start) {
      this.cols.forEach((element, index) => {
        const animTarget = 0;
        this.transform(element, animTarget, index);
      });
    } else if (scroll >= end) {
      this.cols.forEach((element, index) => {
        const animTarget = columnMax[index] ? columnMax[index] : 0;
        this.transform(element, animTarget, index);
      });
    } else if (scroll >= start) {
      this.cols.forEach((element, index) => {
        const animTarget = mapAnimation(
          [start, end],
          [0, columnMax[index]],
          scroll
        );
        this.transform(element, animTarget, index);
      });
    }
  }

  transform(element: HTMLElement, target: number, index: number) {
    const cached = this.colCache[index] ? this.colCache[index] : 0;
    const lerped = lerp(cached, target);
    element.style.transform = `translateY(${
      lerped ? lerped : 0
    }px) translateZ(0)`;
    this.colCache[index] = lerped ? lerped : 0;
  }

  calc() {
    const { offsetTop, offsetHeight } = this.wrapper;
    if (!offsetTop || offsetHeight < window.innerHeight - 75) return;
    this.measurements = {
      start: offsetTop - 75,
      end: offsetTop + offsetHeight - window.innerHeight,
      columnMax: this.cols.map(
        (element) => offsetHeight - element.offsetHeight
      ),
    };
  }
}
